//created by Peggy on 2021/6/18
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import Modal from "antd/es/modal";
// import Slider from "antd/es/slider";

import "cropperjs/dist/cropper.css";
import CropperCom from "cropperjs";

import { Button, Upload, Spin, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getToken } from "@/utils/storage";

const IndexContainer = styled.div``;

const pkg = "antd-img-crop";
const noop = () => {};

interface Props {
  aspect?: number;
  shape?: "rect";
  grid?: boolean;
  quality?: number;

  zoom?: boolean;
  rotate?: boolean;
  minZoom?: number;
  maxZoom?: number;
  fillColor?: string;

  modalTitle?: string;
  modalWidth?: number | string;
  modalOk?: string;
  modalCancel?: string;

  beforeCrop?: any;
  cropperProps?: any;

  children?: React.ReactNode;
}

const Cropper = forwardRef((props: Props, ref) => {
  const {
    aspect = 1,
    // shape = "rect",
    // grid = false,
    quality = 0.4,

    // zoom = true,
    // rotate = false,
    // minZoom = 1,
    // maxZoom = 3,
    // fillColor = "white",

    modalTitle,
    modalWidth = 620,
    modalOk,
    modalCancel,

    beforeCrop,
    children,

    // cropperProps,
  } = props;

  const [src, setSrc] = useState("");
  // const [zoomVal, setZoomVal] = useState(1);
  // const [rotateVal, setRotateVal] = useState(0);
  const [cropper, setCropper] = useState<Cropper>();
  const [caption, setCaption] = useState("");

  const beforeUploadRef: any = useRef();
  const fileRef: any = useRef();
  const resolveRef: any = useRef(noop);
  const rejectRef: any = useRef(noop);

  /** Upload */
  const renderUpload = useCallback(() => {
    const upload: any = Array.isArray(children) ? children[0] : children;
    const {
      beforeUpload,
      accept,
      customRequest,
      ...restUploadProps
    } = upload?.props;
    beforeUploadRef.current = beforeUpload;

    return {
      ...upload,
      props: {
        ...restUploadProps,
        accept: accept || "image/*",
        beforeUpload: (file: any, fileList: any) =>
          new Promise((resolve, reject) => {
            if (beforeCrop && !beforeCrop(file, fileList)) {
              reject();
              return;
            }

            fileRef.current = file;
            resolveRef.current = resolve;
            rejectRef.current = reject;

            const reader: any = new FileReader();
            reader.addEventListener("load", () => {
              setSrc(reader.result);
            });
            reader.readAsDataURL(file);
          }),
        customRequest: (e: any) => customRequest(e, caption),
      },
    };
  }, [beforeCrop, children]);

  /** Modal */
  const modalProps = useMemo(() => {
    const obj: any = {
      width: modalWidth,
      okText: modalOk,
      cancelText: modalCancel,
    };
    Object.keys(obj).forEach((key) => {
      if (!obj[key]) delete obj[key];
    });
    return obj;
  }, [modalCancel, modalOk, modalWidth]);

  const onClose = useCallback(() => {
    setSrc("");
    // setZoomVal(1);
    // setRotateVal(0);
    setTimeout(() => {
      setCaption("");
    }, 1500);
  }, []);

  const onOk = () => {
    onClose();
    const { type, name, uid } = fileRef.current;
    cropper
      ?.getCroppedCanvas({
        fillColor: "#fff",
      })
      .toBlob(
        async (blob) => {
          let newFile: any = new File([blob!!], name.replace(/[^\w.]/g, "x"), {
            type: "image/png",
            lastModified: Date.now(),
          });
          newFile.uid = uid;
          if (typeof beforeUploadRef.current !== "function")
            return resolveRef.current(newFile);

          const res = beforeUploadRef?.current(newFile, [newFile]);

          if (typeof res !== "boolean" && !res) {
            console.error("beforeUpload must return a boolean or Promise");
            return;
          }

          if (res === true) return resolveRef.current(newFile);
          if (res === false) return rejectRef.current("not upload");
          if (res && typeof res.then === "function") {
            try {
              const passedFile = await res;
              const type = Object.prototype.toString.call(passedFile);
              if (type === "[object File]" || type === "[object Blob]")
                newFile = passedFile;
              resolveRef.current(newFile);
            } catch (err) {
              rejectRef.current(err);
            }
          }
        },
        type,
        quality
      );
  };

  const [imageEle, setImageEle] = useState<HTMLImageElement>();

  useEffect(() => {
    if (imageEle) {
      const _cropper = new CropperCom(imageEle, {
        aspectRatio: aspect,
        crop(event) {},
      });
      setCropper(_cropper);
    }
  }, [imageEle]);

  const renderComponent = (titleOfModal: string) => (
    <>
      {renderUpload()}
      {src && (
        <Modal
          visible={true}
          wrapClassName={`${pkg}-modal`}
          title={titleOfModal}
          onOk={onOk}
          onCancel={onClose}
          maskClosable={false}
          destroyOnClose
          {...modalProps}
        >
          <div className="img-container" style={{ width: "100%", height: 300 }}>
            <img
              style={{
                width: "100%",
                maxWidth: "100%",
                height: "100%",
                maxHeight: "450px",
              }}
              id="image"
              src={src}
              onLoad={() => {
                const image = document.getElementById(
                  "image"
                ) as HTMLImageElement;
                setImageEle(image);
              }}
            />
          </div>

          <div className="img-crop-button-container">
            <Button
              type="link"
              className="btn"
              size="small"
              onClick={() => {
                cropper && cropper.rotate(45);
              }}
            >
              Rotate
            </Button>
            <Button
              type="link"
              className="btn"
              size="small"
              onClick={() => {
                if (cropper) {
                  const { scaleX, scaleY } = cropper.getImageData();
                  if (scaleX === -1) {
                    cropper.scaleX(1);
                  } else {
                    cropper.scaleX(-1);
                  }
                }
              }}
            >
              Flip
            </Button>
            <Button
              type="link"
              className="btn"
              size="small"
              onClick={async () => {
                let _temp: any = {};

                // 167/40 = 572 / x
                let _width = aspect > 572 / 300 ? 572 : 300 * aspect;
                _temp = {
                  height: aspect > 572 / 300 ? 572 / aspect : 300,
                  width: aspect > 572 / 300 ? 572 : 300 * aspect,
                  left: (572 - _width) / 2,
                  top: aspect > 572 / 300 ? (300 - 572 / aspect) / 2 : 0,
                };

                await cropper?.setCropBoxData(_temp);

                const {
                  width: widthC,
                  height: heightC,
                  naturalWidth,
                  naturalHeight,
                }: any = await cropper?.getCanvasData();

                let _cTemp = {};
                if (_temp.height < 300 && _temp.width === 572) {
                  if (naturalWidth / naturalHeight < aspect) {
                    // 117 /67
                    _cTemp = {
                      width: (naturalWidth / naturalHeight) * _temp.height,
                      height: _temp.height,
                      top: _temp.top,
                      left:
                        (572 - (naturalWidth / naturalHeight) * _temp.height) /
                        2,
                    };
                  } else {
                    _cTemp = {
                      width: 572,
                      height: (naturalHeight / naturalWidth) * _temp.width,
                      top:
                        (300 - (naturalHeight / naturalWidth) * _temp.width) /
                        2,
                      left: 0,
                    };
                  }
                } else if (_temp.height === 300 && _temp.width <= 572) {
                  if (naturalWidth / naturalHeight < aspect) {
                    // 117 /67
                    _cTemp = {
                      width: (naturalWidth / naturalHeight) * _temp.height,
                      height: _temp.height,
                      top: _temp.top,
                      left:
                        (572 - (naturalWidth / naturalHeight) * _temp.height) /
                        2,
                    };
                  } else {
                    _cTemp = {
                      width: _temp.width,
                      height: (naturalHeight / naturalWidth) * _temp.width,
                      top:
                        (300 - (naturalHeight / naturalWidth) * _temp.width) /
                        2,
                      left: _temp.left,
                    };
                  }
                }
                console.log(
                  naturalWidth,
                  naturalHeight,
                  _temp.height,
                  _temp.width,

                  heightC,
                  widthC,
                  widthC / heightC,
                  _temp.width / _temp.height
                );

                await cropper?.setCanvasData(_cTemp);
              }}
            >
              auto padding
            </Button>
          </div>
          <div
            className="field-form-items"
            style={{ marginTop: 24, width: "100%" }}
          >
            <span>Image Caption</span>
            <Input
              placeholder="Credit or caption for this photo"
              maxLength={200}
              value={caption}
              onChange={(e: any) => setCaption(e?.target?.value)}
            />
          </div>
        </Modal>
      )}
    </>
  );

  return renderComponent(modalTitle ?? "Edit image");
});

interface IndexProps {
  className?: string;
  value?: any;
  children?: React.ReactNode;
  listType?: "picture" | "text" | "picture-card" | undefined;
  url?: string;
  folder?: string;
  accept?: string;
  showUploadList?: boolean | any;
  maxCount?: number;
  scale?: number;
  onChange?: (e: any) => void;
  crop?: boolean;
  ref?: any;
}

const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
const Index = ({
  value = [],
  children = "",
  listType = "text",
  url = "/upload.do",
  folder = "avatar",
  accept = "image/*",
  showUploadList = false,
  maxCount = 1,
  scale = 1 / 1,
  className,
  onChange = () => {},
  crop = true,
  ref = null,
}: IndexProps) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>(value);
  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    if (/(png|jp[e]g|bmp|gif)/.test((file.name || src).split('.').pop())) {
      const image = new Image();
      image.src = src;
      const imgWindow: any = window.open(src);
      imgWindow.document.write(image.outerHTML);
    } else {
      window.open(src, '_blank')
    }
  };
  const handleChange = ({ fileList: newFileList }: any) => {
    const _fileList = [...newFileList].filter((item) => item.status === "done");
    setFileList(_fileList);
    onChange(_fileList);
  };

  const uploadRequest = async ({ file }: any, caption?: string) => {
    try {
      const name = `${Date.now()}/${file.name.replace(/[^\w.]/g, "x")}`;
      const data = await new ((window as any)?.AWS).S3.ManagedUpload({
        params: {
          Bucket: process.env.REACT_APP_AWS_BUCKET,
          Key: folder + "/" + name,
          Body: file,
          Metadata: { caption: caption || "" },
        },
      });
      let promise = data.promise();

      promise.then(
        (data: any) => {
          const _fileList = [...fileList];
          if (_fileList.length < maxCount) {
            _fileList.push({
              uid: name,
              name,
              status: "done",
              url: data.Location,
            });
          } else {
            _fileList.splice(-1, 1, {
              uid: name,
              name,
              status: "done",
              url: data.Location,
            });
          }
          setFileList(_fileList);
          onChange(_fileList);
          setLoading(false);
        },
        (err: any) => {
          setLoading(false);
          return alert("There was an error uploading your photo ");
        }
      );
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (value) {
      setFileList(value);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(value)]);
  return (
    <IndexContainer className={`upload-crop-container ${className}`}>
      {loading && (
        <Spin className="global-spin-container" spinning={loading}></Spin>
      )}
      {(crop && (
        <Cropper rotate aspect={scale}>
          <Upload
            ref={ref}
            accept={accept}
            headers={{ Authorization: `Bearer ${getToken()}` }}
            maxCount={maxCount}
            customRequest={uploadRequest}
            showUploadList={showUploadList}
            name="files"
            listType={listType}
            action={url}
            fileList={fileList}
            beforeUpload={() => {
              setLoading(true);
              return true;
            }}
            onChange={handleChange}
            onPreview={onPreview}
          >
            {listType === "picture-card"
              ? fileList.length >= 1
                ? null
                : uploadButton
              : children || <Button type="primary">change photo</Button>}
          </Upload>
        </Cropper>
      )) || (
        <Upload
          ref={ref}
          accept={accept}
          defaultFileList={[...(value || [])]}
          headers={{ Authorization: `Bearer ${getToken()}` }}
          maxCount={maxCount}
          customRequest={uploadRequest}
          showUploadList={showUploadList}
          name="files"
          listType={listType}
          action={url}
          fileList={fileList}
          beforeUpload={() => {
            setLoading(true);
            return true;
          }}
          onChange={handleChange}
          onPreview={onPreview}
        >
          {listType === "picture-card"
            ? fileList.length >= 1
              ? null
              : uploadButton
            : children || <Button type="primary">change photo</Button>}
        </Upload>
      )}
    </IndexContainer>
  );
};

export default Index;
