//created by Peggy on 2021/3/2
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu, Table, Input } from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import dayjs from "dayjs";
import { GetRowKey } from "antd/lib/table/interface";
import { NoTranslate } from "@/components/GoogleTranslate";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}
interface Props {
  onSearch?: (e: string) => void;
  title?: React.ReactNode;
  tableData: DataType[];
  pagination?: any;
  columns?: any[];
  scroll?: any; // Table Scroll config
  className?: string;
  rowKey?: string | GetRowKey<any>;
  noSticky?: boolean
}

const InKindTable = ({
  tableData = [],
  pagination = "",
  title = "In-kind donations",
  onSearch = () => { },
  columns = [],
  scroll = {},
  className,
  rowKey,
  noSticky
}: Props) => {
  const history = useHistory();
  const [column] = useState(
    columns?.length
      ? columns
      : [
        {
          title: "Donor",
          dataIndex: "donor",
          fixed: "left",
          render: (text: string) => <NoTranslate>{text}</NoTranslate>,
        },
        {
          title: "Donation Date",
          dataIndex: "createDate",
          // width: 150,
          key: "createDate",
          render: (text: string, row: any) =>
            dayjs(text).format("MM/DD/YYYY"),
        },
        {
          title: "received date",
          dataIndex: "createDate",
          // width: 150,
          key: "receivedDate",
          render: (text: string, row: any) =>
            dayjs(text).format("MM/DD/YYYY"),
        },
        {
          title: "description",
          dataIndex: "description",
          width: 200,
          ellipsis: true,
        },
        {
          title: "",
          width: 80,
          align: "center",
          key: "action",
          render: (row: any) => (
            <Dropdown
              trigger={["click"]}
              overlay={() => (
                <>
                  <Menu>
                    <Menu.Item key={row.id + 'inkind-m'}
                      onClick={() =>
                        history.push(`/dashboard/donation/inKind/${row.id}`)
                      }
                    >
                      View Detail
                    </Menu.Item>
                    {/*<Menu.Item*/}
                    {/*  onClick={() =>*/}
                    {/*    history.push(*/}
                    {/*      `/dashboard/income/detail/${row.id}/note`*/}
                    {/*    )*/}
                    {/*  }*/}
                    {/*>*/}
                    {/*  View thank you note*/}
                    {/*</Menu.Item>*/}
                  </Menu>
                </>
              )}
            >
              <span className="ant-dropdown-link">
                <FilterMore />
              </span>
            </Dropdown>
          ),
        },
      ]
  );
  useEffect(() => { }, []);
  return (
    <Table
      className={`table-item ${className || ""}`}
      // style={{ width: "100%" }}
      title={() => (
        <div className="flex">
          <span>{title}</span>
          <Input.Search onSearch={(e) => onSearch(e)} placeholder="Search" />
        </div>
      )}
      pagination={pagination}
      columns={column}
      rowKey={rowKey || 'id'}
      dataSource={tableData}
      sticky={(!!scroll && !noSticky) ? { offsetHeader: -1 } : undefined}
      showHeader={!!scroll ? !!tableData?.length : true}
      scroll={scroll}
    />
  );
};

export default InKindTable;
