//created by Peggy on 2021/7/27
import React from "react";
import {
  LinkedinFilled,
  InstagramFilled,
  FacebookFilled,
  TwitterCircleFilled,
} from "@ant-design/icons";

const SocialLayout = () => {
  return (
    <section className="social-follow">
      <div className="flex ">
        <h1>Follow us on social media</h1>
        <span className="flex">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/thefieldnyc/"
          >
            <InstagramFilled />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="http://facebook.com/thefieldnyc"
          >
            <FacebookFilled />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/TheFieldNYC"
          >
            <TwitterCircleFilled />
          </a>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/thefieldnyc/"
          >
            <LinkedinFilled />
          </a>
        </span>
      </div>
      <a href="https://thefield.us16.list-manage.com/subscribe?u=6f3a87ebf7036e5960e4e7c36&amp;id=6a5f62890c" target="_blank" className="elementor-button-link" role="button">
        <span className="elementor-button-text">Join our mailing list</span>
      </a>
    </section>
  );
};

export default SocialLayout;
