//created by Peggy on 2021/3/4
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "@/styles/dashboard/donation.less";
import {
  Breadcrumb,
  Divider,
  Form,
  Input,
  Select,
  Button,
  message,
} from "antd";
import DatePicker from "@/components/DatePicker";

import { InKindAdd, incomeDetail } from "@/api/donation-api";
import { useParams } from "react-router-dom";

import { stateList, UserRole } from "@/types/enum";
import dayjs from "dayjs";
import { localStorageGet } from "@/utils/storage";

const InKindDetail = () => {
  const history = useHistory();
  const { id } = useParams() as any;
  const roles = localStorageGet("roles");

  const isAdminUser = roles?.includes(UserRole.ADMIN);

  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    const { description, date, ...others } = values;
    InKindAdd({
      description,
      date,
      donor: others,
    }).then((data) => {
      message.success("In-kind donation added successfully.");
    });
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };
  //detail
  const [info, setInfo] = useState<any>("");
  useEffect(() => {
    let _data = {
      id,
    };
    incomeDetail(_data).then((data) => {
      const { donor, date, ...others } = data;
      setInfo({
        ...others,
        ...donor,
        date: dayjs(date),
      });
      form.setFieldsValue({
        ...others,
        ...donor,
        date: dayjs(date),
      });
    });
    // eslint-disable-next-line
  }, [id]);
  return (
    <div className="admin-common-container campaign-detail-container donation-detail-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/donation/list">
          In-kind donations
        </Breadcrumb.Item>
        <Breadcrumb.Item>Donation detail</Breadcrumb.Item>
      </Breadcrumb>
      <h1 className="flex">Donation by {info.name}</h1>
      <div className="common-form shadow">
        <Form
          form={form}
          layout="vertical"
          name="form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h2>Donation information</h2>
          <Form.Item label="Donation Description" name="description">
            <Input disabled maxLength={200} placeholder="Donation Description" />
          </Form.Item>
          <Form.Item label="Donation date" name="date">
            <DatePicker disabled />
          </Form.Item>
          <Divider />
          <h2>Donor information</h2>
          <Form.Item label="Donor name" name="name">
            <Input disabled maxLength={50} placeholder="Donor name" />
          </Form.Item>
          <Form.Item label="Donor public name" name="publicName">
            <Input disabled maxLength={50} placeholder="Donor public name" />
          </Form.Item>
          <Form.Item label="email address" name="email">
            <Input disabled maxLength={50} placeholder="email address" />
          </Form.Item>
          <Form.Item label="address" name="address">
            <Input disabled maxLength={200} placeholder="Address" />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Input disabled placeholder="City" maxLength={30} />
          </Form.Item>
          <div className="flex">
            <Form.Item label="State" name="state" className="select-container">
              <Select disabled placeholder="State">
                {Object.values(stateList)?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Zip Code" name="zipCode">
              <Input disabled placeholder="Zip Code" maxLength={5} />
            </Form.Item>
          </div>
        </Form>
      </div>
      {!isAdminUser && <div className="flex end">
        <Button
          type="primary"
          onClick={() => history.push("/dashboard/message")}
        >
          contact admin
        </Button>
      </div>
      }
    </div>
  );
};

export default InKindDetail;
