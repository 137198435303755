//created by Peggy on 2021/5/20
import React from "react";
import { Select, Spin } from "antd";
import { SelectProps } from "antd/es/select";
import debounce from "lodash/debounce";

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  onChange?: (e?: any) => void;
  value?: any;
  debounceTimeout?: number;
  defaultOptions?: any[];
}

function DebounceInput<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  onChange = () => {},
  defaultOptions = [],
  value,
  ...props
}: DebounceSelectProps) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<ValueType[]>(defaultOptions);
  const fetchRef = React.useRef(0);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (val: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(val).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  React.useMemo(() => {
    if (defaultOptions?.length > 0) {
      setOptions(defaultOptions);
    }
  }, [defaultOptions]);

  return (
    <Select<ValueType>
      showSearch
      bordered={false}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      value={value}
      onChange={(e: any) => {
        onChange(e);
      }}
      {...props}
      options={options}
    />
  );
}

export default DebounceInput;
