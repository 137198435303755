// Membership Modal
export const getPopupButtonByMembershipStatus = (status: string) => {
  switch (status) {
    case "approved":
      return "PROCEED TO PAYMENT";
    case "expired":
      return "RENEW NOW";
    case "paid":
    case "wait_for_signature":
      return "COMPLETE REGISTRATION";
    default:
      return "APPLY NOW";
  }
};
export const getPopupTitleByMembershipStatus = (
  status: string,
  title: string
) => {
  switch (status) {
    case "expired":
      return "Fiscal Sponsorship Expired";
    case "approved":
        return "Complete your fiscal sponsorship payment";
    case "paid":
    case "wait_for_signature":
    default:
      return title;
  }
};

export const getPopupDescByMembershipStatus = (
  status: string,
  description: string
) => {
  switch (status) {
    case "expired":
      return "Renew your membership to receive benefits and continue fundraising.";
    case "approved":
    case "paid":
    case "wait_for_signature":
      return "In order to proceed, you will need to complete your registration for Fiscal Sponsorship.";
    default:
      return description;
  }
};
