//created by Peggy on 2021/6/23
import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Select,
  Table,
  Button,
  Switch,
  Tag,
} from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import {
  couponList,
  couponSave,
  couponUpdate,
  couponDelete,
  couponTypeList,
} from "@/adminApp/apis/coupon-api";
import dayjs from "dayjs";
import useConfirm from "@/components/Modal/Confirm";
import DatePicker from "@/components/DatePicker";

import "./coupon.less";
import styled from "styled-components";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
const IndexContainer = styled.div``;

const CouponProductType: any = {
  fiscal_sponsorship: "Fiscal Sponsorship",
  consultation: "Consultation",
  rush_fee: "Rush Fee",
  misc_fee: "Misc Fee",
  carryover_fee: "Carryover Fee",
};

const CouponType: any = {
  percentage_discount: "Percentage discount",
  amount_discount: "Amount discount",
};

const StatusType: any = {
  active: {
    label: "Active",
    color: "green",
  },
  inactive: {
    label: "InActive",
    color: "default",
  },
  expired: {
    label: "Expired",
    color: "purple",
  },
};

const Index = () => {
  // -view note
  const [noteForm] = Form.useForm();
  const [noteModal, setNoteModal] = useState<any>(false);
  const handleViewNote = (row: any) => {
    setNoteModal(true);
    if (row === true) {
      noteForm.resetFields();
    } else {
      const { id, from, to, perUserLimit, ...others } = row;
      let date = (from && to && [dayjs(from), dayjs(to)]) || "";
      noteForm.setFieldsValue({
        id,
        ...others,
        perUserLimit,
        date,
        dateSwitch: !!date,
        useSwitch: !!perUserLimit,
      });
    }
  };
  const handleNoteFinish = async (values: any) => {
    const {
      id,
      dateSwitch,
      useSwitch,
      date,
      perUserLimit,
      amount,
      productType,
      ...others
    } = values;
    let [from, to] = dateSwitch && date ? date : [null, null];
    let _data = {
      ...others,
      from,
      to,
      productType: Number(productType) > 0 ? "the_field_ticket" : productType,
      extraId: Number(productType) > 0 ? productType : "",
      amount: amount - 0,
      perUserLimit: useSwitch ? perUserLimit - 0 : null,
    };
    try {
      if (id) {
        await couponUpdate(id, _data);
      } else {
        await couponSave(_data);
      }
      setNoteModal(false);
      getList();
      message.success("successfully");
    } catch (e) { }
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  const { setShow: deleteShow, component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>Are you sure you want to permanently delete this coupon ?</p>
    </div>,
    {
      title: "Delete confirm",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        couponDelete(id).then(() => {
          getList();
          message.success("successfully");
        });
      },
    }
  );
  const handleDelete = (row: any) => {
    deleteShow(row.id);
  };

  //handler end
  const [opts, setOpts] = useState<any[]>();
  const getTypeList = () => {
    couponTypeList().then((data: any[]) =>
      setOpts(
        data
          ?.map((item: any) => ({
            value: item.id,
            label: item.name,
          }))
          .concat(
            Object.entries(CouponProductType)?.map((item: any[]) => ({
              value: item[0],
              label: item[1],
            }))
          )
      )
    );
  };

  // -search
  const column: any[] = [
    {
      title: "Coupon Code",
      dataIndex: "name",
      width: 100,
      fixed: "left",
    },
    {
      title: "Type",
      dataIndex: "type",
      width: 100,
      render: (text: string) => CouponType[text],
    },
    {
      title: "Amount",
      width: 120,
      dataIndex: "amount",
      render: (text: string, rows: any) =>
        rows?.type === "amount_discount"
          ? Number(text || 0).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })
          : text + "%",
    },

    {
      title: "Status",
      dataIndex: "status",
      maxWidth: 200,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
      render: (text: string, _: any) => (
        <Tag color={StatusType[text] ? StatusType[text]?.color : "gray"}>
          {StatusType[text] ? StatusType[text]?.label : text}
        </Tag>
      ),
    },
    {
      title: "Product",
      width: 150,
      dataIndex: "productType",
      render: (text: string, row: any) =>
        text !== "the_field_ticket" ? CouponProductType[text] : row.extraName,
    },
    {
      title: "Start Date",
      width: 130,
      dataIndex: "from",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "End Date",
      width: 130,
      dataIndex: "to",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Date created",
      width: 130,
      dataIndex: "createAt",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },

    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleViewNote(row)}>
                  Edit coupon
                </Menu.Item>
                <Menu.Item onClick={() => handleDelete(row)}>Delete</Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
  });

  const [total, setTotal] = useState(0);
  const [windowHeight] = useWindowSize();
  const [tableData, setTableData] = useState<any[]>();

  const getList = () => {
    let { date, ...others } = params,
      [from, to] = date ?? [null, null];
    couponList({
      from,
      to,
      ...others,
    })
      .then(({ items, meta, ...others }) => {
        setTableData(items);
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
      });
  };
  useEffect(() => {
    getList();

    // eslint-disable-next-line
  }, [params]);
  useEffect(() => {
    getTypeList();
  }, []);
  return (
    <IndexContainer className="admin-coupons-container admin-common-container">
      <h1>Coupons</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="field-form-items">
            <span>Product type</span>
            <Select
              allowClear
              value={params?.productType}
              bordered={false}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  productType: e,
                });
              }}
              placeholder="select..."
            >
              {Object.entries(CouponProductType).map((item: any) => (
                <Select.Option value={item[0]} key={item[0]}>
                  {item[1]}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span>Coupon type</span>
            <Select
              allowClear
              value={params?.type}
              placeholder="select..."
              bordered={false}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  type: e,
                });
              }}
            >
              <Select.Option value="percentage_discount">
                Percentage discount
              </Select.Option>
              <Select.Option value="amount_discount">
                Amount discount
              </Select.Option>
            </Select>
          </div>
          <div className="range-picker-wrap field-form-items">
            <span>valid date</span>
            <DatePicker.RangePicker
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e,
                });
              }}
            />
          </div>
        </div>
        <Button type="primary" onClick={() => handleViewNote(true)}>
          Add new coupon
        </Button>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Coupon list</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) =>
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                })
              }
              placeholder="Search"
            />
          </div>
        )}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title={`${noteForm.getFieldValue("id") ? "Edit" : "Add"} Coupon Code`}
        className="common-modal admin-coupons-modal"
        visible={noteModal}
        onOk={() => noteForm.submit()}
        okText="save"
        cancelText="discard changes"
        onCancel={() => setNoteModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form
          form={noteForm}
          onFinish={handleNoteFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          name="form_note"
        >
          <Form.Item noStyle name="id">
            <Input hidden disabled />
          </Form.Item>
          <Form.Item
            label="Coupon Name"
            rules={[
              {
                required: true,
                whitespace: false,
                message: "Coupon Name",
              },
            ]}
            name="name"
          >
            <Input maxLength={50} placeholder="Coupon Name" />
          </Form.Item>
          <Form.Item
            label="COUPON TYPE"
            rules={[
              {
                required: true,
                whitespace: false,
                message: "Coupon type",
              },
            ]}
            name="type"
            initialValue="percentage_discount"
          >
            <Select placeholder="select..." bordered={false}>
              <Select.Option value="percentage_discount">
                Percentage discount
              </Select.Option>
              <Select.Option value="amount_discount">
                Amount discount
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.type !== curValues.type
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                label="Amount"
                name="amount"
                normalize={(e) => {
                  return e.replace(/[^\d]*/g, "");
                }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Amount",
                  },
                  {
                    validator: (_, value) => {
                      if (
                        value > 100 &&
                        getFieldValue("type") !== "amount_discount"
                      ) {
                        return Promise.reject("No more than 100 please");
                      }
                      return Promise.resolve();
                    },
                    validateTrigger: "blur",
                  },
                ]}
              >
                <Input
                  prefix={
                    getFieldValue("type") === "amount_discount" ? "$" : ""
                  }
                  suffix={
                    getFieldValue("type") !== "amount_discount" ? "%" : ""
                  }
                  maxLength={
                    getFieldValue("type") !== "amount_discount" ? 3 : 100
                  }
                  placeholder="Amount"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            label="Applicable product"
            rules={[
              {
                required: true,
                message: "Applicable product",
              },
            ]}
            name="productType"
          >
            <Select
              allowClear
              placeholder="select..."
              bordered={false}
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {opts?.map((item: any) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Applicable User"
            rules={[
              {
                required: true,
                message: "Applicable User",
              },
            ]}
            name="userType"
          >
            <Select allowClear placeholder="select..." bordered={false}>
              <Select.Option value="every_one">
                Everyone
              </Select.Option>
              <Select.Option value="free_plan_artist">
                Free plan artist{" "}
              </Select.Option>
              <Select.Option value="sponsor_artist">
                Sponsor artist{" "}
              </Select.Option>
            </Select>
          </Form.Item>
          <div className="switch-wrap flex">
            <span>Valid between</span>
            <div className="switch-item">
              <span>Don’t set end date</span>
              <Form.Item
                noStyle
                name="dateSwitch"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.dateSwitch !== curValues.dateSwitch
            }
          >
            {({ getFieldValue }) => (
              <Form.Item label="date(est)" name="date">
                <DatePicker.RangePicker
                  disabled={!getFieldValue("dateSwitch")}
                />
              </Form.Item>
            )}
          </Form.Item>

          <div className="switch-wrap flex">
            <span>Limited use</span>
            <div className="switch-item">
              <span>Unlimited</span>
              <Form.Item
                noStyle
                name="useSwitch"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.useSwitch !== curValues.useSwitch
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                label="Limit use per user"
                normalize={(e) => {
                  return e.replace(/[^\d]*/g, "");
                }}
                name="perUserLimit"
              >
                <Input
                  disabled={!getFieldValue("useSwitch")}
                  placeholder="Limit use per user"
                />
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </Modal>
      {deleteComponent}
    </IndexContainer>
  );
};

export default Index;
