import { getToken, localStorageSet, setToken } from "@/utils/storage";
import { showErrorMessage } from "@/utils/util";
import { message } from "antd";
import { Action, justBack, KV, Model } from "use-reaction";
import {
  AdminGetCode,
  AdminGetUserInfo,
  AdminLogin,
  AdminVerify,
} from "../apis/user-api";

interface AdminUser extends Model {
  logined: boolean;
  token: string;
  info: KV;
  email?: string;
}
export const model_admin_user: AdminUser = {
  NAME: "model_admin_user",
  logined: false,
  token: "",
  info: {},
};

export const AdminLoginAction: Action<AdminUser> = async ({
  payload: { email, password },
}) => {
  try {
    const res = await AdminLogin({ email, password });
    if (res?.token) {
      setToken(res.token);
    }
    return {
      ...res,
      logined: !!res.token,
      email,
    };
  } catch (error) {
    showErrorMessage(error);
  }
};

export const AdminGetCodeAction: Action<AdminUser> = async ({
  store: { email },
}) => {
  const res = await AdminGetCode(email!);
  return justBack(res);
};

export const AdminVerifyAction: Action<AdminUser> = async ({
  payload: { code },
  store: { email },
}) => {
  try {
    const res = await AdminVerify({
      email: email!,
      code: code?.toString()?.trim(),
    });
    if (res?.token) {
      setToken(res.token);
      return {
        logined: true,
        token: res.token,
      };
    }
  } catch (error: any) {
    message.error(error.data.message);
  }
};

export const BackLoginAction: Action<AdminUser> = async ({
  payload: { token },
}) => {
  if (token) {
    setToken(token);
    return {
      logined: true,
      token,
    };
  }
};

export const getUserInfoAction: Action<AdminUser> = async () => {
  // try get user info
  const token = getToken();
  if (token) {
    try {
      const res = await AdminGetUserInfo();
      if (res?.id) {
        localStorageSet("info", res);
        localStorageSet("roles", res.roles);
        return {
          logined: true,
          token,
          info: res,
        };
      }
    } catch (error) {
      return {};
    }
  }
  return {};
};
