import { addAdmin, editAdmin, getAdmin } from "@/adminApp/apis/user-api";
import { UserRole } from "@/types/enum";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import { useHistory, useParams } from "react-router-dom";
import { admin_router_admins } from "@/adminApp/routers";
import { useRequest } from "ahooks";

type Props = {};

export const roles = [
  {
    label: "Admin",
    value: UserRole.ADMIN,
  },
  {
    label: "Super Admin",
    value: UserRole.SUPER_ADMIN,
  },
];

const AdminEdit: React.FC<Props> = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const params = useParams<{ id: string }>();
  const id = !isNaN(+params.id) ? +params.id : undefined;
  const loadRequest = useRequest(() => getAdmin(id), {
    ready: !!id,
    refreshDeps: [id],
    onSuccess: (res) => {
      form.setFieldsValue({
        id: res.id,
        firstName: res.profile?.firstName,
        lastName: res.profile?.lastName,
        email: res.email,
        role: res.roles.includes(UserRole.SUPER_ADMIN)
          ? UserRole.SUPER_ADMIN
          : UserRole.ADMIN,
        avatar: res.profile?.avatar
          ? [
              {
                uid: 0,
                name: res.profile?.avatar?.split("/")?.pop(),
                status: "done",
                url: res.profile?.avatar,
              },
            ]
          : undefined,
        phone: res.profile?.phone,
      });
    },
  });
  const addRequest = useRequest(addAdmin, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        message.success("Admin added successfully. Invitation has been sent.");
        history.push(admin_router_admins);
      }
    },
  });
  const editRequest = useRequest(editAdmin, {
    manual: true,
    onSuccess: (res) => {
      if (res.success) {
        message.success("Admin edited successfully.");
        history.push(admin_router_admins);
      }
    },
  });
  const handleUserFinish = (values: any) => {
    if (values.id !== undefined) {
      editRequest.runAsync({
        ...values,
        avatar: values.avatar?.[0]?.url,
      });
    } else {
      addRequest.runAsync({
        ...values,
        avatar: values.avatar?.[0]?.url,
      });
    }
  };
  return (
    <div className="admin-admin-container admin-common-container">
      {id !== undefined ? <h1>Edit Admin</h1> : <h1>Add a New Admin</h1>}
      <div className="p-8 bg-white shadow-md">
        <h4 className="text-[24px] mb-6 leading-[1.58]">Staff information</h4>
        <Form
          form={form}
          onFinish={handleUserFinish}
          layout="vertical"
          name="form_user"
        >
          <Form.Item hidden name="id">
            <Input hidden />
          </Form.Item>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter first name",
              },
            ]}
          >
            <Input maxLength={50} placeholder="First name" />
          </Form.Item>
          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter last name",
              },
            ]}
          >
            <Input maxLength={50} placeholder="Last name" />
          </Form.Item>
          <Form.Item
            label="User role"
            name="role"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please select role",
              },
            ]}
          >
            <Select allowClear bordered={false} placeholder="Select a role ">
              {roles.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="email address"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "email",
              },
            ]}
          >
            <Input
              disabled={id !== undefined}
              maxLength={100}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name="phone"
            className="ant-form-item-required"
            normalize={(e) => {
              return e
                .replace(/[^\d]*/g, "")
                .replace(/(\d{3})(?=\d{2})/g, "$1-");
            }}
          >
            <Input maxLength={12} placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item
            className="no-style-item mt-10 !mb-0"
            label={<h2 className="normal-case text-[16px]">Profile photo</h2>}
            name="avatar"
          >
            <UploadCrop
              crop={false}
              accept=".png,.jpeg,.jpg,.gif"
              listType="picture"
              className="upload-item-wrap !mb-0"
              showUploadList
              maxCount={1}
            >
              <div className="children-upload">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Support a single file upload.</p>
              </div>
            </UploadCrop>
          </Form.Item>
        </Form>
        <Spin
          spinning={
            loadRequest.loading || addRequest.loading || editRequest.loading
          }
        ></Spin>
      </div>
      <div className="flex mt-10">
        <Button
          className="ml-auto mr-4"
          onClick={() => history.push(admin_router_admins)}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={form.submit}
          loading={addRequest.loading || editRequest.loading}
        >
          {id !== undefined ? "Save" : "Create & Send invitation"}
        </Button>
      </div>
    </div>
  );
};

export default AdminEdit;
