//created by Peggy on 2021/4/22
import React, { useState, forwardRef, useImperativeHandle } from "react";

import { Modal } from "antd";

interface ModalProps {
  content?: React.ReactNode;
  className?: string;
  title?: string;
  onCancel?: (type?: "close" | "cancel") => void;
  onOk?: (val?: any) => void;
  footer?: React.ReactNode[] | null;
  width?: number;
  okText?: string;
  cancelText?: string;
  showClose?: boolean;
}

const Confirm = React.memo(
  forwardRef<any, any>(
    (
      {
        content = "",
        title = "modal",
        className,
        onCancel,
        onOk,
        footer = undefined,
        width = 570,
        okText = "ok",
        cancelText = "cancel",
        showClose = false,
      }: ModalProps,
      ref
    ) => {
      const [show, setShow] = useState<boolean | number>(false);
      useImperativeHandle(ref, () => ({
        setShow,
      }));

      const handleCancel = (e: any) => {
        const target = e.currentTarget.className.includes("ant-modal-close")
          ? "close"
          : "cancel";
        onCancel && onCancel(target);
        setShow(false);
      };

      const handleOk = () => {
        (onOk && onOk(show)) || setShow(false);
      };
      return (
        <Modal
          width={width}
          closable={showClose}
          maskClosable={false}
          className={`common-modal ${className}`}
          title={title}
          visible={!!show}
          onOk={handleOk}
          okText={okText}
          cancelText={cancelText}
          footer={footer}
          onCancel={handleCancel}
          forceRender
          centered
        >
          {typeof content === "function" ? content(show) : content}
        </Modal>
      );
    }
  )
);

const useConfirm = (
  content: React.ReactNode | (() => React.ReactNode) = "",
  props: ModalProps
) => {
  const confirmRef = React.useRef<any>(null as any);
  return {
    setShow: (v: boolean | number) => confirmRef?.current?.setShow(v),
    component: <Confirm {...{ content, ...props }} ref={confirmRef} />,
  };
};

export default useConfirm;
