import Request from "@/libs/fetch";

export const contributionList = async (data?: any) =>
  await Request("/contribution/search", { type: "post", data });

export const contributionDetail = async (id: number) =>
  await Request(`/contribution/detail/${id}`, { type: "get" });

export const contributionTag = async () =>
  await Request(`order-tag`, { type: "get" });

export const contributionTagAdd = async (data?: any) => 
  await Request('/order-tag', { type: 'post', data })

export const contributionTagDelete = async (id: number) =>
  await Request('/order-tag/' + id, { type: 'delete' })

export const contributionTagUpdate = async (id: number, name: string) =>
  await Request('/order-tag', { type: 'put', data: { id, name } })

export const contributionCheck = async (data?: any) =>
  await Request("/contribution/check", { type: "post", data });

export const transactionCheck = async (data?: any) =>
  await Request("/contribution/check-transaction", { type: "post", data });

export const contributionDelete = async (id: string) =>
  await Request(`/contribution/${id}`, { type: "delete" });

export const contributionSave = async (id: number, data: any) =>
  await Request(`/contribution/${id}`, { type: "put", data });

export const orderList = async (data?: any) =>
  await Request("/contribution/order-search", { type: "post", data });

  export const orderEditTags = async (data?: any) =>
  await Request("/contribution/tags", { type: "post", data });

export const inkindList = async (data?: any) =>
  await Request("/contribution/inkind-search", { type: "post", data });
export const contributionThankyouAddr = async (id: number, data?: any) =>
  await Request("/contribution/ty-info/" + id, { type: "post", data });
export const contributionThankyouResend = async (id: number, data?: any) =>
  await Request("/contribution/ty-resend/" + id, { type: "post", data });
export const contributionBulkEditStatus = async (data?: any) =>
  await Request("/contribution/bulk-status", { type: "post", data });
