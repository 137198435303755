//create by pegg on
import { KV } from "use-reaction";
const tokenStorageKey = "THEFIELD:local:token";
const LOCAL_STORAGE_ACCESS = "THEFIELD:local:key";

export const getToken = () => {
  return localStorageGet(tokenStorageKey);
};

export const setToken = (token: string) => {
  return localStorageSet(tokenStorageKey, token);
};

// allow key contans '.' to specific deeper structure
export function getValueOfKV(key: string, obj: KV) {
  if (key.includes(".")) {
    const keyChains = key.split(".").map((_) => _.trim());
    let res = obj,
      k;
    while (keyChains.length) {
      k = keyChains.shift();
      if (res[k!]) {
        res = res[k!];
      }
    }

    return res === obj ? undefined : res;
  } else {
    return obj[key];
  }
}

export function setValueOfKV(key: string, v: any, obj: KV) {
  if (key.includes(".")) {
    const keyChains = key.split(".").map((_) => _.trim());
    let res = obj,
      k;
    while (keyChains.length) {
      k = keyChains.shift();
      if (keyChains.length) {
        res[k!] = res[k!] || {};
        res = res[k!];
      } else {
        res[k!] = v;
      }
    }
  } else {
    obj[key] = v;
  }
}
export function localStorageGet(key: string) {
  const str = localStorage.getItem(LOCAL_STORAGE_ACCESS);
  if (str) {
    const store = JSON.parse(str);
    return getValueOfKV(key, store);
  }
  return null;
}
export function localStorageSet(key: string, v: any) {
  const str = localStorage.getItem(LOCAL_STORAGE_ACCESS);
  const store = str ? JSON.parse(str) : {};
  setValueOfKV(key, v, store);
  localStorage.setItem(LOCAL_STORAGE_ACCESS, JSON.stringify(store));
}
