//created by Peggy on 2021/1/28
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Form, Input, Button, message } from "antd";

import { resetPassword } from "@/api/sign-api";

import { ReactComponent as LogoWhite } from "@/images/logo-white.svg";

import Info from "@/components/Modal/Info";
import styled from "styled-components";
import '@/styles/sign.less';
const ChangePwdContainer = styled.div``;

const ChangePwd = () => {
  const history = useHistory();
  const { id, salt, from } = useParams() as any;

  const [show, setShow] = useState(false);
  const onFinish = (values: any) => {
    const { password } = values;
    resetPassword({
      password,
      id,
      salt,
    }).then(async () => {
      if (from === "donor") {
        message.success("set up successful");
        history.push("/sign/donor");
      } else {
        setShow(true);
      }
    });
  };
  return (
    <div className="sign-container sign-up-container password-container sign-in-container flex start">
      <div className="left-wrap">
        <span>
          <LogoWhite />
        </span>
        <i className="copy-right">© Artist Alicia Foxworth</i>
      </div>
      <ChangePwdContainer className="change-pwd-container recover-container sign-up-wrap  flex column start">
        <h1>Change password</h1>
        <h4>
          Enter your new password and confirm one more time to complete the
          reset.
        </h4>
        <div className="sign-form">
          <Form
            layout="vertical"
            size="large"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{ width: "100%" }}
          >
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter your new password" />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Enter your new password again" />
            </Form.Item>
            <Form.Item noStyle>
              <Button block type="primary" htmlType="submit">
                change password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </ChangePwdContainer>
      <Info
        className="recover-info-modal"
        visible={show}
        footer={[
          <Link to="/sign/in">
            <Button type="primary">Return to sign in</Button>
          </Link>,
        ]}
      >
        <h1>reset success</h1>
      </Info>
    </div>
  );
};

export default ChangePwd;
