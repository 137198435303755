//created by Peggy on 2021/4/29
import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Select, Input, message } from "antd";
import { disciplineOptions } from "@/types/enum";

const SelectAndInputContainer = styled.div`
  .other-input.show {
    display: block;
    padding: 0;
    border: 0;
    box-shadow: none;
    background-color: transparent;
  }
`;

interface Props {
  options?: any[];
  placeholderForSelect?: string;
  placeholder?: string;
  value?: any;
  onChange?: (val: any) => void;
  mode?: "single" | "multi";
  disabled?: boolean;
}

const SelectAndInput = ({
  value,
  placeholderForSelect,
  placeholder,
  options = disciplineOptions,
  onChange = () => {},
  mode = "multi",
  disabled,
}: Props) => {
  const inputRef = useRef<any>(null);
  const [val, setVal] = useState(value);
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const handleChange = (e: any) => {
    let _temp = Array.isArray(e) ? e : [e];
    // if (_temp.includes("Other")) {
    //   setShow(true);
    // }
    setShow(_temp.includes("Other"));
    setTimeout(() => {
      inputRef?.current?.input?.focus();
    }, 0);
    setVal(_temp);
  };
  const handleInput = (e: string) => {
    if (!e) {
      message.error("please input value");
      return false;
    }
    if (val.includes(e) || val.includes("Other - " + e)) {
      message.error("value had existed");
      return false;
    }
    let _temp = val;
    if (_temp.includes("Other")) {
      _temp = _temp.filter((item: any) => item !== "Other");
    }
    setVal([..._temp, "Other - " + e]);
    setText("");
  };
  useEffect(() => {
    if (val !== undefined) {
      let _temp = val;
      onChange(_temp);
    }
    // eslint-disable-next-line
  }, [val]);
  return (
    <SelectAndInputContainer className="SelectAndInput-container">
      <Select
        className="multiple-row-selector"
        mode={mode === "multi" ? "multiple" : undefined}
        placeholder={placeholderForSelect || "Please select"}
        value={val || value}
        bordered={false}
        onChange={handleChange}
        disabled={disabled}
      >
        {options?.map((item, index) => (
          <Select.Option key={index} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
      {show && (
        <div className={`other-input ${show ? "show" : ""}`}>
          <Input
            value={text}
            ref={inputRef}
            maxLength={30}
            placeholder={placeholder || `Enter your disciplines here`}
            onChange={(e: any) => setText(e.target.value)}
            onPressEnter={(e: any) => handleInput(e.target.value)}
            disabled={disabled}
          />
        </div>
      )}
    </SelectAndInputContainer>
  );
};

export default SelectAndInput;
