import Request from "@/libs/fetch"

export const putGrant = async (data: any) => await Request('/grant', { type: 'put', data })

export const getGrant = async (id: number) => await Request(`/grant/${id}`, { type: 'get' })

export const publishGrant = async (id: number) => await Request(`/grant/status`, { type: 'put', data: { id, status: 'live' } })

export const duplicateGrant = async (id: number) => await Request(`/grant/duplicate/${id}`, { type: 'post' })

export const deleteGrant = async (id: number) => await Request(`/grant/delete/${id}`, { type: 'delete' })

export const searchGrants = async (data: any) => await Request('/grant/search', { type: 'post', data: { ...data, admin: true } })

export const searchGrantApplications = async (data: any) => await Request('/grant/applications/search', { type: 'post', data: { ...data, admin: true } })

export const getGrantApply = async (id: number) => await Request(`/grant/application/${id}`, { type: 'get' })

export const reviewGrantApply = async (id: number, status: string, reason?: string) => await Request(`/grant/application/review`, { type: 'post', data: { id, status, reason } })

export const deleteGrantApply = async (id: number) => await Request(`/grant/application/${id}`, { type: 'delete' })

export const acceptGrantApply = async (id: number) => await Request(`/grant/application/accept/${id}`, { type: 'post' })

export const addGrantApplyNote = async (application: number, type: 'link' | 'text', content: string, id?:number) => await Request(`/grant/application/note`, {
    type: 'put', data: {
        application,
        type,
        content,
        id
    }
})

export const deleteGrantApplyNote = async (id: number) => await Request(`/grant/application/note/${id}`, { type: 'delete' })

export const getGrantApplyNotes = async (id: number) => await Request(`/grant/application/${id}/notes`, { type: 'get' })