//created by Peggy on 2021/3/11
import React from "react";
import "react-virtualized/styles.css";
import { List, Spin, Tag, Dropdown, Menu, Tooltip, Empty } from "antd";

import { IndexRange } from "react-virtualized";
import WindowScroller from "react-virtualized/dist/commonjs/WindowScroller";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import VList from "react-virtualized/dist/commonjs/List";
import InfiniteLoader from "react-virtualized/dist/commonjs/InfiniteLoader";
import { paymentTypes, statusTypes } from "@/types/enum";

const RenderHeader = ({ column }: any) => {
  return (
    <div className="flex">
      {column.map((item: any) => (
        <span
          className={`th-cell ${
            item?.key === "action" ? "table-cell-action" : ""
          }`}
          key={item.key}
          style={{
            textAlign: item.align || "left",
            flex: item.width ? ` 0 0 ${item.width}px` : 1,
            width: item.width || "auto",
          }}
        >
          {item.title}
        </span>
      ))}
    </div>
  );
};

const RenderFooter = ({ summary, column }: any) => {
  return (
    <div className="flex">
      {column.map((item: any, index: number) => {
        if (index === 0) {
          return (
            <span
              className={`th-cell ${
                item?.key === "action" ? "table-cell-action" : ""
              }`}
              key={item.key}
              style={{
                textAlign: item.align || "left",
                flex: item.width ? ` 0 0 ${item.width}px` : 1,
                width: item.width || "auto",
              }}
            >
              Total
            </span>
          );
        } else if (
          item.key === "amount" ||
          item.key === "fees" ||
          item.key === "received"
        ) {
          return (
            <span
              className="th-cell"
              key={item.key}
              style={{
                textAlign: item.align || "left",
                flex: item.width ? ` 0 0 ${item.width}px` : 1,
                width: item.width || "auto",
              }}
            >
              {Number(summary[item.key] ? summary[item.key] : 0).toLocaleString(
                "en",
                {
                  style: "currency",
                  currency: "USD",
                }
              )}
            </span>
          );
        } else {
          return (
            <span
              className={`th-cell ${
                item?.key === "action" ? "table-cell-action" : ""
              }`}
              key={item.key}
              style={{
                textAlign: item.align || "left",
                flex: item.width ? ` 0 0 ${item.width}px` : 1,
                width: item.width || "auto",
              }}
            >
              {" "}
            </span>
          );
        }
      })}
    </div>
  );
};

interface Props {
  dataSource?: any;
  setHeight?: number;
  height?: number;
  tableCount?: {
    total: number;
    pageIndex: number;
    pageTotal: number;
    fees: number;
    amount: number;
    received: number;
  };
  column?: {
    title: string;
    key: string;
    dataIndex?: string;
    width?: number;
    align?: string;
    ellipsis?: boolean;
    render?: (text?: any, row?: any, index?: number) => void;
  }[];
  pageChange: () => void;
}

export default class VirtualizedExample extends React.PureComponent<Props, {}> {
  state = {
    data: [],
    loading: false,
  };

  static defaultProps: Props = {
    column: [
      {
        title: "Donor",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Donation Date",
        dataIndex: "startDate",
        width: 150,
        ellipsis: true,
        key: "startDate",
      },
      {
        title: "received date",
        dataIndex: "endDate",
        width: 150,
        ellipsis: true,
        key: "endDate",
      },
      {
        title: "Payment method",
        dataIndex: "payment",
        key: "payment",
        render: (text: string, row: any) => paymentTypes[text || "_null"],
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string, _: any) => (
          <Tag color={statusTypes[text ?? "processing"].color}>
            {statusTypes[text ?? "processing"].label}
          </Tag>
        ),
      },
      {
        title: "Total Amount",
        dataIndex: "amount",
        width: 145,
        align: "right",
        key: "amount",
      },
      {
        title: "Fee",
        dataIndex: "fees",
        width: 90,
        align: "right",
        key: "fees",
      },
      {
        title: "Received Amount",
        dataIndex: "received",
        width: 155,
        align: "right",
        key: "received",
      },
      {
        title: "",
        width: 80,
        align: "right",
        key: "action",
        render: (row: any) => (
          <Dropdown
            trigger={["click"]}
            overlay={() => (
              <>
                <Menu>
                  <Menu.Item>View Detail</Menu.Item>
                  {/*<Menu.Item>Print receipt</Menu.Item>*/}
                </Menu>
              </>
            )}
          >
            <span className="ant-dropdown-link">123</span>
          </Dropdown>
        ),
      },
    ],
    dataSource: [],
    setHeight: 412,
    tableCount: {
      total: 0,
      pageIndex: 0,
      pageTotal: 0,
      fees: 0,
      amount: 0,
      received: 0,
    },
    pageChange: () => {},
  };

  loadedRowsMap: any = {};

  componentDidMount() {
    const { dataSource } = this.props;
    this.setState({
      data: dataSource,
    });
  }

  handleInfiniteOnLoad = ({ startIndex, stopIndex }: IndexRange): any => {
    const { tableCount, pageChange } = this.props;
    this.setState({
      loading: true,
    });
    for (let i = startIndex; i <= stopIndex; i++) {
      // 1 means loading
      this.loadedRowsMap[i] = 1;
    }

    if (tableCount && tableCount?.pageIndex >= tableCount?.pageTotal) {
      // message.info("List loaded all");
      this.setState({
        loading: false,
      });
      return;
    }

    pageChange();

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 3000);
  };

  isRowLoaded = ({ index }: any) => !!this.loadedRowsMap[index - 10];

  renderItem = ({ index, key, style }: any) => {
    const { dataSource: data } = this.props;
    const { column } = this.props;
    const row: any = data[index];
    return row? (
      <List.Item key={key} style={style}>
        <div className="flex">
          {column?.map((item: any, cell) => {
            let _html;
            if (item?.render) {
              _html = item?.render(row[item?.dataIndex], row);
            } else {
              _html = row && item?.dataIndex && row[item?.dataIndex];
            }
            return (
              <div
                className={`table-cell ${
                  item?.key === "action" ? "table-cell-action" : ""
                }`}
                key={item?.key || cell}
                style={{
                  textAlign: item.align || "left",
                  flex: item.width ? `0 0 ${item.width}px` : 1,
                  width: item.width || "auto",
                }}
              >
                {item.ellipsis ? (
                  <Tooltip title={_html}>
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                        padding: "0 16px 0 0 ",
                        width: "100%",
                      }}
                    >
                      {_html}
                    </span>
                  </Tooltip>
                ) : (
                  _html
                )}
              </div>
            );
          })}
        </div>
      </List.Item>
    ) : null;
  };

  render() {
    const {
      dataSource,
      column,
      tableCount,
      setHeight,
      height: VHeight,
    } = this.props;
    return (
      <List
        header={<RenderHeader column={column} />}
        footer={<RenderFooter column={column} summary={tableCount} />}
        className="common-virtual-table-container"
      >
        {(dataSource?.length > 0 && (
          <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }: any) => (
              <InfiniteLoader
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.handleInfiniteOnLoad}
                rowCount={tableCount?.total}
                threshold={5}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <VList
                        height={VHeight || height - (setHeight || 0)}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        overscanRowCount={2}
                        rowCount={tableCount?.total || 0}
                        rowHeight={48}
                        rowRenderer={this.renderItem}
                        onRowsRendered={onRowsRendered}
                        ref={registerChild}
                        // scrollTop={scrollTop}
                        width={width}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </WindowScroller>
        )) || <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {this.state.loading && <Spin className="demo-loading" />}
      </List>
    );
  }
}
