import { Action, justBack } from "use-reaction";

import { login, getUserInfo } from "@/api/sign-api";

import {
  setToken,
  getToken,
  localStorageSet,
  localStorageGet,
} from "@/utils/storage";

interface User {
  info: any;
  fromAdmin: boolean;
  progress: number;
  token: string;
  roles: string[];
  test: number;
}

export const user: User = {
  test: 0,
  token: getToken() || "",
  fromAdmin: localStorageGet("fromAdmin") || false,
  progress: localStorageGet("progress") || 0,
  roles: localStorageGet("roles") || [],
  info: localStorageGet("info") || {
    avatar: "",
    firstName: "",
    lastName: "",
    phone: "",
    addresses: [],
    city: "",
    state: "",
    zipCode: "",
    companyName: "",
    tags: [],
  },
};

export const userLogin: Action<User> = async ({ store, payload }) => {
  try {
    let data: any = {};
    if (payload?.save) {
      data.token = payload.token;
    } else {
      data = await login(payload);
    }
    setToken(data?.token);
    localStorageSet("fromAdmin", payload?.fromAdmin);
    return {
      fromAdmin: !!payload?.fromAdmin,
      token: data?.token,
    };
  } catch (e) {
    return justBack("false");
  }
};

export const saveToken: Action<User> = async ({ store, payload }) => {
  setToken(payload);
  return {
    token: payload,
  };
};

// remove this after finish test
export const testUser: Action<User> = async ({ store }) => {
  return { test: store.test + 1 };
};

export const getUser: Action<User> = async ({ store, payload }) => {
  try {
    let { progress, roles, ...others }: any = await getUserInfo();
    localStorageSet("progress", progress);
    localStorageSet("roles", roles);
    localStorageSet("info", others);
    return {
      progress,
      roles,
      info: others,
    };
  } catch (e: any) {
    return Promise.resolve(e);
  }
};

export const saveUser: Action<User> = async ({
  payload: { progress, roles = [], ...others },
  store,
}) => {
  const info = localStorageGet("info");
  progress && localStorageSet("progress", progress);
  roles && localStorageSet("roles", roles);
  localStorageSet("info", {...info,...others});
  try {
    return {
      progress: progress || store.progress,
      roles: roles || store.roles,
      info: { ...store?.info, ...others },
    };
  } catch (e: any) {
    return Promise.resolve(e);
  }
};

export const loginOut: Action<User> = async () => {
  localStorage.clear();
  return {
    token: "",
    progress: 0,
    roles: [],
    info: {
      avatar: "",
      firstName: "",
      lastName: "",
      phone: "",
      addresses: [],
      city: "",
      state: "",
      zipCode: "",
      companyName: "",
      tags: [],
    },
    fromAdmin: false,
    test: 0,
  };
};
