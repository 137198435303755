//created by Peggy on 2021/4/23
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { model_admin_user, BackLoginAction } from "../models/model_admin_user";
import qs from "query-string";

import { useModel } from "use-reaction";

const Auth = () => {
  const { token } = useParams() as any;
  const history = useHistory();
  const { doAction } = useModel(model_admin_user);
  const { to } = qs.parse(history.location.search)

  const handleRedirect = async () => {
    await doAction(BackLoginAction, { token });

    history.push(to as string || "/users");
  };

  useEffect(() => {
    handleRedirect();
    // eslint-disable-next-line
  }, []);

  return <div className="Auth-container">link ...</div>;
};

export default Auth;
