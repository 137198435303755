import React, { useState } from "react";
import { Input, Popover } from "antd";
import styled from "styled-components";

const PasswordContainer = styled.div``;

const ContentContainer = styled.div`
  p {
    position: relative;
    padding-left: 16px;
    color: #999;
    :before {
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      margin-right: 8px;
      border: 1px solid currentColor;
      border-radius: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
    }
    &.active {
      color: #304659;
      &:before {
        background-color: #304659;
      }
    }
  }
`;

interface Props {
  max?: number;
  value?: string | number;
  onChange?: (e: string | number | undefined) => void;
  visibilityToggle?: boolean;
  popUpShow?: boolean;
  placeholder?: string;
}

const checkStrong = (str: string) => {
  let modes: number[] = [];
  if (str.length < 8) return modes;
  if (str.length >= 8 && str.length <= 30) modes.push(5); //特殊字符
  if (/\d/.test(str)) modes.push(1); //数字
  if (/[a-z]/.test(str)) modes.push(2); //小写
  if (/[A-Z]/.test(str)) modes.push(3); //大写
  if (/\W/.test(str)) modes.push(4); //特殊字符
  //逻辑处理
  return modes;
};

const Content = ({ check: array }: { check: number[] }) => (
  <ContentContainer>
    <p className={array.includes(5) ? "active" : ""}>
      Between 8 and 30 characters
    </p>
    <p className={array.includes(3) ? "active" : ""}>
      Contain at least one uppercase, or capital, letter
    </p>
    <p className={array.includes(2) ? "active" : ""}>
      Contain at least one lowercase letter
    </p>
    <p className={array.includes(1) ? "active" : ""}>
      Contain at least one number digit
    </p>
    <p className={array.includes(4) ? "active" : ""}>
      Contain at least one special character
    </p>
  </ContentContainer>
);

const Index = ({
  value = "",
  max = 30,
  onChange = () => {},
  visibilityToggle = true,
  popUpShow = true,
  placeholder,
}: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const onInputChange = (e: any) => {
    e.stopPropagation();
    // if (e.target.value) {
    //   // !show && setShow(true);
    // } else {
    //   // setShow(false);
    // }
    onChange(e.target.value);
  };

  // useEffect(() => {
  //   // eslint-disable-next-line
  // }, [value]);

  return (
    <PasswordContainer>
      <Popover
        placement="bottom"
        title="Your password must"
        content={<Content check={checkStrong(value.toString())} />}
        trigger="focus"
        visible={popUpShow && show}
      >
        <Input.Password
          visibilityToggle={visibilityToggle}
          maxLength={max}
          value={value}
          onChange={onInputChange}
          onFocus={() => setShow(true)}
          onBlur={() => setShow(false)}
          autoComplete="off"
          placeholder={placeholder}
        />
      </Popover>
    </PasswordContainer>
  );
};

export default Index;
