import { KV } from "use-reaction";
import Request from "./fetch";

const config:KV = {
    DONATION_TO_FIELD_FEE:0.029,
    WEB_HOST:'https://staging.thefield.org',
    ADMIN_HOST:'https://admin.staging.thefield.org',
    IS_PROD: false
};

(function loadCfg() {
  Request('/health-check/fe-cfg', {type: 'get'}).then(cfg => {
      Object.assign(config, cfg)
      // load paypal sdk
      const paypalSdk = cfg.IS_PROD? 'https://www.paypal.com/sdk/js?client-id=Aca0bXPAiS7dDMLIOOhIDcpB_dDVPbOu2QJ1dpliRM4puTUhQ1mJZVB0ABFxvnv6-WoCB4lmnlZuvMLn&vault=true&locale=en_US' : 
      'https://www.paypal.com/sdk/js?client-id=AanZnEnasOs1ymLTCgRaNN0LScpBPntaGSFz6yXKhxEI5128pKyNaWnvjMsoajIPG2DzS8EKV7Z2E8sp&vault=true&debug=true'
      const script = document.createElement('script')
      script.src = paypalSdk
      document.head.append(script)
  })  
})()

export function getEnv(key: string) {
    return config[key] || process.env[key]
}