import React, { useEffect, useState } from "react";
import { KV, useModel } from "use-reaction";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "@/images/logo-text.svg";
import { Collapse, Menu, Spin } from "antd";
import {
  getUserInfoAction,
  model_admin_user,
} from "../models/model_admin_user";
import { ReactComponent as UsersIcon } from "@/images/icon_menu_users.svg";
import { ReactComponent as AdminsIcon } from "@/images/icon_menu_admins.svg";
import { ReactComponent as ArtistIcon } from "@/images/icon_menu_artist.svg";
import { ReactComponent as DonorIcon } from "@/images/icon_menu_donation.svg";
import { ReactComponent as GrantIcon } from "@/images/icon_menu_grant.svg";
import { ReactComponent as EventIcon } from "@/images/icon_menu_event.svg";
import { ReactComponent as FinanceIcon } from "@/images/icon_menu_finance.svg";
import { ReactComponent as MessageIcon } from "@/images/icon_menu_message.svg";
import { ReactComponent as DocumentIcon } from "@/images/icon_menu_document.svg";
import { ReactComponent as ScheduleIcon } from "@/images/icon_scheduling.svg";
import user_img from "@/images/admin_icon_user.svg";
import {
  admin_router_application_list,
  admin_router_application_membership,
  admin_router_artists,
  admin_router_donors,
  admin_router_events,
  admin_router_event_field,
  admin_router_finance_contribution,
  admin_router_finance_order,
  admin_router_finance_inkind,
  admin_router_program_coupon,
  admin_router_grant_list,
  admin_router_message,
  admin_router_sign_in,
  admin_router_users,
  home_routers,
  admin_router_documents,
  admin_router_event_crowd,
  admin_router_crowd_field,
  admin_router_change_pwd,
  admin_router_consultation,
  admin_router_consultation_schedule,
  admin_router_consultation_staff,
  admin_router_consultation_topic,
  admin_router_admins,
} from "../routers";
import "./admin_home.less";
import { localStorageGet, setToken } from "@/utils/storage";
import { useLoadingHandler, useRequestHandler } from "@/libs/fetch";
import { getUnreadCount } from "@/api/message-api";
import { useWindowSize } from "react-use";
import { model_admin_size, up_size_action } from "../models/model_admin_size";
import { intersection } from "lodash";
import { UserRole } from "@/types/enum";
const { SubMenu } = Menu;

const default_active_router = admin_router_artists;

export const AdminHome = (props: KV) => {
  const userInfo = localStorageGet("info");
  useRequestHandler();
  const { width: winWd, height: winHt } = useWindowSize();

  const [show] = useLoadingHandler();
  const history = useHistory();
  const { store, resetModel, doAction } = useModel(model_admin_user);
  const { doAction: upSize } = useModel(model_admin_size);
  const onSignOut = () => {
    setToken("");
    resetModel();
  };
  const onResetPwd = () => {
    history.push(admin_router_change_pwd);
  };
  const tryLogin = async () => {
    const res = await doAction(getUserInfoAction);
    if (res && !res.logined) {
      history.replace(admin_router_sign_in);
    } else if (res && res.logined) {
      if (history.location.pathname === "/") {
        history.push(default_active_router);
      }
    }
  };
  useEffect(() => {
    !store.logined && tryLogin();
    // eslint-disable-next-line
  }, [store.logined]);

  useEffect(() => {
    upSize(up_size_action, { width: winWd, height: winHt });
  }, [winHt]);
  return (
    <>
      <div className="admin-layout-container flex start">
        <aside className="flex column">
          <Link to="/" className="logo">
            <Logo />
          </Link>
          <MenuLayout />
          <div className="admin-avatar-bar">
            <Collapse expandIconPosition="right">
              <Collapse.Panel
                header={
                  <div className="user-wrap flex start">
                    <span>
                      <img src={store?.info?.avatar || user_img} alt="user" />
                      {userInfo?.roles?.includes(UserRole.SUPER_ADMIN)
                        ? "Super Admin"
                        : "Admin"}
                    </span>
                  </div>
                }
                key="1"
              >
                <div className="admin-func-item" onClick={onResetPwd}>
                  Change password
                </div>
                <div className="admin-func-item" onClick={onSignOut}>
                  Sign out
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
        </aside>
        <div className="main-content">
          <Switch>
            {home_routers.map((r) => {
              if (
                r.roles &&
                intersection(r.roles, userInfo?.roles ?? [])?.length < 1
              ) {
                return null;
              }
              return <Route {...r} key={String(r.path)} exact strict />;
            })}
          </Switch>
        </div>
      </div>
      {(!store.logined || show) && <Spin className="global-spin-container" />}
    </>
  );
};

const MenuLayout = () => {
  const userInfo = localStorageGet("info");
  const [activeKey, setActiveKey] = useState(default_active_router);
  const history = useHistory();
  const location = useLocation();
  const handleClick = (e: any) => {
    setActiveKey(e.key);
    history.push(e.key);
  };
  const [unreadMsgCnt, setUnreadMsgCnt] = useState(0);
  useEffect(() => {
    getUnreadCount().then((data) => {
      setUnreadMsgCnt(data?.count || 0);
    });
  }, [activeKey]);
  useEffect(() => {
    if (location.pathname) {
      if (location.pathname.startsWith(admin_router_admins)) {
        setActiveKey(admin_router_admins);
        return;
      }
      if (activeKey !== location.pathname) {
        setActiveKey(location.pathname);
        return;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <Menu
      onClick={handleClick}
      style={{ width: 300 }}
      activeKey={activeKey}
      selectedKeys={[activeKey]}
      mode="inline"
      className="menu-layout-container admin-side-menu"
    >
      <Menu.Item key={admin_router_users} icon={<UsersIcon />}>
        Users
      </Menu.Item>
      <Menu.Item key={admin_router_artists} icon={<ArtistIcon />}>
        Artists
      </Menu.Item>
      <Menu.Item key={admin_router_donors} icon={<DonorIcon />}>
        Donors
      </Menu.Item>
      <SubMenu key="/application" icon={<GrantIcon />} title="Applications">
        <Menu.Item key={admin_router_application_membership}>
          Fiscal Sponsorship
        </Menu.Item>
        <SubMenu key="/application/grants" title="Grants & Solicitations">
          <Menu.Item key={admin_router_grant_list}>Grants List</Menu.Item>
          <Menu.Item key={admin_router_application_list}>
            Applications List
          </Menu.Item>
        </SubMenu>
      </SubMenu>
      <SubMenu
        key={admin_router_consultation}
        icon={<ScheduleIcon />}
        title="Consultation"
      >
        <Menu.Item key={admin_router_consultation_schedule}>Schedule</Menu.Item>
        <Menu.Item key={admin_router_consultation_staff}>Staff list</Menu.Item>
        <Menu.Item key={admin_router_consultation_topic}>Topic list </Menu.Item>
      </SubMenu>
      <SubMenu key="/activity" icon={<EventIcon />} title="Activities">
        <Menu.Item key={admin_router_event_crowd}>
          Crowdfunding Campaigns
        </Menu.Item>
        <Menu.Item key={admin_router_events}>Artist events</Menu.Item>
      </SubMenu>
      <SubMenu key="/finance" icon={<FinanceIcon />} title="Finance">
        <Menu.Item key={admin_router_finance_contribution}>
          Contributions
        </Menu.Item>
        <Menu.Item key={admin_router_finance_order}>Orders</Menu.Item>
        <Menu.Item key={admin_router_finance_inkind}>
          In-Kind Donations
        </Menu.Item>
      </SubMenu>
      <SubMenu key="/program" icon={<FinanceIcon />} title="Program">
        <Menu.Item key={admin_router_event_field}>The Field events</Menu.Item>
        <Menu.Item key={admin_router_crowd_field}>
          The Field Crowdfunding
        </Menu.Item>
        <Menu.Item key={admin_router_program_coupon}>Coupons</Menu.Item>
      </SubMenu>
      <Menu.Item key={admin_router_message} icon={<MessageIcon />}>
        Message
        <span
          className="red-dot"
          hidden={
            unreadMsgCnt === 0 || activeKey.includes(admin_router_message)
          }
        ></span>
      </Menu.Item>
      <Menu.Item key={admin_router_documents} icon={<DocumentIcon />}>
        Documents
      </Menu.Item>
      {intersection([UserRole.SUPER_ADMIN], userInfo?.roles ?? [])?.length >
        0 && (
        <Menu.Item key={admin_router_admins} icon={<AdminsIcon />}>
          Admins
        </Menu.Item>
      )}
    </Menu>
  );
};
