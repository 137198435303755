//created by Peggy on 2021/3/1
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Drawer, Form, Select, Button, Dropdown, Menu } from "antd";
import DatePicker from "@/components/DatePicker";

import { ReactComponent as Filter } from "@/images/filter.svg";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { statusTypes } from "@/types/enum";

const SearchContainer = styled.div`
  margin-bottom: 24px;
  .filter-item {
    height: 56px;
    width: 56px;
    line-height: 66px;
    margin-left: 16px;
    padding: 0 16px;
    text-align: center;
    border: 1px solid #e0e0e0;
    color: #000;
    font-size: 18px;
    background-color: #fff;
    cursor: pointer;
    &:hover {
      border-color: #000;
    }
  }
`;

type Action =
  | {
    text?: React.ReactNode;
    onClick?: () => void;
  }
  | string;

interface Props {
  date?: boolean;
  filter?: boolean;
  hiddenPayment?: boolean;
  hiddenDropdown?: boolean;
  value?: any;
  onChange?: (val: any) => void;
  onExport?: (val?: any) => void;
  onCheck?: (val: any) => void;
  action?: Action[];
  custom?: React.ReactNode;
  // initOrderStatus: string[]
}

const Search = ({
  date = true,
  filter = true,
  hiddenPayment = false,
  hiddenDropdown = false,
  value = "",
  onChange = () => { },
  onExport = () => { },
  onCheck = () => { },
  action = [],
  custom
}: Props) => {
  const [val, setVal] = useState(value);
  const [show, setShow] = useState(false);

  useEffect(() => {
    form.setFieldsValue(val)
    onChange(val);
    // eslint-disable-next-line
  }, [val]);

  //drawer
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    const { range, ...other } = values;
    setVal({
      ...val,
      range: range?.split(','),
      ...other,
    });
    setShow(false);
  };
  const onClear = () => {
    form.resetFields();
    form.submit();
  };
  return (
    <SearchContainer className="search-container flex start">
      {date && (
        <div className="range-picker-wrap field-form-items">
          <span>Date range</span>
          <DatePicker.RangePicker
            dropdownClassName="date-picker-mobile"
            onChange={(e) => {
              const [start, end] = e || ["", ""];
              setVal({
                ...val,
                startTime: start,
                endTime: end,
              });
            }}
          />
        </div>
      )}
      {filter && (
        <Filter className="filter-item" onClick={() => setShow(true)} />
      )}
      {!hiddenDropdown && (
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              {(action &&
                action.map((item: any, index) => {
                  if (item === "export") {
                    return (
                      <Menu.Item key={index} onClick={() => onExport()}>
                        Export CSV
                      </Menu.Item>
                    );
                  } else {
                    return (
                      <Menu.Item key={index} onClick={() => item!.onClick()}>
                        {item!.text}
                      </Menu.Item>
                    );
                  }
                })) || (
                  <>
                    <Menu.Item onClick={() => onCheck}>
                      Add check donation
                    </Menu.Item>
                    <Menu.Item onClick={() => onExport()}>Export CSV</Menu.Item>
                  </>
                )}
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      )}
      {
        custom
      }

      <Drawer
        title="Filter"
        placement="right"
        width={374}
        className="common-drawer"
        onClose={() => setShow(false)}
        visible={show}
      >
        <Form layout="vertical" name="campaign" form={form} onFinish={onFinish}>
          <Form.Item label="total amount range" name="range">
            <Select placeholder="Select a option" allowClear>
              <Select.Option value="0,500">0 ~ 500</Select.Option>
              <Select.Option value="500,">500 more</Select.Option>
            </Select>
          </Form.Item>
          {!hiddenPayment && (
            <Form.Item label="payment method" name="paymentMethod">
              <Select placeholder="Select a option" allowClear>
                <Select.Option value="paypal">paypal</Select.Option>
                <Select.Option value="card">card</Select.Option>
                <Select.Option value="ach">ach</Select.Option>
                <Select.Option value="check">check</Select.Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item label="status" name="status">
            <Select placeholder="Select a option" mode="multiple" allowClear>
              {Object.entries(statusTypes).map((item: any) => (
                <Select.Option key={item[0]} value={item[0]}>
                  {item[1].label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Frequency" name="interval">
            <Select placeholder="Select a option" allowClear>
              <Select.Option key='once' value='once'>Once</Select.Option>
              <Select.Option key='month' value='month'>Monthly</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <div className="flex end">
          <Button onClick={() => onClear()}>clear all</Button>
          <Button type="primary" onClick={() => form.submit()}>
            Apply
          </Button>
        </div>
      </Drawer>
    </SearchContainer>
  );
};

export default Search;
