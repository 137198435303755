import request from "@/libs/fetch";

export const sendChatMsg = async (msg: { to?: number, type: 'link' | 'html', content: string }) => await request('/chatmsg', {
    type: 'post',
    data: msg,
    headers: { noLoading: true }
})

export const getMsgList = async (pageIdx: number, pageSize: number = 10, chnl = 0) => await request('/chatmsg/list', {
    type: 'get',
    params: {
        idx: pageIdx,
        size: pageSize,
        chnl
    },
    headers: { noLoading: true }
})
export const initChanel = async (user: number) => await request('/chatmsg/init-channel', { type: 'post', data: { to: user } })
export const getChannels = async () => await request('/chatmsg/channels', { type: 'GET' })
export const getUnreadCount = async () => await request('/chatmsg/unread-count', { type: 'GET', headers: { noLoading: true } })

export const rmChatMsg = async (id: number) => await request(`/chatmsg/${id}`, { type: 'DELETE' })