import { Action, KV } from "use-reaction";
import { getGrant, putGrant } from "../apis/grant-api";

const utc = require("dayjs/plugin/utc");
const timezones = require("dayjs/plugin/timezone");
const dayjs = require("dayjs");
dayjs.extend(utc);
dayjs.extend(timezones);


export const model_grant_post = {
    'NAME': 'model_grant_post',
    id: undefined,
    name: '',
    requireSponsorship: true,
    organization: '',
    link: '',
    award: '',
    description: '',
    discipline: '',
    email: '',
    phone: '',
    location: undefined,
    deadlineType: 'hard',
    deadline: dayjs().format('MM/DD/YYYY'),
    deadlineStr: '',
    rolling: '',
    timezone: '',
    endtime: '',
    thumb: undefined,
    photos: []
}

type GrantModel = typeof model_grant_post
const fillResInfo = (res: any) => {
    const info = { ...res, photos: [], thumb: undefined }
    if (res.photos?.length) {
        info.photos = res.photos.map((url: string) => {
            const idx = url.lastIndexOf('/')
            const name = url.substr(idx + 1)
            const uid = name.split('.')[0]
            const size = 0
            return {
                uid,
                name,
                size,
                url
            }
        })
    }
    if (res.thumb) {
        const url = res.thumb
        const idx = url.lastIndexOf('/')
        const name = url.substr(idx + 1)
        const uid = name.split('.')[0]
        const size = 0
        info.thumb = [{
            uid,
            name,
            size,
            url
        }]
    }
    if (info.deadlineType === 'hard' && info.deadlineStr) {
        const [deadline, timezone, endtime] = info.deadlineStr.split(',')
        Object.assign(info, { deadline, timezone, endtime })
    }
    info.discipline = info.discipline?.split(',')

    return info
}

export const putGrantAction: Action<GrantModel> = async ({ payload, store }) => {
    const { deadlineType, deadline, timezone, endtime, photos, thumb, discipline, ...others } = payload
    const req: KV = { ...others, deadlineType }
    if (deadlineType === 'hard') {
        let dueDate = dayjs(`${deadline} ${endtime}`).tz(timezone).toString()
        req.deadline = dueDate
        // also store tz and time for easy display
        req.deadlineStr = [deadline, timezone, endtime].join(',')
    }
    req.discipline = discipline?.join(',')
    req.photos = photos?.length ? photos.map((d: any) => d.url).filter(Boolean) : undefined
    req.thumb = thumb?.length? thumb[0].url : null
    req.id = store.id
    const res = await putGrant(req)
    if (res?.id) {
        return fillResInfo(res)
    }
}

export const upGrantFieldAction: Action<GrantModel> = ({ payload }) => {
    return payload
}

export const getGrantAction: Action<GrantModel> = async ({ payload }) => {
    const res = await getGrant(payload)
    if (res?.id) {
        return fillResInfo(res)
    }
}