//created by Pegg on 2021/1/21
import React from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";

const BackContainer = styled.span`
  cursor: pointer;
`;

interface Props {
  onClick?: () => void;
  children?: React.ReactNode;
}

const Index: React.FC<Props> = ({ onClick, children = "< Back" }: Props) => {
  const history = useHistory();
  return (
    <BackContainer
      className="back-component-container"
      onClick={() => (onClick ? onClick() : history.goBack())}
    >
      {children}
    </BackContainer>
  );
};

export default Index;
