//created by Peggy on 2021/3/11
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Menu, Dropdown } from "antd";
import { ReactComponent as More } from "@/images/filter-more.svg";

import styled from "styled-components";
const CardItemContainer = styled.div`
  height: 285px;
  .card-item-title {
    height: 58px;
    line-height: 58px;
    padding: 0 32px;
    border-bottom: 1px solid #e0e0e0;
    h3 {
      height: auto;
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 700;
      i {
        font-size: 14px;
        color: #a0a0a0;
        font-weight: normal;
        margin-left: 8px;
        font-family: Roboto;
      }
    }
    a {
      display: inherit;
      height: 24px;
    }
  }
  .card-item-content {
    padding: 32px;
  }
`;

interface Props {
  url?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
}

const CardItem = ({ title = "Income overview", url = "", children }: Props) => {
  const history = useHistory();
  useEffect(() => {}, []);
  return (
    <CardItemContainer className="card-item-container">
      <div className="card-item-title flex">
        <h3>{title}</h3>
        {url && (
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item onClick={() => history.push(url)}>
                  View detail
                </Menu.Item>
              </Menu>
            )}
          >
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              <More />
            </span>
          </Dropdown>
        )}
      </div>
      {children}
    </CardItemContainer>
  );
};

export default CardItem;
