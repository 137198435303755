//
import request from "@/libs/fetch";
import Request from "@/libs/fetch";

export const crowListLive = async () =>
  await request("/crowdfunding/live", {
    type: "get",
  });
export const crowListPast = async (params: any) =>
  await request("/crowdfunding/past", {
    params,
    type: "get",
  });

export const crowPublish = async (data: any) =>
  await request("/crowdfunding/publish", {
    data,
  });

export const crowSave = async (data: any) =>
  await request("/crowdfunding", {
    data,
  });

export const crowDelete = async (data: any) =>
  await request(`/crowdfunding/${data.id}`, {
    data,
    type: "delete",
  });

export const crowClose = async (data: any) =>
  await request(`/crowdfunding/close`, {
    data,
  });

export const crowPut = async (id: string | number, data: any) =>
  await request(`/crowdfunding/${id}`, {
    data,
    type: "put",
  });
export const crowGet = async (params: any) =>
  await request(`/crowdfunding`, {
    params,
    type: "get",
  });

export const crowedFundIncome = async ({ id, ...params }: any) =>
  await request(`/crowdfunding/${id}/income`, {
    type: "get",
    params
  });

export const incomeList = async (params: any) =>
  await request(`/income`, {
    params,
    type: "get",
    headers: {
      noLoading: true,
    },
  });

export const incomeArchiveList = async ({ date, ...params }: any) =>
  await request(`/income`, {
    params: { ...params, archived: true, ...(date?.length > 1 ? { startTime: date[0].toISOString(), endTime: date[1].toISOString() } : {}) },
    type: "get",
    headers: {
      noLoading: true,
    },
  });
export const incomeCheckHistoryList = async ({ date, ...params }: any) =>
  await request(`/income/check-history`, {
    params: { ...params, ...(date?.length > 1 ? { startDate: date[0].toISOString(), endDate: date[1].toISOString() } : {}) },
    type: "get",
    headers: {
      noLoading: true,
    },
  });

export const incomeDetail = async (params: any) =>
  await request(`/income/detail`, {
    params,
    type: "get",
  });

export const donationOverview = async (params: any) =>
  await request(`/income/overview`, {
    type: "get",
    params
  });

export const InKindList = async (params: any) =>
  await request(`/income`, {
    params,
    type: "get",
  });

export const InKindAdd = async (data: any) =>
  await request(`/donation/in-kind`, {
    data,
  });

export const checkAdd = async (data: any) =>
  await request(`/donation/check`, {
    data,
  });

export const getBillInfo = async (params: any, flag?: boolean) =>
  await request("/donation/stripe-card-information", {
    params,
    type: "get",
    headers: { noLoading: !!flag },
  });

export const getStripeSecret = async (data: any) =>
  await request("/donation/by-stripe", {
    data,
  });

export const createPayPal = async (data: any) =>
  await request("/donation/create-paypal-transaction", {
    data,
  });

export const capturePayPal = async (data: any) =>
  await request("/donation/capture-paypal-transaction", {
    data,
  });

export const orderDetail = async (id: number) =>
  await Request(`/contribution/detail/${id}`, { type: "get" });

export const donationHistoryList = async (params: any) =>
  await Request(`/donation/list`, { type: "get", params });

export const donationMonthSubscribeStripe = async (data: any) =>
  await Request(`/donation/subscribe-by-stripe`, { type: "post", data });

export const donationMonthSubscribePayPal = async (data: any) =>
  await Request(`/donation/subscribe-by-paypal`, { type: "post", data });

export const donationAch = async (data: any) =>
  await Request(`/donation/by-ach`, { type: "post", data });

export const donationMonthSubscribePayPalCheck = async (data: any) =>
  await request("/donation/check-paypal-subscription", {
    data,
    type: "post",
  });
export const reportCrowd = async (id: number, reason: string) => await request('/crowdfunding/report', { data: { id, reason }, type: 'post' })

export const myTransactions = async (data: any) => await request('/contribution/transactions', { type: 'get', params: data })
