import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export enum StaffStatus {
  DRAFT = "draft",
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const staffStatusMap = {
  [StaffStatus.ACTIVE]: { text: "Active", color: "green" },
  [StaffStatus.INACTIVE]: { text: "Inactive", color: "red" },
  [StaffStatus.DRAFT]: { text: "Draft", color: "grey" },
} as const;

export enum PriceModel {
  STANDARD = 'standard',
  ROLE_TIER = 'role_tier',
}