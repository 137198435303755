import Request from "@/libs/fetch";

export const donorList = async (params?: any) =>
  await Request("/user/donor/list", { type: "get", params });

export const donorAddNote = async (data?: any) =>
  await Request('/user/donor/note', { type: "post", data });

export const donorReport = async (params?: any) =>
  await Request('/contribution/report', { type: "get", params });

export const donorLastDonation = async (data?: any) =>
  await Request('/contribution/lastone/detail', { type: "post", data });
