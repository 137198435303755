//created by Pegg on 2021/2/10
import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Divider,
  Dropdown,
  Menu,
  Tag,
  Modal,
  Empty,
  message,
} from "antd";
import DatePicker from "@/components/DatePicker";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import styled from "styled-components";
import {
  crowDelete,
  crowPut,
  crowPublish,
  crowClose,
} from "@/api/donation-api";

import avatar from "@/images/avatar.svg";
import icon_donation from "@/images/icons/icon_donation.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getEnv } from "@/libs/cfg";
import Info from "@/components/Modal/Info";
import { localStorageGet } from "@/utils/storage";
import useConfirm from "@/components/Modal/Confirm";
import { UserRole } from "@/types/enum";
import { admin_router_crowd_field_preview, admin_router_crowd_field_edit, admin_router_crowd_field_donation_detail } from "@/adminApp/routers";
import '@/styles/home/event.less';
import { NoTranslate, TheField } from "@/components/GoogleTranslate";

const CampaignContainer = styled.div``;

interface Props {
  list: any;
  pastSearch: (val: any) => void;
  liveRefresh: () => void;
}

const Campaign = ({
  list = null,
  pastSearch = (val) => { },
  liveRefresh = () => { },
}: Props) => {
  const history = useHistory();
  const [current, setCurrent] = useState<any>({
    id: 0,
    name: "",
    time: undefined as any,
  });
  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);
  const detailPath = isAdmin ? admin_router_crowd_field_donation_detail : '/dashboard/donation/detail/:id'

  const handleSave = (type: string, item: any) => {
    if (type === "live") {
      setCurrent(item)
      setTimeout(() => {
        setShowPub(true)
      }, 66);
    } else {
      let _data = {
        status: type,
      };
      crowPut(item.id, _data).then((data) => {
        liveRefresh();
      });
    }
  };
  const handleClose = (id: number, status: string, name: string) => {
    Modal.confirm({
      title: "Are you sure you want to close campaign ?",
      icon: <ExclamationCircleOutlined />,
      content: `You are deleting a ${status} version of the crowdfunding campaign ${name}. This action can’t be undone. `,
      okText: "close campaign",
      cancelText: "cancel",
      onOk: () => {
        let _data = {
          id,
        };
        crowClose(_data).then((data) => {
          liveRefresh();
          message.success("Close success");
        });
      },
    });
  };
  const handleDelete = (id: number, status: string, name: string) => {
    Modal.confirm({
      title: "Are you sure you want to delete?",
      icon: <ExclamationCircleOutlined />,
      content: `You are deleting a ${status} version of the crowdfunding campaign ${name}. This action can’t be undone. `,
      okText: "Delete",
      cancelText: "cancel",
      onOk: () => {
        let _data = {
          id,
        };
        crowDelete(_data).then((data) => {
          liveRefresh();
          message.success("delete success");
        });
      },
    });
  };
  const handleEdit = (id: number) => {
    const createPath = (isAdmin ? admin_router_crowd_field_edit : '/dashboard/donation/create/').split(':')[0] + id
    history.push(createPath);
  };

  const menu = ({ status, name, id }: any) => (
    <Menu>
      <Menu.Item onClick={() => handleEdit(id)}>Edit</Menu.Item>
      {status === "draft" ? (
        <>
          <Menu.Item onClick={() => handleDelete(id, status, name)}>
            Delete
          </Menu.Item>
          {
            isAdmin &&
            <Menu.Item onClick={() => history.push(admin_router_crowd_field_preview.replace(':id', id))}>
              Preview live site
            </Menu.Item>
          }
        </>
      ) : (
        <>
          <Menu.Item
            onClick={() => history.push(detailPath.replace(':id', id))}
          >
            View detail
          </Menu.Item>
          {
            !isAdmin &&
            <Menu.Item
              onClick={() =>
                history.push(`/home/profile/${localStorageGet("info")?.id}`)
              }
            >
              View on artist profile
            </Menu.Item>
          }
          <Menu.Item onClick={() => handleClose(id, status, name)}>
            close campaign
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  const { setShow: showCreatePopup, component: createCrowdPopup } = useConfirm(
    <div className="create-crowd-popup">
      Before you begin, have you:
      <br />
      • Thought about your timing / budget / audience? We recommend reading our article on&nbsp;
      <a href="https://www.thefield.org/resource-crowdfunding-campaign/" target='_blank' rel="noreferrer">
        how to run a successful crowdfunding campaign.
      </a>
      <br />
      • Read how crowdfunding works on <TheField/>? If not, <a href="https://www.thefield.org/crowdfunding/" target='_blank' rel="noreferrer" >please review our guidelines here.</a>
      <br />
      • Please note: You are NOT permitted to offer rewards in the form of goods or services in exchange for donations.
      This is in order for crowdfunding contributions to be fully tax-deductible.
      Campaigns offering goods or services as rewards will be flagged for removal.
      <br />

    </div>,
    {
      title: 'Creating crowdfunding campaign',
      okText: 'Continue',
      onOk: () => history.push(isAdmin ? admin_router_crowd_field_edit.split(':')[0] : "/dashboard/donation/create")
    }
  )
  //info
  const [showPub, setShowPub] = useState(false);
  const [show, setShow] = useState(false);
  return (
    <CampaignContainer className="campaign-container">
      <h1 className="flex">
        Crowdfunding campaigns{" "}
        <Button
          type="primary"
          // onClick={() => history.push("/dashboard/donation/create")}
          onClick={() => showCreatePopup(true)}
        >
          Create Crowdfunding Campaign
        </Button>
      </h1>
      {list!.onGoing!.length ? (
        <>
          <div className="campaign-on-wrap">
            <h4 className="title">Current</h4>
            {list!.onGoing!.map((item: any) => (
              <div key={item.name} className="campaign-list">
                <div className="flex start">
                  <img
                    src={(item?.photos && item?.photos[0]) || avatar}
                    alt=""
                  />
                  <div className="flex column campaign-info-wrap">
                    <div className="flex info-title">
                      <h2 className="flex start">
                        <NoTranslate>{item?.name}</NoTranslate>
                        {item.status === "draft" ? (
                          <Tag color="gray">Draft</Tag>
                        ) : (
                          <Tag color="blue">live</Tag>
                        )}
                      </h2>
                      <span>
                        Last updated at&nbsp;
                        {dayjs(item.updateDate).format("MM/DD/YYYY h:mm A")}
                      </span>
                    </div>
                    <div className="flex align-end">
                      <ul>
                        <li>
                          <span>Date</span>{" "}
                          {dayjs(item.startDate).format("MM/DD/YYYY")} - &nbsp;
                          {dayjs(item.endDate).format("MM/DD/YYYY")}
                        </li>
                        <li>
                          <span>Goal</span>{" "}
                          {Number(item?.goalAmount).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </li>
                        <li>
                          <span>Raised</span>
                          {Number(item?.currentAmount).toLocaleString("en", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </li>
                      </ul>
                      <div className="btn flex end">
                        <Dropdown
                          trigger={["click"]}
                          overlay={() => menu(item)}
                        >
                          <span className="ant-dropdown-link">
                            <EllipsisOutlined />
                          </span>
                        </Dropdown>
                        {item?.status === "live" ? (
                          <Button
                            type="primary"
                            onClick={() =>
                              history.push(
                                isAdmin ? admin_router_crowd_field_preview.replace(':id', item.id) :
                                  `/home/donation/crowd/preview/${item.id
                                  }/${item.name.replace(/[^\w]/g, "-")}`
                              )
                            }
                          >
                            View live site
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            onClick={() =>
                              handleSave("live", item)
                            }
                          >
                            Publish
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Divider />
        </>
      ) : (
        ""
      )}

      <div className="range-picker-wrap field-form-items">
        <span>Date range</span>
        <DatePicker.RangePicker
          dropdownClassName="date-picker-mobile"
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          onChange={(e: any) => {
            const [start, end] = e || ["", ""];
            pastSearch({
              start,
              end,
            });
          }}
        />
      </div>
      <div className="campaign-on-wrap campaign-past-wrap">
        <h4 className="title">Past campaigns</h4>

        {list!.past?.length ? (
          list!.past?.map((item: any) => (
            <div className="campaign-list" key={item.id}>
              <div key={item.name} className="flex start">
                <img src={(item?.photos && item?.photos[0]) || avatar} alt="" />
                <div className="flex column campaign-info-wrap">
                  <div className="flex info-title">
                    <h2 className="flex start">{item?.name}</h2>
                    <span>
                      Last updated at{" "}
                      {dayjs(item.updateDate).format("DD/MM/YYYY h:mm A")}
                    </span>
                  </div>
                  <div className="flex align-end">
                    <ul>
                      <li>
                        <span>Date</span>
                        {dayjs(item.startDate).format("MM/DD/YYYY")} - &nbsp;
                        {dayjs(item.endDate).format("MM/DD/YYYY")}
                      </li>
                      <li>
                        <span>Goal</span>{" "}
                        {Number(item?.goalAmount).toLocaleString("en", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </li>
                      <li>
                        <span>Raised</span>
                        {Number(item?.currentAmount).toLocaleString("en", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </li>
                    </ul>
                    <div className="btn flex end">
                      <Button
                        onClick={() =>
                          history.push(detailPath.replace(':id', item.id))
                        }
                      >
                        View detail
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Empty description={false} />
        )}
      </div>
      {createCrowdPopup}
      <InfoModal {...{ current, history, show, setShow, detailPath }} />
      <CrowdPublishPopup {...{
        current, setCurrent, history, show: showPub, setShow: setShowPub, next: () => {
          liveRefresh();
          setCurrent({
            ...current,
            status: 'live'
          });
          setShow(true)
        }
      }} />
    </CampaignContainer>
  );
};

interface InfoModalProps {
  current: {
    id: string | number;
    name: string;
    time?: any;
    goalAmount?: string | number;
  };
  history: any;
  show: boolean;
  setShow: (val: boolean) => void;
  detailPath?: string
}

export const InfoModal = memo(
  ({ current, history, show, setShow, detailPath = '/dashboard/donation/detail/:id' }: InfoModalProps) => (
    <Info
      className="event-info-modal"
      visible={show}
      width={660}
      footer={[
        <Button onClick={() => history.push("/dashboard/message")}>
          contact us
        </Button>,
        <div>
          <Button onClick={() => setShow(false)}>Later</Button>
          <Button
            onClick={() =>
              history.push(detailPath.replace(':id', current.id + ''))
            }
            type="primary"
          >
            view fund details
          </Button>
        </div>,
      ]}
      img={icon_donation}
    >
      <h1>
        Congratulations!
        <br />
        Your crowdfunding campaign has been published!
      </h1>
      <p>
        Your campaign is scheduled to begin on {dayjs(current.time?.start).format("MMMM DD ,  YYYY | hh:mm A ") + current.time?.timezone},
        but you can share your link on social media now - or wait until it’s live!
      </p>
      <div className="field-form-items flex column start align-start">
        <span>campaign url</span>
        <CopyToClipboard
          text={`${getEnv("WEB_HOST")}/home/donation/crowd/view/${current.id
            }/${current.name.replace(/[^\w]/g, "-")}`}
          onCopy={() => message.success("Copy success")}
        >
          <p className="flex">
            <i>{`${getEnv("WEB_HOST")}/home/donation/crowd/view/${current.id
              }/${current.name.replace(/[^\w]/g, "-")}`}</i>
            <span>copy Link</span>
          </p>
        </CopyToClipboard>
      </div>
    </Info>
  )
);

interface CrowdPublishPopupProp extends InfoModalProps {
  next?: () => void,
}

export const CrowdPublishPopup = ({
  current,
  history,
  show,
  setShow,
  next = () => { },
}: CrowdPublishPopupProp) => {

  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);
  const createPath = (isAdmin ? admin_router_crowd_field_edit : '/dashboard/donation/create/').split(':')[0] + current.id
  return <Modal
    title='Are you ready to go live?'
    okText='Yes, publish now'
    cancelText='Back to edit'
    closable
    className="common-modal"
    visible={show}
    width={570}
    onCancel={(e) => {
      setShow(false)
      if (!e.currentTarget.className.includes('ant-modal-close')) {
        history.push(createPath)
      }
    }}
    onOk={() => {
      setShow(false)
      let _data = {
        id: current.id,
      };
      crowPublish(_data).then((data) => {
        next()
      });
    }
    }
  >
    <div className="publish-confirm">
      Please remember that, once you publish your campaign,
      <br />
      &nbsp; • You cannot change the closing date.
      <br />
      &nbsp; • You cannot change the goal amount.
      <br />
      <br />
      Have you offered rewards OTHER THAN a “shout-out” on social media,
      or acknowledging donors in credits / programs? If you have,
      please go back and remove the non-permitted rewards.
      Campaigns offering goods or services as rewards will be flagged for removal.
      <br />
      <br />
      Here’s a summary of your campaign:
      <br />
      &nbsp; • Campaign Name: {current.name}
      <br />
      &nbsp; • URL: {`${getEnv("WEB_HOST")}/home/donation/crowd/view/${current.id
        }/${current.name.replace(/[^\w]/g, "-")}`}
      <br />
      &nbsp; • Goal Amount: {Number(current.goalAmount).toLocaleString('en', {
        style: "currency",
        currency: "USD",
      })}
      <br />
      &nbsp; • Start Date: {dayjs(current.time?.start).format("MM/DD/YYYY")}
      <br />
      &nbsp; • End Date: {dayjs(current.time?.end).format("MM/DD/YYYY")}
      <br />
      <br />
      Does everything look correct?

    </div>
  </Modal>
}

export default Campaign;
