/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  Select,
  message,
  Switch,
} from "antd";
import { CloudUploadOutlined, DeleteOutlined } from "@ant-design/icons";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import { useHistory, useParams } from "react-router-dom";
import AmountInput from "@/components/Input/AmountInput";
import { useRequest } from "ahooks";
import {
  addStaff,
  getCalendlyEventTypeByUser,
  getCalendlyUsers,
  getConsultationTopics,
  getStaff,
  updateStaff,
} from "@/adminApp/apis/consultation-api";
import { ConfigProvider } from "antd";
import { admin_router_consultation_staff } from "@/adminApp/routers";
import { StaffStatus } from "./shared";
import { useState } from "react";
import "./edit.less";
import { formatDuration } from "../scheduling/shared";

export const ConsultationStaffEdit = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isEdit = !isNaN(+id) && id !== "";
  const [form] = Form.useForm();
  const [calendlyId, setCalendlyId] = useState("");
  const topicsRequest = useRequest(getConsultationTopics);
  const getStaffRequest = useRequest(() => getStaff(id), {
    refreshDeps: [id],
    ready: isEdit,
    onSuccess: (res) => {
      res.topics = res.topics?.split(",");
      res.photo = [
        {
          uid: 0,
          name: res.photo?.split("/")?.pop(),
          status: "done",
          url: res.photo,
        },
      ];
      if (res.calendlyId) {
        setCalendlyId(res.calendlyId);
      }
      form.setFieldsValue(res);
    },
  });
  const addStaffRequest = useRequest(addStaff, { manual: true });
  const updateStaffRequest = useRequest(updateStaff, { manual: true });
  const calendlyUsersRequest = useRequest(getCalendlyUsers);
  const eventTypesRequest = useRequest(
    async () => {
      if (calendlyId) {
        return getCalendlyEventTypeByUser(calendlyId);
      }
      return [];
    },
    {
      refreshDeps: [calendlyId],
    }
  );
  const calendlyUsers = calendlyUsersRequest.data?.collection || [];
  const eventTypes = eventTypesRequest.data?.collection || [];

  const handleSubmit = async (draft = false) => {
    if (!draft) {
      try {
        await form.validateFields();
      } catch (error) {
        return message.error(error?.errorFields?.[0]?.errors?.[0]);
      }
    }
    const data = { ...getStaffRequest.data, ...form.getFieldsValue() };
    if (!data.photo?.[0]?.url) {
      return message.error("Please upload a photo");
    }
    data.photo = data.photo[0]?.url;
    data.status = draft ? StaffStatus.DRAFT : data.status ?? StaffStatus.ACTIVE;
    data.topics = data.topics.join(",");
    if (isEdit) {
      await updateStaffRequest.runAsync(data);
      message.success("Staff updated successfully!");
      history.push(admin_router_consultation_staff);
    } else {
      await addStaffRequest.runAsync(data);
      message.success("Staff created successfully!");
      history.push(admin_router_consultation_staff);
    }
  };

  const onValuesChange = (_: any, all: any) => {
    if (all.calendlyId !== calendlyId) {
      setCalendlyId(all.calendlyId);
    }
  };

  return (
    <ConfigProvider getPopupContainer={(node) => node.parentElement!}>
      <div className="tailwind admin-consultation-staff-edit-container admin-common-container overflow-hidden">
        <Breadcrumb separator=">">
          <Breadcrumb.Item
            href="#"
            onClick={() => history.push(admin_router_consultation_staff)}
          >
            Consultation
          </Breadcrumb.Item>
          <Breadcrumb.Item>Staff</Breadcrumb.Item>
        </Breadcrumb>
        <div className="flex title !mt-10">
          <h1>{id !== undefined ? "Edit" : "Add"} a Staff</h1>
        </div>
        <p>
          Add the information for a staff that will appear in the consultation
          booking.
        </p>
        <Form layout="vertical" form={form} onValuesChange={onValuesChange}>
          <div className="staff-form">
            <h2 className="">Staff information</h2>
            <Form.Item
              label="Staff Displayed name"
              name="name"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  message: "Please enter Staff Displayed Name",
                },
              ]}
            >
              <Input maxLength={100} placeholder="Enter staff displayed name" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <Input.TextArea
                maxLength={1000}
                rows={5}
                placeholder="Enter staff description (1000 characters). Please note that the platform will display the first 150 characters, the rest will be hidden under read more. "
                style={{ minHeight: 140 }}
              />
            </Form.Item>
            <Form.Item
              label="Topics Covered"
              name="topics"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  type: "array",
                  message: "Select topics that the staff covers",
                },
              ]}
            >
              <Select
                bordered={false}
                mode="multiple"
                placeholder="Select topics this staff covers"
                options={topicsRequest.data?.list?.map((item: any) => ({
                  value: item.title,
                }))}
              />
            </Form.Item>
            <Divider />
            <h2>Calendar</h2>
            <Form.Item
              label="Calendly ID"
              name="calendlyId"
              rules={[
                {
                  required: true,
                  message: "Please input the calendly ID",
                },
              ]}
            >
              <Select
                showSearch
                bordered={false}
                placeholder="Select topics this staff covers"
                options={calendlyUsers.map((item: any) => ({
                  value: item.user.uri,
                  label: `${item.user.name} - ${item.user.email}`,
                  data: item,
                }))}
                onChange={(_, option: any) => {
                  const email = option?.data?.user?.email;
                  if (email) {
                    form.setFields([
                      {
                        name: "email",
                        value: email,
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
            <Divider />
            <h2>Service Rates</h2>
            <Form.List
              name="serviceRates"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("Please add at least one service rate")
                      );
                    }
                  },
                },
              ]}
              initialValue={[{}]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <div className="service-rate-card" key={index}>
                      <div className="service-rate-header btn-service">
                        <h3>Service {index + 1}</h3>
                        <div
                          className="flex"
                          style={{ marginLeft: "auto", marginBottom: 16 }}
                        >
                          <button
                            type="button"
                            className="btn-service bg-white border-none hover:bg-black/5 rounded p-2"
                            onClick={() => remove(field.name)}
                          >
                            <span className="mr-3">Delete</span>
                            <div>
                              <DeleteOutlined />
                            </div>
                          </button>
                          <div
                            className="btn-service"
                            style={{ marginLeft: 27 }}
                          >
                            <div className="mr-3">Hide</div>
                            <Form.Item
                              name={[field.name, "hide"]}
                              noStyle
                              label="Hide"
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                      <Form.Item
                        label="Event URI"
                        name={[field.name, "uri"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select calendly event",
                            pattern:
                              /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          maxLength={1000}
                          loading={eventTypesRequest.loading}
                          placeholder="Select calendly event"
                          options={eventTypes.map((item: any) => ({
                            value: item.uri,
                            label: `${item.name} - ${formatDuration(
                              item.duration
                            )}`,
                            data: item,
                          }))}
                          onChange={(_, option: any) => {
                            form.setFields([
                              {
                                name: ["serviceRates", field.name, "duration"],
                                value: option?.data?.duration,
                              },
                              {
                                name: [
                                  "serviceRates",
                                  field.name,
                                  "calendlyEventLink",
                                ],
                                value: option?.data?.scheduling_url,
                              },
                            ]);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Event Link"
                        name={[field.name, "calendlyEventLink"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input calendly event link",
                            pattern:
                              /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                          },
                        ]}
                      >
                        <Input
                          maxLength={1000}
                          placeholder="Input calendly event uri"
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        label="Duration Type"
                        name={[field.name, "duration"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a duration type",
                          },
                        ]}
                      >
                        <Select
                          bordered={false}
                          placeholder="Select duration type"
                          options={
                            eventTypes?.length
                              ? eventTypes.map((item: any) => ({
                                  label: formatDuration(item.duration),
                                  value: item.duration,
                                }))
                              : [
                                  { label: "30 minutes", value: 30 },
                                  { label: "1 hour", value: 60 },
                                ]
                          }
                          disabled
                        />
                      </Form.Item>
                      <h4>Pricing details</h4>
                      <Form.Item
                        label="price model"
                        name={[field.name, "priceModel"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a price model",
                          },
                        ]}
                        initialValue="standard"
                      >
                        <Select
                          bordered={false}
                          placeholder="Select a price model"
                          onChange={() => {
                            form.resetFields([
                              ["serviceRates", field.name, "rate"],
                            ]);
                          }}
                          options={[
                            { label: "Standard pricing", value: "standard" },
                            {
                              label: "User role tier pricing",
                              value: "role_tier",
                            },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                          const priceModel = getFieldValue([
                            "serviceRates",
                            field.name,
                            "priceModel",
                          ]);
                          if (priceModel === "standard") {
                            return (
                              <Form.Item
                                label="Rate"
                                name={[field.name, "rate"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: false,
                                    message: "Please input service rate",
                                  },
                                ]}
                              >
                                <AmountInput placeholder="0.00" />
                              </Form.Item>
                            );
                          } else if (priceModel === "role_tier") {
                            return ["sponsored_artist", "free_artist"].map(
                              (key, index) => (
                                <div key={key} className={key}>
                                  <h5 className="flex">
                                    User Role {index + 1}
                                  </h5>
                                  <div className="flex">
                                    <Form.Item
                                      label="role"
                                      name={[field.name, "rate", index, "role"]}
                                      initialValue={key}
                                    >
                                      <Select
                                        bordered={false}
                                        disabled
                                        placeholder="Select a user role"
                                        value={key}
                                        options={[
                                          {
                                            label: "Sponsored artist",
                                            value: "sponsored_artist",
                                          },
                                          {
                                            label: "Free artist",
                                            value: "free_artist",
                                          },
                                        ]}
                                      ></Select>
                                    </Form.Item>
                                    <Form.Item
                                      label="Rate"
                                      name={[field.name, "rate", index, "rate"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing price",
                                        },
                                      ]}
                                    >
                                      <AmountInput placeholder="0.00" />
                                    </Form.Item>
                                  </div>
                                </div>
                              )
                            );
                          }
                        }}
                      </Form.Item>
                      <Form.Item
                        label="Pricing description"
                        name={[field.name, "description"]}
                      >
                        <Input
                          maxLength={2000}
                          placeholder="Enter pricing description"
                        />
                      </Form.Item>
                    </div>
                  ))}
                  <a className="btn-add-service" onClick={() => add()}>
                    Add New Service
                  </a>
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
            <Divider />
            <h2>Contact information</h2>
            <Form.Item
              label="Email address"
              name="email"
              className="ant-form-item-required"
              rules={[
                {
                  required: true,
                  type: "email",
                  whitespace: true,
                  message: "email invalid",
                },
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
            <Form.Item
              label="Phone number"
              name="phone"
              className="ant-form-item-required"
              normalize={(e) => {
                return e
                  .replace(/[^\d]*/g, "")
                  .replace(/(\d{3})(?=\d{2})/g, "$1-");
              }}
              rules={[
                {
                  required: true,
                  message: "Enter phone number",
                },
              ]}
            >
              <Input maxLength={12} placeholder="Enter phone number" />
            </Form.Item>
            <Divider />
            <h2>Media</h2>
            <Form.Item
              className="no-style-item"
              label={<h3>Featured photo</h3>}
              name="photo"
              rules={[
                {
                  required: true,
                  message: "Please upload a photo",
                },
              ]}
            >
              <UploadCrop
                crop={false}
                accept=".png,.jpeg,.jpg,.gif"
                listType="picture"
                className="upload-item-wrap"
                showUploadList
                maxCount={15}
              >
                <div className="children-upload">
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support a single file upload.
                  </p>
                </div>
              </UploadCrop>
            </Form.Item>
          </div>
          <p className="flex end btn">
            <Button
              loading={addStaffRequest.loading || updateStaffRequest.loading}
              onClick={() => handleSubmit(true)}
            >
              Save as draft
            </Button>
            <Button
              loading={addStaffRequest.loading || updateStaffRequest.loading}
              type="primary"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </p>
        </Form>
      </div>
    </ConfigProvider>
  );
};
