import { Input, InputProps, Select, SelectProps } from "antd"
import DatePicker from '@/components/DatePicker'
import TextArea, { TextAreaProps } from "antd/lib/input/TextArea"
import styled from "styled-components"
import { Dayjs } from 'dayjs'
import { PickerDateProps, RangePickerDateProps } from "antd/lib/date-picker/generatePicker"
interface LabelInputProp extends InputProps {
    label: string
    labelClassName?: string
}
interface LabelInputAreaProp extends TextAreaProps {
    label: string
    labelClassName?: string
}
interface LabelSelectProp extends SelectProps<string> {
    label: string
    labelClassName?: string
}
interface LabelDatePickerProp extends PickerDateProps<Dayjs> {
    label: string
    labelClassName?: string
}
interface LabelDateRangePickerProp extends RangePickerDateProps<Dayjs> {
    label: string
    labelClassName?: string
}
const Container = styled.div`
    position: relative;
    background: #ffffff;
    border: 1px solid #DDDFE4;
    padding: 4px 16px 0;
    height: 56px;
    .ipt-lbl {
        color: #3B5266;
        font-size: 10px;
        text-transform: uppercase;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ant-input,.ant-picker,.ant-select{
        width: 100%;
        height: 26px;
        position: relative;
        top: -2px;
        .ant-select-selector{
            height: 100%;
            border: none !important;
            box-shadow: none !important;
        }
        border: none;
    }
`
export const LabelInput = (props: LabelInputProp) => {
    const id = Date.now() + Math.random().toFixed(6)
    const { className, ...others } = props
    return <Container className={className}>
        <label htmlFor="id" className={`ipt-lbl ${props.labelClassName || ''}`}>{props.label}</label>
        <Input id={id} {...others} />
    </Container>
}

export const LabelInputArea = (props: LabelInputAreaProp) => {
    const id = Date.now() + Math.random().toFixed(6)
    const { className, ...others } = props
    return <Container className={className}>
        <label htmlFor="id" className={`ipt-lbl ${props.labelClassName || ''}`}>{props.label}</label>
        <TextArea id={id} {...others} />
    </Container>
}
export const LabelSelect = (props: LabelSelectProp) => {
    const id = Date.now() + Math.random().toFixed(6)
    const { className, ...others } = props
    return <Container className={className}>
        <label htmlFor="id" className={`ipt-lbl ${props.labelClassName || ''}`}>{props.label}</label>
        <Select id={id} {...others} />
    </Container>
}
export const LabelDatePicker = (props: LabelDatePickerProp) => {
    const id = Date.now() + Math.random().toFixed(6)
    const { className, ...others } = props
    return <Container className={className}>
        <label htmlFor="id" className={`ipt-lbl ${props.labelClassName || ''}`}>{props.label}</label>
        <DatePicker id={id} {...others} />
    </Container>
}
export const LabelDateRangePicker = (props: LabelDateRangePickerProp) => {
    const id = Date.now() + Math.random().toFixed(6)
    const { className, ...others } = props
    return <Container className={className}>
        <label htmlFor="id" className={`ipt-lbl ${props.labelClassName || ''}`}>{props.label}</label>
        <DatePicker.RangePicker id={id} {...others} />
    </Container>
}