import { changePassword } from "@/api/sign-api";
import { Button, Form, message } from "antd";
import Password from "antd/lib/input/Password";
import { KV } from "use-reaction";
import './admin_chg_pwd.less'

export const AdminChgPwd = () => {
  //password
  const [password] = Form.useForm();
  const onPassword = (values: KV) => {
    const { confirm, ...data } = values;
    changePassword(data).then(() => {
      message.success("update success");
      password.resetFields();
    });
  };

  return (
    <div className="admin-common-container admin-changepwd">
      <section className="section-changepwd flex between">
        <Form
          layout="vertical"
          size="large"
          name="profile"
          form={password}
          onFinish={onPassword}
        >
          <h2>Change password</h2>
          <Form.Item
            name="currentPassword"
            label="Current password"
            rules={[
              {
                required: true,
                message: "Enter current password",
              },
            ]}
          >
            <Password visibilityToggle={false} />
          </Form.Item>
          <Form.Item
            name="password"
            label="New password"
            rules={[
              {
                required: true,
                message: "Enter new password",
              },
            ]}
          >
            <Password
              visibilityToggle={false}
              placeholder="Enter new password"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="confirm New password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Password
              visibilityToggle={false}
              placeholder="Enter new password again"
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">update password</Button>
        </Form>
      </section>
    </div>
  );
};
