import { Button, Dropdown, Select, Menu } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"
import { ReactComponent as FilterMore } from '@/images/filter-more.svg'
// import TrendIcon from '@/images/icon_trend.svg'
import { Bar, Line, Pie, measureTextWidth } from "@ant-design/charts"
import { CSSProperties } from "styled-components"
import { SyncOutlined } from "@ant-design/icons"
import { LabelDateRangePicker, LabelSelect } from "@/components/LabelInput/LabelInput"
import { donorReport } from "@/adminApp/apis/donor-api"
import './donor_report.less'
import _ from "lodash"
import queryString from "query-string";
import { admin_router_finance_contribution, admin_router_finance_order } from "@/adminApp/routers"

interface ChartItem {
    type: string
    value: number
}
interface BasicChartProp {
    title: string
    data: ChartItem[]
    colors: string[]
    filterMenu?: React.ReactElement
    filterIcon?: React.ReactElement
}
interface StatChatItem extends ChartItem {
    date: string // date-string
}
type TypeT = 'last_year' | 'custom'

type Statistics = {
    overview: StatChatItem[]
    summary: ChartItem[]
    trend: number
    sum_fund: ChartItem[]
    sum_events: ChartItem[]

    compare_fund: ChartItem[]
    compare_addon: ChartItem[]
    compare_ticket: ChartItem[]
    compare_benefit: ChartItem[]
}
const initDatas = [{ type: 'current', value: 0 }, { type: 'previous', value: 0 }]
const initialStats: Statistics = {
    summary: initDatas,
    trend: 0,
    sum_fund: initDatas,
    sum_events: initDatas,
    overview: [],
    compare_fund: initDatas,
    compare_addon: initDatas,
    compare_ticket: initDatas,
    compare_benefit: initDatas,
}

export const DonorReport = () => {
    const { state: { from, to } = {} as any } = useLocation()
    const history = useHistory()
    const now = dayjs()
    const yearAgo = dayjs().add(-1, 'year')
    const [fromDate, setFromDate] = useState(from?.length ? from.map((_: any) => dayjs(_)) : [now.startOf('year'), now.endOf('year')])
    const [toDate, setToDate] = useState(to?.length ? to.map((_: any) => dayjs(_)) : [yearAgo.startOf('year'), yearAgo.endOf('year')])
    const [type, setType] = useState<TypeT>(to?.length && dayjs(to[1]).diff(dayjs(to[0]), 'year') < 0.98 ? 'custom' : 'last_year')
    const [stats, setStats] = useState<Statistics>(initialStats)

    const getData = async () => {
        const [currentStartDate, currentEndDate] = fromDate.map((_: Dayjs) => _.format('YYYY-MM-DD'))
        const [previousStartDate, previousEndDate] = toDate.map((_: Dayjs) => _.format('YYYY-MM-DD'))
        const res = await donorReport({
            currentStartDate,
            currentEndDate,
            previousStartDate,
            previousEndDate
        })

        const summary = [
            {
                type: 'Annual Fund',
                value: res.comparison.annualFund.find((_: any) => /current/i.test(_.type))?.value || 0
            },
            {
                type: 'Special Event',
                value: res.comparison.specialEvent.find((_: any) => /current/i.test(_.type))?.value || 0
            }
        ]
        const summary_prev = [
            {
                type: 'Annual Fund',
                value: res.comparison.annualFund.find((_: any) => /previous/i.test(_.type))?.value || 0
            },
            {
                type: 'Special Event',
                value: res.comparison.specialEvent.find((_: any) => /previous/i.test(_.type))?.value || 0
            }
        ]

        const sum_fund = [
            {
                type: 'Add on',
                value: res.comparison.addOn.find((_: any) => /current/i.test(_.type))?.value || 0
            },
            {
                type: 'Field specific',
                value: res.comparison.fieldSpecific.find((_: any) => /current/i.test(_.type))?.value || 0
            },
        ]
        const sum_events = [
            {
                type: 'Benefit Donation',
                value: res.comparison.benefitDonation.find((_: any) => /current/i.test(_.type))?.value || 0
            },
            {
                type: 'Benefit ticket',
                value: res.comparison.benefitTicket.find((_: any) => /current/i.test(_.type))?.value || 0
            },
        ]

        setStats({
            overview: res.overview,
            summary,
            sum_fund,
            sum_events,
            compare_fund: res.comparison.annualFund,
            compare_addon: res.comparison.addOn,
            compare_benefit: res.comparison.benefitDonation,
            compare_ticket: res.comparison.benefitTicket,
            trend: summary.reduce((p, cur) => p + cur.value, 0) - summary_prev.reduce((p, cur) => p + cur.value, 0)
        })
    }
    const reset = () => {
        const now = dayjs()
        setFromDate([now.startOf('year'), now.endOf('year')])
        getData()
    }

    useEffect(() => {
        if (type === 'last_year') {
            const now = dayjs()
            const yearAgo = dayjs().add(-1, 'year')
            setFromDate([now.startOf('year'), now.endOf('year')])
            setToDate([yearAgo.startOf('year'), yearAgo.endOf('year')])
        }
    }, [type])

    useEffect(() => {
        getData()
    }, [from])

    const dateFormat = 'YYYY-MM-DD'
    const viewCurrentAnnualFund = () => {
        window.open(`${admin_router_finance_contribution}?${queryString.stringify({
            date: [fromDate[0].format(dateFormat), (dayjs().endOf('year').isSame(fromDate[1]) ? dayjs() : fromDate[1]).format(dateFormat)],
            productType: 'donation_to_the_field'
        })}`, '_blank')
    }
    const viewPreviousAnnualFund = () => {
        window.open(`${admin_router_finance_contribution}?${queryString.stringify({
            date: [toDate[0].format(dateFormat), (type === 'last_year' ? dayjs().add(-1, 'year') : toDate[1]).format(dateFormat)],
            productType: 'donation_to_the_field'
        })}`, '_blank')
    }
    const viewCurrentAddOn = () => {
        window.open(`${admin_router_finance_contribution}?${queryString.stringify({
            date: [fromDate[0].format(dateFormat), (dayjs().endOf('year').isSame(fromDate[1]) ? dayjs() : fromDate[1]).format(dateFormat)],
            productType: 'donation_to_the_field',
            tag: 'add_on' // tag is from BE's order_tag db
        })}`, '_blank')
    }
    const viewPreviousAddOn = () => {
        window.open(`${admin_router_finance_contribution}?${queryString.stringify({
            date: [toDate[0].format(dateFormat), (type === 'last_year' ? dayjs().add(-1, 'year') : toDate[1]).format(dateFormat)],
            productType: 'donation_to_the_field',
            tag: 'add_on'
        })}`, '_blank')
    }
    const viewCurrentBnfTicket = () => {
        window.open(`${admin_router_finance_order}?${queryString.stringify({
            date: [fromDate[0].format(dateFormat), (dayjs().endOf('year').isSame(fromDate[1]) ? dayjs() : fromDate[1]).format(dateFormat)],
            tag: 'benefit_ticket' // tag is from BE's order_tag db
        })}`, '_blank')
    }
    const viewPreviousBnfTicket = () => {
        window.open(`${admin_router_finance_order}?${queryString.stringify({
            date: [toDate[0].format(dateFormat), (type === 'last_year' ? dayjs().add(-1, 'year') : toDate[1]).format(dateFormat)],
            tag: 'benefit_ticket'
        })}`, '_blank')
    }
    const viewCurrentBnfDonation = () => {
        window.open(`${admin_router_finance_order}?${queryString.stringify({
            date: [fromDate[0].format(dateFormat), (dayjs().endOf('year').isSame(fromDate[1]) ? dayjs() : fromDate[1]).format(dateFormat)],
            tag: 'benefit_donation' // tag is from BE's order_tag db
        })}`, '_blank')
    }
    const viewPreviousBnfDonation = () => {
        window.open(`${admin_router_finance_order}?${queryString.stringify({
            date: [toDate[0].format(dateFormat), (type === 'last_year' ? dayjs().add(-1, 'year') : toDate[1]).format(dateFormat)],
            tag: 'benefit_donation'
        })}`, '_blank')
    }

    return <div className="admin-common-container donor-report-con">
        <h1>Reports</h1>
        <div className="flex search-container">
            <LabelDateRangePicker label="current period" placeholder={['start date', 'end date']} value={fromDate} onChange={e => setFromDate(e)} />
            <LabelSelect className="compare" allowClear label="compare to" value={type} onChange={e => setType(e as TypeT)}>
                <Select.Option value='last_year'>Last year</Select.Option>
                <Select.Option value='custom'>Custom</Select.Option>
            </LabelSelect>
            <LabelDateRangePicker label="previous period" placeholder={['start date', 'end date']} value={toDate} onChange={e => setToDate(e)} />
            <Button type="primary" onClick={getData}>apply</Button>
            <Button onClick={reset}>reset</Button>
        </div>
        <div className="flex section">
            <CircleChart
                title="Contributed Revenue"
                trend={stats.trend}
                data={stats.summary}
                colors={['#05A5FF', '#FF7456']}
            />
            <i />
            <div className="part flex column between">
                <HorizonLineChart
                    title="Annual Fund"
                    data={stats.sum_fund}
                    colors={['#95D4F8', '#05A5FF']}
                />
                <HorizonLineChart
                    title="Special events"
                    data={stats.sum_events}
                    colors={['#E0DB68', '#FFA24B', '#FF7456']}
                />
            </div>
        </div>
        <div className="section">
            <LineChart
                title="Overview"
                data={stats.overview}
                yearPeriod={type === 'last_year'}
                onRefresh={getData}
                colors={['#95D4F8', '#05A5FF', '#FF7456', '#FFA24B', '#E0DB68']}
            />
        </div>
        <div className="flex wrap section section-bottom">
            <HorizonLineChart
                title="Annual Fund"
                filterMenu={
                    <Menu>
                        <Menu.Item key='annual-fund-m-current' onClick={viewCurrentAnnualFund}>
                            View Current Period
                        </Menu.Item>
                        <Menu.Item key='annual-fund-m-previous' onClick={viewPreviousAnnualFund}>
                            View Previous Period
                        </Menu.Item>
                    </Menu>
                }
                data={stats.compare_fund}
                colors={['#F1F1F6', '#05A5FF']}
            />
            <i />
            <HorizonLineChart
                title="Add ons"
                filterMenu={
                    <Menu>
                        <Menu.Item key='addon-m-current' onClick={viewCurrentAddOn}>
                            View Current Period
                        </Menu.Item>
                        <Menu.Item key='addon-m-previous' onClick={viewPreviousAddOn}>
                            View Previous Period
                        </Menu.Item>
                    </Menu>
                }
                data={stats.compare_addon}
                colors={['#F1F1F6', '#95D4F8']}
            />
            <HorizonLineChart
                title="Benefit tickets"
                filterMenu={
                    <Menu>
                        <Menu.Item key='bnf-t-m-current' onClick={viewCurrentBnfTicket}>
                            View Current Period
                        </Menu.Item>
                        <Menu.Item key='bnf-t-m-previous' onClick={viewPreviousBnfTicket}>
                            View Previous Period
                        </Menu.Item>
                    </Menu>
                }
                data={stats.compare_ticket}
                colors={['#F1F1F6', '#FF7456']}
            />
            <i />
            <HorizonLineChart
                title="Benefit donations"
                filterMenu={
                    <Menu>
                        <Menu.Item key='bnf-d-m-current' onClick={viewCurrentBnfDonation}>
                            View Current Period
                        </Menu.Item>
                        <Menu.Item key='bnf-d-m-previous' onClick={viewPreviousBnfDonation}>
                            View Previous Period
                        </Menu.Item>
                    </Menu>
                }
                data={stats.compare_benefit}
                colors={['#F1F1F6', '#FFA24B']}
            />
        </div>
    </div>
}


function formatter(v: any) {
    const _v = v?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$ ".concat(_v);
}
function renderPieStatistic(containerWidth: number, text: string, style: CSSProperties, color = '#000') {
    var _measureTextWidth = measureTextWidth(text, style),
        textWidth = _measureTextWidth.width || 0,
        textHeight = _measureTextWidth.height;
    var R = containerWidth / 2;
    var scale = 1;
    if (containerWidth < textWidth) {
        scale = Math.min(
            Math.sqrt(
                Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
            ),
            1,
        );
    }
    var textStyleStr = 'width:'.concat(containerWidth + 'px;');
    return '<div style="'
        .concat('color:', color, ';')
        .concat(textStyleStr, ';font-size:')
        .concat(scale + 'em;line-height:')
        .concat(scale < 1 ? '1' : 'inherit', ';">')
        .concat(text, '</div>');
}

const HorizonLineChart = ({
    title,
    data,
    colors,
    filterIcon,
    filterMenu
}: BasicChartProp) => {
    const padding = [20, 80, 20, 108]
    const itemHt = 32
    const height = padding[0] * 2 + itemHt * data.length
    data.forEach(d => {
        if (d.type === 'current' || d.type === 'previous') {
            d.type = _.capitalize(d.type) + ' Period'
        }
    })
    const reversed = [...data].reverse()
    const labelMaxCharacters = reversed.reduce((p, cur) => Math.max(p, cur.type.length), 0)
    return <div className="report-chart">
        <div className="flex top">
            <h3>{title}</h3>
            {filterMenu && <Dropdown
                trigger={['hover']}
                overlay={filterMenu}>
                <span className="ant-dropdown-link">
                    {filterIcon || <FilterMore />}
                </span>
            </Dropdown>}
        </div>
        <Bar
            xField="value"
            yField="type"
            seriesField="type"
            legend={false}
            data={data}
            color={colors}
            xAxis={{
                line: null,
                tickCount: 0
            }}
            yAxis={{
                position: 'top',
                line: null,
                tickLine: {
                    style: { lineWidth: 0 }
                },
                label: {
                    formatter: (t, item, index) => formatter(reversed[index].value),
                    style: { fontWeight: 'bold' }
                }
            }}
            label={{
                position: 'left',
                offsetX: -118,
                formatter: (t, item, index) => {
                    let lb = reversed[index].type
                    return lb + _.fill([], ' ', 0, labelMaxCharacters - lb.length).join('')
                }
            }}
            height={height}
            padding={padding}

        />
    </div>
}

interface CircleChartProp extends BasicChartProp {
    trend: number
}

const CircleChart = ({
    title,
    data,
    colors,
    filterIcon,
    filterMenu,
    trend
}: CircleChartProp) => {

    return <div className="report-chart circle-chart">
        <div className="flex top">
            <h3>{title}</h3>
            {filterMenu && <Dropdown
                trigger={['hover']}
                overlay={filterMenu}>
                <span className="ant-dropdown-link">
                    {filterIcon || <FilterMore />}
                </span>
            </Dropdown>}
        </div>
        <div className="flex center pie-con">
            <Pie
                angleField="value"
                colorField="type"
                width={176}
                height={176}
                legend={false}
                data={data}
                radius={1}
                innerRadius={0.76}
                color={colors}
                autoFit={false}
                label={false}
                meta={{ value: { formatter } }}
                statistic={{
                    title: {
                        offsetY: -6,
                        offsetX: -8,
                        style: { fontSize: '22px' },
                        customHtml: function (container, view, datum?: any) {
                            let _container$getBoundin = container.getBoundingClientRect(),
                                width = _container$getBoundin.width,
                                height = _container$getBoundin.height;
                            let d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                            let text = formatter(data.reduce((p, cur) => p + cur.value, 0))
                            return renderPieStatistic(d, text, { fontSize: 28 });
                        }
                    },
                    content: {
                        offsetY: 6,
                        style: { fontSize: '14px' },
                        customHtml: function (container, view, datnum?: any) {
                            let _container$getBoundin = container.getBoundingClientRect(),
                                width = _container$getBoundin.width
                            // const icon = `<img src="${TrendIcon}" alt="trend" />`
                            return renderPieStatistic(width, formatter(trend), { fontSize: 16 }, trend > 0? '#6ECB63' : '#ee0000')
                        }
                    }
                }}
            />
        </div>
        <div className="fake-legend">
            {data.map((d, idx) =>
                <div key={`circ-${idx}`} className="flex legend-item">
                    <span className="mark" style={{ background: colors[idx] }}></span>
                    <span className="name">{d.type}</span>
                    <span className="value">{formatter(d.value)}</span>
                </div>
            )}
        </div>
    </div>
}

interface LineChartProp extends BasicChartProp {
    onRefresh: () => void,
    yearPeriod: boolean
}

const LineChart = ({
    title,
    data,
    colors,
    yearPeriod,
    onRefresh
}: LineChartProp) => {
    return <div className="report-chart">
        <div className="flex top">
            <h3>{title}</h3>
            <div className="timer"></div>
            <Button onClick={onRefresh}>
                <SyncOutlined />
            </Button>
        </div>
        <Line
            xField="date"
            yField="value"
            seriesField="type"
            data={data}
            color={colors}
            height={312}
            xAxis={{
                label: {
                    formatter: function (text: string) {
                        return dayjs(text).format(yearPeriod ? 'MMM' : 'MMM D')
                    }
                }
            }}
            legend={{
                position: 'bottom'
            }}
        />
    </div>
}

