import { useEffect, useState } from 'react';
import { Action, Model } from 'use-reaction'
const screen_wd_small = 1024;
const screen_ht_small = 788;
export const model_admin_size = {
    width: screen_wd_small,
    height: screen_ht_small,
    bigWd: true,
    bigHt: true,
}

export const up_size_action: Action<typeof model_admin_size> = ({ payload: { width, height } }) => {
    return {
        width,
        height,
        bigWd: width > screen_wd_small,
        bigHt: height > screen_ht_small
    }
}

export const useWindowSize = () => {
    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
    const handleResize = () => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    return [height,width]
}