//created by Peggy on 2021/3/11
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import CardItem from "@/pages/dashboard/income/CardItem";
import { Progress } from "antd";
import { ReactComponent as NoData } from "@/images/icon_menu_donation.svg";
const dayjs = require("dayjs");

const relativeTime = require("dayjs/plugin/relativeTime");
const duration = require("dayjs/plugin/duration");
dayjs.extend(relativeTime);
dayjs.extend(duration);

const CampaignCardContainer = styled.div`
  .card-item-content {
    padding: 32px;
    h2 {
      margin-bottom: 20px !important;
      i {
        font-family: Roboto;
        font-weight: normal;
        font-size: 14px;
        color: #a0a0a0;
      }
      &:nth-of-type(2) {
        margin-bottom: 0 !important;
      }
    }
    .ant-progress-inner {
      margin-bottom: 16px;
      border-radius: 0px;
      .ant-progress-bg {
        height: 20px !important;
        background-color: #00ff6d;
      }
    }
  }
`;

interface Props {
  data?: any;
}

export const formatDuration = (timestamp: string) => {
  const date = dayjs(timestamp);
  const now = dayjs();
  const duration = dayjs.duration(date.diff(now));
  const months = duration.months();

  if (months >= 1) {
    return dayjs(timestamp).format("MMMM DD,YYYY");
    // return `${months} month${months >= 2 ? "s" : ""}`;
  } else {
    return duration
      .format("D HH:mm-")
      .replace(/\s/, " Days ")
      .replace(":", " Hours ")
      .replace("-", " Minutes ");
  }
};

const CampaignCard = ({ data }: Props) => {
  return (
    <CampaignCardContainer className="campaign-card-container flex-1">
      <CardItem title="Crowdfunding campaign" url="/dashboard/donation/list">
        {(data && (
          <div className="card-item-content">
            <h2 translate="no">{data?.name} </h2>
            <h2>
              {Number(data?.currentAmount || 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}{" "}
              <i>
                Raised of{" "}
                {Number(data?.goalAmount || 0).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}{" "}
                goals
              </i>
            </h2>
            <Progress
              showInfo={false}
              strokeLinecap="square"
              percent={
                data?.goalAmount
                  ? Number(
                      (Number(data?.currentAmount) / Number(data?.goalAmount)) *
                        100
                    )
                  : 0
              }
            />
            <p>
              Donation ends in <b>{formatDuration(data?.endDate)}</b>
            </p>
          </div>
        )) || (
          <div className="no-data">
            <NoData />
            <h4>No crowdfunding campaign</h4>
            <p>
              Create a crowdfunding campaign to raise funds that will help you
              reach your goals{" "}
            </p>
            <Link to="/dashboard/donation/list">Get started</Link>
          </div>
        )}
      </CardItem>
    </CampaignCardContainer>
  );
};

export default CampaignCard;
