import { useEffect, useState } from "react";
import { Dropdown, Input, Menu, message, Table, Button, Modal } from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { useAntdTable, useRequest } from "ahooks";
import {
  deleteConsultationTopic,
  getConsultationTopics,
  updateConsultationTopicIndex,
} from "@/adminApp/apis/consultation-api";
import { ColumnsType } from "antd/lib/table";
import EditTopicModal from "./EditTopicModal";
import "./list.less";
import { useUrlState } from "@/utils/util";
import {
  arrayMove,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

export const ConsultationTopicList = () => {
  const deleteTopicRequest = useRequest(deleteConsultationTopic, {
    manual: true,
  });
  const updateIndexRequest = useRequest(updateConsultationTopicIndex, {
    manual: true,
  });
  const [{ action }, setState] = useUrlState({ action: undefined });
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [record, setRecord] = useState<object | undefined>();
  const { tableProps, refresh, mutate, loading } = useAntdTable(
    () => {
      return getConsultationTopics({ keyword }).then((data) => {
        data.list = data.list.map((item: any, index: number) => {
          item.index = index;
          return item;
        });
        return data;
      });
    },
    {
      refreshDeps: [keyword],
    }
  );

  const openEdit = (row: object) => {
    setRecord(row);
    setOpen(true);
  };

  const openAdd = () => {
    setRecord(undefined);
    setOpen(true);
  };

  const handleDelete = async (row: any) => {
    Modal.confirm({
      title: "Delete topic",
      content: "Are you sure you want to delete this topic?",
      onOk: async () => {
        const res = await deleteTopicRequest.runAsync(row.id);
        if (res) {
          message.success("Topic deleted successfully!");
          refresh();
        }
      },
    });
  };

  const column: ColumnsType<any> = [
    {
      title: "Sort",
      dataIndex: "order",
      className: "min-w-[60px]",
      render: () => <DragHandle />,
    },
    {
      title: "TOPIC",
      dataIndex: "title",
      className: "!pl-8 min-w-[200px]",
    },
    {
      title: "DESC",
      dataIndex: "description",
      className: "!max-w-[40vw]",
      ellipsis: true,
    },
    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <Menu className="admin-consultation-topic-menu">
              <Menu.Item key="edit" onClick={() => openEdit(row)}>
                Edit topic
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => handleDelete(row)}>
                Delete topic
              </Menu.Item>
            </Menu>
          )}
        >
          <span
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    if (action === "add") {
      openAdd();
      setState({ action: undefined });
    }
  }, [action, setState]);

  const onSortEnd = async ({ oldIndex, newIndex }: SortEnd) => {
    document.body.style.userSelect = "";
    const list = arrayMove(tableProps.dataSource, oldIndex, newIndex);
    mutate({ list });
    await updateIndexRequest.runAsync(list.map((item: any) => item.id));
    refresh?.();
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      onSortEnd={onSortEnd}
      helperClass="row-dragging"
      onSortStart={() => {
        document.body.style.userSelect = "none";
      }}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    const index = tableProps.dataSource.findIndex(
      (x: any) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className="tailwind admin-common-container admin-consultation-topic-list-container">
      <h1 className="flex">
        Topic List
        <Button type="primary" onClick={openAdd}>
          Add new Topic
        </Button>
      </h1>
      <Table
        className="table-container"
        title={() => (
          <div className="flex pl-4">
            <span>Topics</span>
            <Input.Search onSearch={setKeyword} placeholder="Search" />
          </div>
        )}
        rowKey="index"
        columns={column}
        sticky
        scroll={{
          scrollToFirstRowOnChange: true,
          x: "max-content",
          y: "calc(100vh - 300px)",
        }}
        pagination={false}
        dataSource={tableProps.dataSource}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        loading={updateIndexRequest.loading ?? loading}
      />
      <EditTopicModal
        open={open}
        setOpen={setOpen}
        record={record}
        refresh={refresh}
      />
    </div>
  );
};
