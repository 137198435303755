//created by Peggy on 2021/2/1
import React from "react";

import { Modal } from "antd";
import styled from "styled-components";
import Success from "@/images/icons/icon_campaign_step.svg";

const InfoContainer = styled.div``;

interface Props {
  width?: string | number;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  visible: boolean;
  okText?: string;
  onOk?: () => void;
  onClose?: () => void;
  className?: string;
  img?: any;
}

const Info = ({
  width = 470,
  children = "",
  visible = true,
  okText = "Done",
  onOk = () => {},
  onClose = () => {},
  footer = null,
  className = "",
  img = Success,
}: Props) => {
  return (
    <Modal
      title=""
      width={width}
      closable={false}
      maskClosable={false}
      visible={visible}
      footer={footer}
      onOk={onOk}
      onCancel={onClose}
      className={`sponsorship-modal ${className}`}
    >
      <InfoContainer className="info-container flex column">
        <img src={img} alt="success" />
        {children}
      </InfoContainer>
    </Modal>
  );
};

export default Info;
