import Password from "@/components/Password";
import { Input, Form, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { KV, useLoading, useModel } from "use-reaction";
import {
  model_admin_user,
  getUserInfoAction,
  AdminVerifyAction,
  AdminGetCodeAction,
} from "../models/model_admin_user";
import { admin_router_artists } from "../routers";
import qs from "query-string";
import "./admin_signin.less";

export const AdminSignIn2Fa = (props: KV) => {
  const loading = useLoading();
  const loadingM = useLoading(model_admin_user);
  const history = useHistory();
  const { to } = qs.parse(history.location.search);
  const { doAction } = useModel(model_admin_user);
  const onFinish = async (data: any) => {
    const res = await doAction(AdminVerifyAction, data, "global");
    if (res && res.token) {
      history.push((to as string) || admin_router_artists);
      doAction(getUserInfoAction);
    }
  };

  const resend = async () => {
    const res = await doAction(AdminGetCodeAction, null, 'model')
    if (res && res.verify_sent) {
      message.success('verify code sent!')
    }
  }
  return (
    <div className="admin-sign-container">
      <div className="admin-sign-in">
        <h1>Enter verify code</h1>
        <div className="sign-form">
          <Form layout="vertical" size="large" name="sign" onFinish={onFinish}>
            <Form.Item
              label="Verify code"
              name="code"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  message: "Enter veriry code",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item className="no-style">
              <Button block type="primary" htmlType="submit" loading={loading}>
                Verify
              </Button>
              <div className="tips">
                Tips: verify code was sent to your email. pls check or
                <Button type="text" onClick={resend} loading={loadingM}>resend</Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
