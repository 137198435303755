import { Select } from "antd";
import { useEffect, useState } from "react";

const DynamicSelect = (props:{
    onChange?: (e?: any) => void;
    fetchOptions: () => Promise<any[]>;
    value?:any 
    placeholder?:string 
    }) => {
    const {onChange,fetchOptions,value,placeholder} = props;
    const [options, setOptions] = useState<{label:string,value:any}[]>([]);

    useEffect(()=>{
        fetchOptions?.().then(e => {
            console.log(e)
            setOptions(e)
        })
    },[])

    return <Select
    bordered={false}
    placeholder={placeholder}
    onChange={(e: any) => {
        onChange?.(e);
    }}
    value={value}
    options={options}
  >
  </Select>
}

export default DynamicSelect