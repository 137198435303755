import {
  addConsultationTopic,
  updateConsultationTopic,
} from "@/adminApp/apis/consultation-api";
import { useRequest } from "ahooks";
import { Form, Input, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";

type Props = {
  record?: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  refresh: () => void;
};

const EditTopicModal = ({ open, setOpen, record, refresh }: Props) => {
  const [form] = useForm();
  const addTopicRequest = useRequest(addConsultationTopic, { manual: true });
  const updateTopicRequest = useRequest(updateConsultationTopic, {
    manual: true,
  });
  const onValuesChange = () => {};
  const onSubmit = async () => {
    await form.validateFields();
    try {
      let result;
      const formData = form.getFieldsValue();
      formData.title = formData.title.replaceAll(",", " ");
      if (record) {
        result = await updateTopicRequest.runAsync({
          ...record,
          ...formData,
        });
      } else {
        if (!formData.index) {
          formData.index = 0;
        }
        result = await addTopicRequest.runAsync(formData);
      }
      if (result.id !== undefined) {
        message.success("Topic saved successfully!");
        setOpen(false);
        refresh?.();
      } else {
        message.error(
          result.message ?? result?.msg ?? "Error while saving topic"
        );
      }
    } catch (error: any) {
      message.error(error?.message ?? error?.msg ?? error);
    }
  };
  useEffect(() => {
    if (open) {
      if (record) {
        form.setFieldsValue(record);
      } else {
        form.resetFields();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <Modal
      className="add-consultant-topic-modal"
      title={
        <span className="text-[24px] font-bold leading-[1.5]">
          {record ? "Edit Topic" : "Add a New Topic"}
        </span>
      }
      width={670}
      visible={open}
      okText="submit"
      onOk={onSubmit}
      onCancel={() => setOpen(false)}
      okButtonProps={{
        loading: addTopicRequest.loading || updateTopicRequest.loading,
      }}
      destroyOnClose
    >
      <Form layout="vertical" form={form} onValuesChange={onValuesChange}>
        <Form.Item
          label="Topic"
          name="title"
          rules={[
            {
              required: true,
              whitespace: false,
              message: "Please enter topic title",
            },
          ]}
        >
          <Input maxLength={100} placeholder="Add a new topic" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter description",
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            maxLength={1000}
            placeholder="Describe the topic"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTopicModal;
