import request from "@/libs/fetch";

export type StaffQueryParams = {
  topic?: string;
  status?: string;
} & BasicQueryParams;

export type ConsultationQueryParams = {
  startDate?: string;
  endDate?: string;
  staffIds?: string;
  userId?: number;
  duration?: string;
  topic?: string;
  status?: string;
  ids?: string;
} & BasicQueryParams;

export type BasicQueryParams = {
  current?: number;
  pageSize?: number;
  keyword?: string;
};

export const getConsultationTopics = (params: object) =>
  request("/consultation-topic", {
    type: "get",
    params,
    headers: { noLoading: true },
  });
export const addConsultationTopic = (data?: any) =>
  request("/consultation-topic", {
    type: "post",
    data,
    headers: { noLoading: true },
  });
export const deleteConsultationTopic = (id: number) =>
  request("/consultation-topic/" + id, {
    type: "delete",
    headers: { noLoading: true },
  });
export const updateConsultationTopicIndex = (ids: number[]) =>
  request("/consultation-topic/update-index", {
    type: "post",
    data: { ids },
    headers: { noLoading: true },
  });
export const updateConsultationTopic = (data?: any) =>
  request(`/consultation-topic/${data?.id}`, {
    type: "put",
    data,
    headers: { noLoading: true },
  });

export const getStaffs = (params: StaffQueryParams) =>
  request("/staff", { type: "get", params, headers: { noLoading: true } });
export const getStaff = (id: string) =>
  request(`/staff/${id}`, { type: "get", headers: { noLoading: true } });
export const addStaff = (data?: any) =>
  request("/staff", { type: "post", data, headers: { noLoading: true } });
export const deleteStaff = (id: number) =>
  request(`/staff/${id}`, { type: "delete", headers: { noLoading: true } });
export const updateStaff = (data: any) =>
  request(`/staff/${data?.id}`, {
    type: "put",
    data,
    headers: { noLoading: true },
  });

export const getCalendlyUsers = () =>
  request("/calendly/organization_memberships", {
    type: "get",
    headers: { noLoading: true },
  });
export const getCalendlyEventTypeByUser = (user: string) =>
  request("/calendly/event_types", {
    type: "get",
    params: { user },
    headers: { noLoading: true },
  });
export const getCalendlyEntity = (uri: string) =>
  request("/calendly/entity", {
    type: "get",
    params: { uri },
    headers: { noLoading: true },
  });
export const getBookingUrl = (eventUri: string) =>
  request("/calendly/create-scheduling-link", {
    type: "post",
    data: { eventUri },
    headers: { noLoading: true },
  });

export const getConsultations = (params: ConsultationQueryParams) =>
  request("/consultation", {
    type: "get",
    params,
    headers: { noLoading: true },
  });
export const exportConsultationsCSV = (params: ConsultationQueryParams) =>
  request("/consultation/export-csv", {
    type: "get",
    params,
    headers: { noLoading: true },
    dataType: "blob",
  });
export const getConsultation = (id: string) =>
  request(`/consultation/${id}`, { type: "get", headers: { noLoading: true } });
export const getConsultationBySalt = (id: string, salt: string) =>
  request(`/consultation/${id}/${salt}`, {
    type: "get",
    headers: { noLoading: true },
  });
export const addConsultation = (data?: any) =>
  request("/consultation", { type: "post", data });
export const updateConsultationBook = (data?: any) =>
  request("/consultation/book", { type: "post", data });
export const deleteConsultation = (id: number) =>
  request(`/consultation/${id}`, {
    type: "delete",
    headers: { noLoading: true },
  });
export const updateConsultation = (data: any) =>
  request(`/consultation/${data?.id}`, {
    type: "put",
    data,
    headers: { noLoading: true },
  });
export const updateConsultationStatus = (data: any) =>
  request(`/consultation/status/${data?.id}`, {
    type: "put",
    data,
    headers: { noLoading: true },
  });

export const updateConsultationStatusBySalt = (data: any, salt: string) =>
  request(`/consultation/status/${data?.id}/${salt}`, {
    type: "put",
    data,
    headers: { noLoading: true },
  });

export const confirmConsultationPayment = (data: {
  orderId: number;
  coupon?: string;
  paymentMethodId?: string;
}) =>
  request(`/consultation/payment/confirm`, {
    type: "post",
    data,
  });

export const updateConsultationPayment = (data: {
  orderId: number;
  coupon?: string;
}) =>
  request(`/consultation/payment/update`, {
    type: "post",
    data,
  });
