import { Typography } from "antd";

export const textRender = (text: string) => (
  <Typography.Text
    className="table-tooltip"
    ellipsis={{ tooltip: <div style={{ padding: 8 }}>{text}</div> }}
  >
    {text || "N/A"}
  </Typography.Text>
);
