import Request from "@/libs/fetch";
const ADMIN_ROLE = "administrator";
export const AdminLogin = async (data: { email: string; password: string }) => {
  return await Request("/user/basic/sign-in", {
    data: {
      ...data,
      role: ADMIN_ROLE,
    },
  });
};
export const AdminVerify = async (data: { email: string; code: string }) => {
  return await Request("/user/check-2fa", { data });
};
export const AdminGetCode = async (email: string) => {
  return await Request("/user/generate-2fa", { data: { email } });
};

export const AdminGetUserInfo = async () => {
  const res = await Request("/user/info", { type: "get" });
  if (res.roles.includes(ADMIN_ROLE)) {
    return res;
  }
  return {};
};

export const AdminGetOneUserInfo = async (id: number) =>
  await Request("/user/info/" + id, { type: "get" });

export const userList = async (params?: any) =>
  await Request("/user/search", { type: "get", params });

export const userAccess = async (data?: any) =>
  await Request("/user/access", { type: "post", data });

export const userAdd = async (data?: any) =>
  await Request("/user/add", { type: "post", data });

export const userEditEmail = async (data?: any) =>
  await Request("/user/edit-email", { type: "post", data });

export const userEditTag = async (data?: any) =>
  await Request("/user/tags", { type: "post", data });

export const userBulkTag = async (data?: any) =>
  await Request("/user/bulk-tags", { type: "post", data });

export const userNote = async (data?: any) =>
  await Request("/user/add-note", { type: "post", data });

export const getUserTag = async () =>
  await Request("/user-tag", { type: "get" });

export const userTag = async (data?: any) =>
  await Request("/user-tag", { type: "post", data });

export const userTagDelete = async (id: number) =>
  await Request("/user-tag/" + id, { type: "delete" });

export const userTagUpdate = async (id: number, name: string) =>
  await Request("/user-tag", { type: "put", data: { id, name } });

export const userSuspend = async (data?: any) =>
  await Request("/user/suspend", { type: "post", data });
export const userUnsuspend = async (data?: any) =>
  await Request("/user/unsuspend", { type: "post", data });

export const userDelete = async (data?: any) =>
  await Request("/user/delete", { type: "post", data });

export const addAdmin = async (data?: any) =>
  await Request("/user/admin/add", { type: "post", data });
export const editAdmin = async (data?: any) =>
  await Request("/user/admin/edit", { type: "put", data });
export const getAdmin = async (id?: number) =>
  await Request(`/user/admin/${id}`, { type: "get" });
export const sendAdminInvitation = async (data?: any) =>
  await Request("/user/admin/send-invitation", { type: "post", data });
export const adminSetPassword = async (data?: any) =>
  await Request("/user/admin-set-password", { type: "post", data });

export const getSponsorDetail = async (id: number) =>
  await Request("/sponsorship/detail/" + id, { type: "get" });
export const editSponsorInfo = async (id: number, data: any) =>
  await Request("/sponsorship/edit/" + id, { type: "put", data });
export const reviewSponsorship = async (
  id: number,
  status: string,
  allowSubsidized?: boolean
) =>
  await Request("/sponsorship/review/" + id, {
    type: "post",
    data: { status, allowSubsidized },
  });
