import { useEffect } from "react";
import { Menu } from "antd";
import IconTranslate from '../../images/icons/icon_translate.svg'
import ContainerTranslate from "./style";
import { DownOutlined } from "@ant-design/icons";
const {SubMenu} = Menu

declare global {
    interface Window {
        googleTranslateElementInit: any;
        google: any;
    }
}

export const GoogleTranslateTrigger = (e:any) => {
    const language = e.key.replace('language-','');
    if(language === 'en') {
        backOriginal();
        return;
    }
    const select:any = document.querySelector('.goog-te-combo');
    select.value = language;
    select.dispatchEvent(new Event('change'));
    GoogleTranslateInit();
}

const GoogleTranslateInit = () => {
    const style = '@media screen and (max-width: 1000px){ table tr:nth-child(3){display:none} }';
    var css = document.createElement("style"); 
    css.appendChild(document.createTextNode(style));
    var iframe:any = document.querySelector('iframe.skiptranslate');
    iframe?.contentDocument?.head?.appendChild(css);
}

export const backOriginal = () => {
    var iframe:any = document.querySelector('iframe.skiptranslate');
    const closeButton:any = iframe?.contentDocument?.querySelector('.VIpgJd-ZVi9od-TvD9Pc-hSRGPd')
    closeButton?.click()
}

export const GoogleTranslate = () => {

    return <ContainerTranslate>
        <Menu className="flex menu-item"
          mode="horizontal"
          onClick={GoogleTranslateTrigger}
          >
        <SubMenu key="lang" icon={<DownOutlined />} title={<><img src={IconTranslate} alt="" style={{marginRight:5}}/>Language</>}>
            <Menu.Item key="en">
                <a href="#English">English</a>
            </Menu.Item>
            <Menu.Item key="es">
                <a href="#Spanish">Spanish</a>
            </Menu.Item>
            <Menu.Item key="zh-CN">
                <a href="#Chinese">Chinese</a>
            </Menu.Item>
            <Menu.Item key="fr">
                <a href="#French">French</a>
            </Menu.Item>
            <Menu.Item key="ja">
                <a href="#Japanese">Japanese</a>
            </Menu.Item>
            <Menu.Item key="ar">
                <a href="#Arabic">Arabic</a>
            </Menu.Item>
          </SubMenu>
        </Menu>
    </ContainerTranslate>
}

export const GoogleTranslateMobile = ({showIcon}:{showIcon?:boolean}) => {
    return <SubMenu
    key="/language"
    icon={showIcon && <img src={IconTranslate} alt="" style={{marginRight:16}}/>}
    title="Language"
  >
    <Menu.Item key="language-es"><a href="#">Spanish</a></Menu.Item>
    <Menu.Item key="language-zh-CN"><a href="#">Chinese</a></Menu.Item>
    <Menu.Item key="language-fr"><a href="#">French</a></Menu.Item>
    <Menu.Item key="language-ja"><a href="#">Japanese</a></Menu.Item>
    <Menu.Item key="language-ar"><a href="#">Arabic</a></Menu.Item>
  </SubMenu>
}

export const TheField = () => {
    return  <NoTranslate> The Field </NoTranslate>
}

export const NoTranslate = ({children,style}:{children:any,style?:React.CSSProperties }) => {
    return <span translate="no" style={style}> {children} </span>
}