//created by Peggy on 2021/2/10
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Button, Dropdown, Menu, message, Tag } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { grantDetail } from "@/api/grant-api";
import HeaderLayout from "@/components/layouts/HeaderLayout";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";

import styled from "styled-components";
import { publishGrant } from "@/adminApp/apis/grant-api";

import "@/styles/home/preview.less";
import { addFavorite } from "@/api/favorite-api";
import { route_sign_in } from "@/types/enum";
import { localStorageGet } from "@/utils/storage";
import {
  admin_router_grant_edit,
  admin_router_grant_list,
} from "@/adminApp/routers";
import FooterLayout from "@/components/layouts/FooterLayout";
import icon_grant from "@/images/icons/icon_grant.svg";
import Modal from "@/components/Modal/Info";
import Caption from "@/components/Image/caption";

const localizedFormat = require("dayjs/plugin/localizedFormat");
const dayjs = require("dayjs");
dayjs.extend(localizedFormat);

const PreviewContainer = styled.div`
  .event-header {
    width: 100%;
  }
  .event-content {
  }
`;

const Preview = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const preview =
    new URLSearchParams(history.location.search).get("preview") === "true";
  const [info, setInfo] = useState<any>("");
  useEffect(() => {
    grantDetail(id).then((data) => {
      setInfo(data);
    });
  }, [id]);

  const backEdit = () => {
    history.push(admin_router_grant_edit.replace(":id", id));
  };
  const handleSave = () => {
    message.success("save success.");
    history.push(admin_router_grant_list);
  };
  const handlePublish = () => {
    publishGrant(id).then((res) => {
      res?.success && message.success("publish success.");
      history.push(admin_router_grant_list);
    });
  };
  const handleRequest = () => {
    const userInfo = localStorageGet("info");
    if (userInfo) {
      if (!userInfo.sponsorshipValid) {
        setFlag(true);
        return false;
      }
      history.push(`/dashboard/grant?request=${id}`);
    } else {
      history.push(route_sign_in + `?to=/dashboard/grant?request=${id}`);
    }
  };

  const [flag, setFlag] = useState(false);
  return (
    <PreviewContainer className="event-preview-container grant-preview-container common-container">
      {preview && (
        <div className="preview-btn flex">
          <span>Grant live site preview</span>
          <div className="btn-item">
            <Button onClick={backEdit}>Back to edit</Button>
            <Button onClick={handleSave}>Save as draft</Button>
            <Button type="primary" onClick={handlePublish}>
              Publish
            </Button>
          </div>
        </div>
      )}
      <div className="event-header">
        <HeaderLayout hideSign />
        <div className="header-content">
          <h4>grant</h4>
          {info.requireSponsorship && (
            <Tag color="blue">Fiscal Sponsorship</Tag>
          )}
          {console.log(dayjs(info.deadline).diff(dayjs(), "days") < 10)}
          {info.deadline &&
            dayjs(info.deadline).diff(dayjs(), "days") < 10 &&
            dayjs(info.deadline).diff(dayjs(), "days") > 0 && (
              <Tag color="red">Deadline Approaching</Tag>
            )}
          <h1>
            {info?.name}{" "}
            <Dropdown
              className="filter-item"
              trigger={["click"]}
              overlay={() => (
                <Menu>
                  <>
                    <Menu.Item onClick={async () => {
                      await addFavorite(id, "grant")
                      message.success('Saved to favorite successfully!')
                    }}>
                      Save as favorite
                    </Menu.Item>
                  </>
                </Menu>
              )}
            >
              <span className="ant-dropdown-link">
                <FilterMore />
              </span>
            </Dropdown>
          </h1>
          <div className="sub-title" onClick={()=>{
              window.open(info.link,'_blank')
          }}>{info.organization}</div>
          <div className="icon-block-wrap">
            <p className="flex between">
              <span>• Status</span>
              <span>{info?.status === 'live' ? (info?.deadline && dayjs(info.deadline).isAfter(dayjs()) ? 'Accepting Applications' : `${info.rolling} - Check with organization for details.`) : 'No Longer Accepting Applications'}</span>
            </p>
            <p className="flex between">
              <span>• Award</span>
              <span>{info?.award}</span>
            </p>
            <p className="flex between">
              <span>• Discipline</span>
              <span>{info?.discipline}</span>
            </p>
            <p className="flex between">
              <span>• Deadline</span>
              <span>
                {info.rolling ||
                  (info?.deadline
                    ? dayjs(info.deadline).format("MM/DD/YYYY")
                    : "N/A")}
              </span>
            </p>
          </div>
          <div className="flex start">
            <Button
              type="primary"
              hidden={!info.requireSponsorship}
              onClick={handleRequest}
            >
              Request grant review
            </Button>
            <div onClick={() => window.open(info.link)} style={{ textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold' }}>
            REVIEW GRANT GUIDELINES {info.requireSponsorship?"":"AND APPLY"}
            </div>
          </div>
        </div>
      </div>
      <div className="event-content">
        <h2>About</h2>
        <p
          dangerouslySetInnerHTML={{
            __html:
              info?.description && info.description.startsWith("<")
                ? info.description
                : `<p>${info?.description || ""}</p>`,
          }}
        ></p>
        {info?.description && info.description && info?.link && (
          <p
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              color: "#000",
            }}
            onClick={() =>
              window.open(
                info?.link?.indexOf("http") > -1
                  ? info.link
                  : "https://" + info.link,
                "_blank"
              )
            }
          >
            REVIEW GRANT GUIDELINES {info.requireSponsorship?"":"AND APPLY"}
          </p>
        )}
      </div>
      <div className="event-photo-list">
        {info?.photos?.map((item: any) => (
          <Caption src={item} key={item} />
        ))}
      </div>
      <Modal
        visible={flag}
        width={420}
        className="grant-info-modal"
        footer={[
          <Button key="back" onClick={() => setFlag(false)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            Apply now
          </Button>,
        ]}
        img={icon_grant}
      >
        <h2>Two more steps to apply for the grant </h2>
        <p>
          In order to apply for this grant, you will need to apply and register
          for Fiscal Sponsorship. Here's what you'll get:
        </p>
        <p className="flex start align-start">
          <CheckOutlined />
          <span>Eligibility for this grant</span>
        </p>
        <p className="flex start align-start">
          <CheckOutlined />
          <span>
            Free review and feedback on grant applications and fundraising
            materials
          </span>
        </p>
        <p className="flex start align-start">
          <CheckOutlined />
          <span>And much more</span>
        </p>
      </Modal>
      <FooterLayout />
    </PreviewContainer>
  );
};

export default Preview;
