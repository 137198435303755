//created by Peggy on 2021/2/10
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Button, Modal, message, Radio, Space } from "antd";
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  DollarCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { eventGet, eventPut, reportEvent } from "@/api/event-api";
import HeaderLayout from "@/components/layouts/HeaderLayout";

import "@/styles/home/preview.less";
import styled from "styled-components";
import { getEnv } from "@/libs/cfg";
import Caption from "@/components/Image/caption";
import { capitalize } from "lodash";
import dayjs from "dayjs";
const PreviewContainer = styled.div`
  .preview-btn {
    background-color: #dddfe4;
    height: 64px;
    width: 100%;
    padding: 8px 136px;
    position: sticky;
    top: 0;
    z-index: 1;
    .ant-btn {
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .home-wrap {
    span {
      display: none;
    }
  }
  .header-content {
    .caption-container {
      bottom: -94px;
    }
  }
`;

const Preview = () => {
  const { edit, id } = useParams() as any;
  const history = useHistory();
  const [info, setInfo] = useState<any>("");
  const [reportReason, setReportReason] = useState("spam");
  const [showReport, setShowReport] = useState(false);
  const handleSave = (type: string) => {
    let _data = {
      status: type,
    };
    eventPut(info.id, _data).then((data) => {
      history.push(`/program/field`);
    }).catch(() => {
      history.push(`/program/field/${id}?checkField=true`);
    });
  };
  useEffect(() => {
    eventGet(id).then((data) => {
      setInfo(data);
    });
  }, [id]);

  return (
    <PreviewContainer className="event-preview-container common-container">
      {edit === "preview" && info?.status === "draft" && (
        <div className="preview-btn flex">
          <span>Event live site preview</span>
          <div className="btn-item">
            <Button onClick={() => history.push(`/program/field/${id}`)}>
              Back to edit
            </Button>
            <Button onClick={() => handleSave("draft")}>Save as draft</Button>
            <Button type="primary" onClick={() => handleSave("live")}>
              Publish
            </Button>
          </div>
        </div>
      )}

      <div
        className="event-header"
        style={{
          backgroundImage: `url(${info?.banner || info?.thumbnail})`,
        }}
      >
        <HeaderLayout hideMenu hideSign/>
        <div className="header-content">
          <h4>Upcoming event</h4>
          <h1>{info?.name}</h1>
          <p>{info?.presenter}</p>
          <div className="icon-block-wrap">
            <p>
              <ClockCircleOutlined />{" "}
              <span>
                {info?.time?.startTime
                  ? dayjs(info?.time?.startTime).format("h:mm A")
                  : "N/A"}{" "}
                {info?.time?.endTime
                  ? `- ${dayjs(info?.time?.endTime).format("h:mm A")}`
                  : ""}{" "}
                {info?.time?.timezone ?? "N/A"}・
                {info?.time?.frequency === "recurring" &&
                  info?.time?.repeatEvery &&
                  info?.time?.repeatOn &&
                  `Recurring every ${info?.time?.repeatEvery} on ${info?.time?.repeatOn} ・`}
                {info?.time?.startDate
                  ? dayjs(info?.time?.startDate).format("MM/DD/YYYY")
                  : "N/A"}{" "}
                {info?.time?.endDate &&
                info?.time?.startDate &&
                !dayjs(info?.time?.startDate).isSame(
                  dayjs(info?.time?.endDate),
                  "date"
                )
                  ? `- ${dayjs(info?.time?.endDate).format("MM/DD/YYYY")}`
                  : ""}
              </span>
            </p>
            <p>
              <EnvironmentOutlined />
              <span>
                {info?.location?.name || info?.location?.address
                  ? `${info.location.name ? info.location.name + " - " : ""}${
                      [info.location.address, info.location.city]
                        .filter(Boolean)
                        .join(" ") + ","
                    } ${[info.location.state, info.location.zipCode]
                      .filter(Boolean)
                      .join(" ")}`
                  : info?.location?.url || "N/A"}
              </span>
            </p>
            <p>
              <DollarCircleOutlined />
              <span>
                {" "}
                {(info?.pricing?.showDisplayPrice &&
                  info?.pricing?.displayPrice) ||
                  info?.pricing?.asYouWishPricing?.minimum ||
                  info?.pricing?.standardPricing?.price ||
                  info?.pricing?.roleTierPricing?.map(
                    (item: any, index: number) => (
                      <i key={index}>
                        {item?.role
                          ?.split("_")
                          ?.map((_: string) => capitalize(_))
                          .join(" ") +
                          " : $" +
                          item.price}{" "}
                        <br />
                      </i>
                    )
                  ) ||
                  info?.pricing?.customTierPricing?.map(
                    (item: any, index: number) => (
                      <i key={index}>
                        {item.name + " : $" + item.price} <br />
                      </i>
                    )
                  ) ||
                  (info?.pricing?.model === "free" && "Free") ||
                  "N/A"}
              </span>
            </p>
            <p>
              <FileTextOutlined />
              <span>
                {" "}
                <b>Accessibility Notice</b> :{" "}
                {info?.accessibilityNotice ?? "N/A"}
              </span>
            </p>
          </div>
          <div className="flex start">
            <Button
              onClick={() => {
                if (edit === "preview" || info.status === "draft") {
                  return;
                }
                window.open(
                  info.category === "artist" ||
                    info.type === "Third Party Event"
                    ? info?.button?.url
                    : `${getEnv("WEB_HOST")}/checkout/${id}/purchase`,
                  "_blank"
                );
              }}
            >
              {info?.button?.label}
            </Button>{" "}
            {info?.pricing?.showTicketInventory &&
              info?.pricing?.inventory > -1 && (
                <span>
                  {info?.pricing?.inventory > -1
                    ? info?.pricing?.remaining
                    : info?.pricing?.customTierPricing?.reduce(
                        (sum = 0, item: any) => sum + item.remaining,
                        0
                      )}{" "}
                  tickets left
                </span>
              )}
          </div>
          <Caption hideImage src={info?.thumbnail} />
        </div>
      </div>
      <div className="event-content">
        <p
          dangerouslySetInnerHTML={{
            __html:
              info?.description && info.description.startsWith("<")
                ? info.description
                : `<p>${info?.description || ""}</p>`,
          }}
        ></p>
        {info?.contactInformation && (
          <p className="contact-info">
            <div className="info-btn-receive">
              <Button
                className="ant-btn-white"
                onClick={() => {
                  if (edit === "preview" || info.status === "draft") {
                    return;
                  }
                  window.open(
                    info.category === "artist" ||
                      info.type === "Third Party Event"
                      ? info?.button?.url
                      : `${getEnv("WEB_HOST")}/checkout/${id}/purchase`,
                    "_blank"
                  );
                }}
              >
                {info?.button?.label}
              </Button>
            </div>
            <h3>Contact information</h3>
            <div className="info-item">{info.contactInformation.name}</div>
            {info.contactInformation?.email
              ?.split(",")
              ?.map((e: string, idx: number) => (
                <div key={idx} className="info-item">
                  {e}
                </div>
              ))}
            <div className="info-item">{info.contactInformation.phone}</div>
          </p>
        )}
      </div>
      <div className="event-photo-list" hidden={info?.category === "the_field"}>
        {info?.photos?.map((item: any) => (
          <Caption src={item} key={item} />
        ))}
      </div>
      {/* {info?.documents && <div className="sub-infos">
        <h3 className="sub-title">Documents</h3>
        <div className="documents">
          {info?.documents?.map((d: string, idx: number) => (
            <div className="flex start doc">
              <img src={fileIcon} alt="file" />
              <a key={'d-url-' + idx} href={d}>
                {d.split("/").pop()}
              </a>
            </div>
          ))}
        </div>
      </div>} */}
      <Modal
        visible={showReport}
        onCancel={(e) => setShowReport(false)}
        onOk={(e) => {
          setShowReport(false);
          reportEvent(info.id, reportReason).then((res) => {
            res?.success && message.success("report success!");
          });
        }}
        maskClosable
        className="common-modal modal-report"
        cancelText="CANCEL"
        okText="REPORT"
        title={<h1>Report Event</h1>}
      >
        <Radio.Group
          onChange={(e) => setReportReason(e.target.value)}
          value={reportReason}
        >
          <Space direction="vertical">
            <Radio key="spam" value="spam">
              Spam
            </Radio>
            <Radio key="inappropriate" value="inappropriate">
              Inappropriate
            </Radio>
            <Radio key="legal" value="legal">
              Copyright or other legal complaints
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </PreviewContainer>
  );
};

export default Preview;
