import React from "react";

import HeaderLayout from "@/components/layouts/HeaderLayout";

import "@/styles/home/index.less";
import FooterLayout from "@/components/layouts/FooterLayout";

interface props {
  style?: object;
  hideMenu?: boolean;
  hideSign?: boolean;
  children: React.ReactNode;
}

const HomeLayout = ({ style = {}, hideMenu = false, hideSign, children }: props) => {
  return (
    <>
      <HeaderLayout hideMenu={hideMenu} hideSign={hideSign} />
      <main style={style}>{children}</main>
      <FooterLayout />
    </>
  );
};

export default HomeLayout;
