//created by Peggy on 2021/2/10
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form, Input, Button, Breadcrumb, message, Tooltip, Select } from "antd";
import DatePicker from "@/components/DatePicker";
import { CloudUploadOutlined, QuestionCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
import dayjs from "dayjs";
import UploadCrop from "@/components/UploadAndCrop/Cropperjs";

import { crowSave, crowPut, crowGet } from "@/api/donation-api";
import YoutubeInput from "@/components/Input/YoutubeInput";
import { TextEditor } from "@/components/TextEditor/TextEditor";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import AmountInput from "@/components/Input/AmountInput";
import { timezoneH, UserRole } from "@/types/enum";
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { localStorageGet } from "@/utils/storage";
import { admin_router_crowd_field, admin_router_crowd_field_preview } from "@/adminApp/routers";
dayjs.extend(utc)
dayjs.extend(tz)

const CreateContainer = styled.div`
  .ant-form-item-required {
    .ant-form-item-label label {
      &:after {
        display: inline-block;
        width: 12px;
        height: 12px;
        line-height: 12px;
        content: "*";
        color: #ff4d4f;
      }
    }
  }
  h2.ant-form-item-required {
    &:after {
      display: inline-block;
      width: 12px;
      height: 12px;
      line-height: 12px;
      margin-left: 4px;
      content: "*";
      color: #ff4d4f;
    }
  }
`;

const tzFormat = 'YYYY-MM-DD HH:mm';
const disabledDate = (current: any) => {
  // return current && current < dayjs().endOf("day");
  return current && current < dayjs();
};

const Create = () => {
  const [draft, setDraft] = useState(-1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRequired, setRequired] = useState(false);
  const [status, setStatus] = useState("draft");
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams() as any;
  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);

  const {
    store: { fromAdmin },
  } = useModel(user);
  const onFinish = (values: any) => {
    let { date, photos, goalAmount, featurePhotos, timezone, ...others } = values,
      [startDate, endDate] = date || ["", ""];
    let _data = {
      ...others,
      photos: photos?.map((item: any) => item.url),
      featurePhotos: featurePhotos?.map((item: any) => item.url),
      startDate,
      endDate,
      time: {
        start: startDate?.format(tzFormat),
        end: endDate?.format(tzFormat),
        timezone
      },
      goalAmount: goalAmount?.replace(",", ""),
      status: "draft",
    };
    setLoading(true);
    if (id) {
      crowPut(id, _data)
        .then((data) => {
          if (draft === 1) {
            history.push(isAdmin ? admin_router_crowd_field_preview.split(':')[0] + id :
              `/home/donation/crowd/preview/${id}/${values?.name.replace(
                /[^\w]/g,
                "-"
              )}`
            );
          } else {
            history.push(isAdmin ? admin_router_crowd_field : `/dashboard/donation/list`);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      crowSave(_data)
        .then((data) => {
          if (draft === 1) {
            history.push(isAdmin ? admin_router_crowd_field_preview.split(':')[0] + data.id :
              `/home/donation/crowd/preview/${data.id}/${values?.name.replace(
                /[^\w]/g,
                "-"
              )}`
            );
          } else {
            history.push(isAdmin ? admin_router_crowd_field : `/dashboard/donation/list`);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  const handleSubmit = async (val: number) => {
    await setDraft(val);
    await form.submit();
  };

  useEffect(() => {
    if (id) {
      let _data = {
        id,
      };
      crowGet(_data).then((data) => {
        data.time = data.time || { timezone: 'EST' }
        const {
          startDate = "",
          endDate = "",
          photos,
          featurePhotos,
          goalAmount,
          time: { timezone, start, end },
          ...others
        } = data;
        setStatus(data.status);
        form.setFieldsValue({
          ...others,
          timezone,
          date: [
            startDate ? dayjs((start || startDate).replace(/(\.\d+)?Z/, '')) : "",
            endDate ? dayjs((end || endDate).replace(/(\.\d+)?Z/, '')) : "",
          ],
          goalAmount: goalAmount?.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          featurePhotos: featurePhotos?.map((item: any, index: number) => ({
            uid: index,
            name: item.replace(/[^\d]+/g, ""),
            status: "done",
            url: item,
          })),
          photos: photos?.map((item: any, index: number) => ({
            uid: index,
            name: item.replace(/[^\d]+/g, ""),
            status: "done",
            url: item,
          })),
        });
      });
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <CreateContainer className="campaign-create-container common-container admin-common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href={isAdmin ? admin_router_crowd_field : "/dashboard/donation/list"}>
          Crowdfunding campaigns
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? "Edit" : "Create"} a crowdfunding campaign
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1>{id ? "Edit" : "Create"} a crowdfunding campaign</h1>
      <h4>
        Fill out the information below to create your own crowdfunding campaign.
      </h4>
      <Form
        layout="vertical"
        name="campaign"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="campaign-form">
          <h2>Campaign information</h2>
          <Form.Item
            label="Campaign name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Enter campaign name with 50 characters limit ",
              },
            ]}
          >
            <Input maxLength={50} placeholder="Enter campaign name" />
          </Form.Item>
          <Form.Item
            label="Short summary"
            name="summary"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                whitespace: false,
                message:
                  "Briefly explain what you're hoping to achieve. 140 character maximum",
              },
            ]}
          >
            <Input
              maxLength={140}
              placeholder="Briefly explain what you're hoping to achieve. 140 character maximum"
            />
          </Form.Item>
          <Form.Item
            className="ant-form-item-required textarea-wrap"
            label="Campaign description"
            name="description"
            rules={[
              {
                required: isRequired,
                whitespace: false,
                message: "Full campaign description and details ",
              },
            ]}
          >
            <TextEditor placeholder="Full campaign description and details" />
          </Form.Item>
          <Form.Item
            label="Donation goal"
            name="goalAmount"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                whitespace: false,
                message: "Enter donation goal",
              },
            ]}
          >
            <AmountInput
              disabled={id && status === "live" && !fromAdmin}
              placeholder="Enter amount" />
          </Form.Item>
          <Form.Item
            label={
              <div>
                Date
                <Tooltip
                  className="tooltip-field-item"
                  title="You cannot change the closing date of your crowdfunding campaign once this page is published. All donations to your crowdfunding campaign will be disbursed 10 business days after this date. Contact Artist Services if you have questions about how to schedule your campaign."
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </div>
            }
            name="date"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                type: "array",
                message: "Enter date",
              },
            ]}
          >
            <DatePicker.RangePicker
              disabled={id && status === "live" && !fromAdmin}
              disabledDate={disabledDate}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  dayjs("00:00", "hh:mm"),
                  dayjs("00:00", "hh:mm"),
                ],
                format: "hh:mm A",
              }}
              format='YYYY-MM-DD hh:mm A'
              getPopupContainer={() =>
                document.getElementsByClassName("common-container")[0] as any
              }
            />
          </Form.Item>
          <Form.Item
            name="timezone"
            label="Select timezone"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                message: "Select timezone",
              },
            ]}
          >
            <Select
              bordered={false}
              disabled={id && status === "live" && !fromAdmin}
              showSearch
              placeholder="Select timezone"
              getPopupContainer={() =>
                document.getElementsByClassName(
                  "common-container"
                )[0] as any
              }
            >
              {timezoneH.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <h2>Campaign media</h2>
          <Form.Item
            name="youtubeUrl"
            rules={[
              {
                required: false,
                message: "Missing Youtube or Vimeo url",
              },
              {
                pattern: /((?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11}))|(vimeo\.com\/[\d]{8})/g,
                message: "Enter valid Youtube or Vimeo url",
              },
            ]}
            className="youtube-field-item"
          >
            <YoutubeInput
              placeholder="Enter youtube or Vimeo url"
              label="Youtube or Vimeo url"
            />
          </Form.Item>
          <h2 className="ant-form-item-required">Featured photo</h2>
          <Form.Item
            label=""
            noStyle
            name="featurePhotos"
            rules={[
              { required: isRequired, message: "Missing featured photo" },
            ]}
          >
            <UploadCrop
              folder="donation"
              scale={117 / 67}
              listType="picture"
              className="upload-item-wrap"
              showUploadList
              maxCount={1}
            >
              <div className="children-upload">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Upload 1 featured photo</p>
              </div>
            </UploadCrop>
          </Form.Item>
          <h2 style={{ marginTop: "40px" }}>Photos</h2>
          <Form.Item
            label=""
            name="photos"
            noStyle
            rules={[
              {
                required: false,
                type: "array",
                message: "photos",
              },
            ]}
          >
            <UploadCrop
              folder="donation"
              listType="picture"
              className="upload-item-wrap"
              showUploadList
              maxCount={15}
            >
              <div className="children-upload">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Please upload 1 file at a time. Strictly prohibit from
                  uploading company data or other band files
                </p>
              </div>
            </UploadCrop>
          </Form.Item>
        </div>
        <p className="flex end btn">
          <Button loading={loading} onClick={() => handleSubmit(0)}>
            Save as draft
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => handleSubmit(1)}
          >
            Preview
          </Button>
        </p>
      </Form>
    </CreateContainer>
  );
};

export default Create;
