import { useState, useCallback, useMemo, useEffect } from "react";
import Axios from "axios";
import { message } from "antd";
import queryString from "query-string";

import { getToken } from "../utils/storage";

import { useModel } from "use-reaction";
import { saveToken, user } from "@/model/user";

type Get = {
  type: "get";
  params?: {
    [propName: string]: any;
  };
};
interface Post {
  type?: string;
  data?: {
    [propName: string]: any;
  };
  headers?: {
    "content-type"?: string;
    noLoading?: boolean | undefined;
  };
}
type Options = (Get | Post) & { dataType?: string };

const host = (() => {
  let host =
    process.env.REACT_APP_API_HOST || "http://staging-api.thefield.com";
  while (host.endsWith("/")) {
    host = host.substr(0, host.length - 1);
  }
  return host;
})();

const _axios = Axios.create({
  baseURL: host,
  timeout: 30000,
});
_axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const useRequestHandler = () => {
  const { doAction, resetModel } = useModel(user);
  useEffect(() => {
    _axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response) {
          switch (error?.response?.status) {
            case 400:
              onMessage(error?.response?.data?.message);
              break;
            case 401:
              // 401 clear token and link to login page
              onMessage(error?.response?.data?.message);
              doAction(saveToken, "");
              resetModel();
              localStorage.clear();
              break;
            case 500:
              onMessage("Server error, please retry");
              break;
            case 404:
            case 403:
            case 409:
              onMessage(error?.response?.data?.message);
              break;
            default:
              onMessage("Inner error, please retry");
          }
          return Promise.reject(error.response);
        } else {
          onMessage("ERR_INTERNET_DISCONNECTED");
          return Promise.reject({
            status: "failed",
            msg: "ERR_INTERNET_DISCONNECTED",
          });
        }
      }
    );
    // eslint-disable-next-line
  }, []);
};
export const useLoadingHandler = () => {
  const [counter, setCounter] = useState(0);
  const inc = useCallback(
    () => setCounter((counter) => counter + 1),
    [setCounter]
  ); // add to counter
  const dec = useCallback(
    () => setCounter((counter) => counter - 1),
    [setCounter]
  ); // remove from counter

  const interceptors = useMemo(
    () => ({
      // eslint-disable-next-line no-sequences
      request: (config: any) => (!config?.headers?.noLoading && inc(), config),
      // eslint-disable-next-line no-sequences
      response: (response: any) => (
        !response?.config?.headers?.noLoading && dec(), response
      ),
      // eslint-disable-next-line no-sequences
      error: (error: any) => (dec(), Promise.reject(error)),
    }),
    [inc, dec]
  ); // create the interceptors

  useEffect(() => {
    const reqInterceptor = _axios.interceptors.request.use(
      interceptors.request,
      interceptors.error
    );
    const resInterceptor = _axios.interceptors.response.use(
      interceptors.response,
      interceptors.error
    );
    return () => {
      _axios.interceptors.request.eject(reqInterceptor);
      _axios.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [counter > 0];
};
const onMessage = (msg: string) => {
  if (document.getElementsByClassName("global-message").length === 0) {
    message.error({
      content: msg,
      className: "global-message",
    });
  }
};

const Request = async (url: string, options: Options) => {
  let opt: any = options || {};
  if (options.type === "form") {
    const formD = new FormData();
    for (let k in options.data) {
      formD.append(k, options.data[k]);
    }
    options.data = formD;
    options.headers = {};
    options.type = "post";
  }
  try {
    let config: any = {
      method: opt.type || "post",
      url: url,
      params: opt.params || {},
      paramsSerializer: (params: any) => {
        return queryString.stringify(params, { arrayFormat: "none" });
      },
      data:
        (opt?.headers &&
        opt?.headers["Content-Type"]?.indexOf("x-www-form-urlencoded") > 0
          ? queryString.parse(opt?.data)
          : opt?.data) || {},
      responseType: opt.dataType || "json",
      headers: opt.headers || {
        "Content-Type": "application/json; charset=UTF-8",
      },
      timeout: opt.timeout || 30000,
    };
    let response = await _axios(config);
    return Promise.resolve(response.data);
  } catch (e: any) {
    return Promise.reject(e?.response || e);
  }
};

export default Request;
