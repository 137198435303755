//
import request from '@/libs/fetch'

export const getSponsorForView = async () =>
  // await Request('/sponsorship/detail/' + id, { type: 'get' })
  await request('/sponsorship', {
    type: 'get'
  })

export const getSponsor = async (params: any) =>
  await request('/sponsorship', {
    params,
    type: 'get'
  })

export const getInvoiceList = async (params: any) =>
  await request('/sponsorship/invoices', {
    params,
    type: 'get'
  })

export const getInvoiceDetail = async (id: string) =>
  await request(`/sponsorship/invoice/${id}`, {
    type: 'get'
  })

export const saveSponsor = async (data: any) =>
  await request('/sponsorship', {
    data,
    type: 'put'
  })

export const getSignature = async () =>
  await request('/sponsorship/signature-info', {
    type: 'get'
  })

export const saveSignature = async (data: any) =>
  await request('/sponsorship/signature', {
    data
  })

export const finishSignature = async (data: any) =>
  await request('/sponsorship/finish-signature', {
    data
  })

export const cancelSubscription = async () =>
  await request('/sponsorship/cancel-subscription', {
    type: 'delete'
  })

export const cancelSponsorship = async () =>
  await request(`/sponsorship/cancel-sponsorship`, {
    type: 'delete'
  })
