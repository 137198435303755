import {
  Button,
  Table,
  Tooltip,
  Input,
  Menu,
  message,
  Dropdown,
  Tag,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import "./grant_list.less";
import { useHistory } from "react-router-dom";
import {
  admin_router_applications_of,
  admin_router_grant_edit,
  admin_router_grant_post,
} from "@/adminApp/routers";
import {
  LabelDateRangePicker,
  LabelSelect,
} from "@/components/LabelInput/LabelInput";
import { KV, useModel } from "use-reaction";
import { model_grant_post } from "@/adminApp/models/model_grant_post";
import { allOpt, disciplineOptions, grantStatusOptions } from "@/types/enum";
import {
  searchGrants,
  duplicateGrant,
  deleteGrant,
} from "@/adminApp/apis/grant-api";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import queryString from "query-string";

import _ from "lodash";
import { getEnv } from "@/libs/cfg";
import useConfirm from "@/components/Modal/Confirm";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
const pageSize = 10;
const initFilters = {
  keyword: undefined,
  discipline: [],
  deadline: [undefined, undefined],
  createDate: [undefined, undefined],
  status: allOpt,
  page: 1,
  limit: pageSize,
};
const getStatusColor = (status: string) => {
  switch (status) {
    case "live":
      return "blue";
    case "draft":
      return "";
    case "expired":
      return "red";
    default:
      return "green";
  }
};
const statusOpts = grantStatusOptions
  .concat(allOpt)
  .map((o) => ({ label: _.capitalize(o), value: o }));
const disciplineOpts = disciplineOptions.map((o) => ({
  label: _.capitalize(o),
  value: o,
}));
export const GrantList = () => {
  const history = useHistory();
  const [filters, setFilters] = useState<KV>(initFilters);
  const [list, setList] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const { resetModel: resetGrantPost } = useModel(model_grant_post);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [curPage, setCurPage] = useState(1);
  const [windowHeight] = useWindowSize()
  const { setShow: deleteShowConfirm, component: deleteConfirm } = useConfirm(
    <div className="flex column start align-start">
      <p>
        You are about to permantly delete this grant. <br />
        By clicking ‘Delete’, you will:
      </p>
      <ul>
        <li>Delete this grant </li>
        <li>Delete all applications applied for this grant</li>
        <li>Delete all application notes related to this grant </li>
      </ul>
    </div>,
    {
      title: "Are you sure you want to permanently delete this grant ?  ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (id?: any) => {
        setLoading(true);
        deleteGrant(id).then((res) => {
          if (res?.success) {
            message.success("delete success.");
            searchList();
          }
        });
        setLoading(false);
      },
    }
  );
  const updateFilter = (key: string, val: any) => {
    setCurPage(1);
    setFilters({ ...filters, [key]: val });
  };
  const searchList = async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    const res = await searchGrants({
      ...filters,
      page: curPage,
      limit: 10,
    });
    setLoading(false);
    setList((res.grants || []).map((g: any) => ({ ...g, key: g.id })));
    setTotal(res.total || 0);
  };

  const exportCsv = () => {
    const { page, limit, discipline, ...others } = filters;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/grant/export/csv?${queryString.stringify({
        ...others,
        discipline: discipline?.length ? discipline.join(",") : undefined,
        ids: selectedRows.map((item: any) => item.id).join(","),
      })}`,
      "_blank"
    );
  };

  const menuAction = useMemo(() => {
    return {
      view_detail: (id: number) => {
        window.open(getEnv("WEB_HOST") + "/home/grant/" + id, "_blank");
      },
      view_apply: (id: number) => {
        history.push(admin_router_applications_of.replace(":id", id + ""));
      },
      edit: (id: number) => {
        history.push(admin_router_grant_edit.replace(":id", id + ""));
      },
      duplicate: async (id: number) => {
        setLoading(true);
        const res = await duplicateGrant(id);
        if (res?.id) {
          message.success("duplicate success");
          searchList();
        }
        setLoading(false);
      },
      delete: (id: number) => {
        deleteShowConfirm(id);
      },
    };
    // eslint-disable-next-line
  }, [deleteShowConfirm]) as any;
  useEffect(() => {
    searchList();
    // eslint-disable-next-line
  }, [filters, curPage]);

  const columns: any[] = [
    {
      title: "Grant name",
      dataIndex: "name",
      fixed: "left",
      maxWidth: 230,
      ellipsis: {
        showTitle: true,
      },
      className: "ellipsis-custom-cell",
    },
    {
      title: "Application",
      width: 120,
      dataIndex: "applycount",
      render: (num: number) => <div className="application-num">{num}</div>,
    },
    {
      title: "Grant Organization",
      dataIndex: "organization",
      width: 200,
      render: (org: string) => <div className="organization">{org}</div>,
    },
    {
      title: "Discipline",
      width: 200,
      dataIndex: "discipline",
      render: (discipline: string) => (
        <div className="discipline">{discipline}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <Tag className="status" color={getStatusColor(status)}>
          {_.capitalize(status)}
        </Tag>
      ),
    },
    {
      title: "Contact Email",
      width: 180,
      dataIndex: "email",
      render: (email: string) => <div className="email">{email}</div>,
    },
    {
      title: "Date Created",
      width: 130,
      dataIndex: "createDate",
      render: (date: string) => (
        <div className="date-created">{dayjs(date).format(dateFormat)}</div>
      ),
    },
    {
      title: "Deadline",
      width: 130,
      dataIndex: "deadline",
      render: (deadline: string, item: any) => (
        <div className="deadline">
          {item.deadlineType === "hard" && deadline
            ? dayjs(deadline).format(dateFormat)
            : "N/A"}
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: 90,
      fixed: "right",
      render: (id: number) => (
        <Tooltip
          className="action-tool"
          placement="bottomLeft"
          overlay={
            <Menu onClick={({ key }) => menuAction[key](id)}>
              <Menu.Item key="view_detail">View details</Menu.Item>
              <Menu.Item key="view_apply">View applications</Menu.Item>
              <Menu.Item key="edit">Edit</Menu.Item>
              <Menu.Item key="duplicate">Duplicate</Menu.Item>
              <Menu.Item key="delete">Delete</Menu.Item>
            </Menu>
          }
        >
          <div className="action">
            <FilterMore />
          </div>
        </Tooltip>
      ),
    },
  ];
  const dateFormat = "MM/DD/YYYY";
  return (
    <div className="admin-common-container admin-grant-list">
      <div className="topbar flex start">
        <h2 className="title">Grant List</h2>
        <Button
          className="filter-item"
          type="primary"
          onClick={(e) => {
            resetGrantPost();
            history.push(admin_router_grant_post);
          }}
        >
          Post a grant
        </Button>
      </div>
      <div className="flex wrap between filters">
        <LabelDateRangePicker
          label="Date created"
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          className="filter-item date-rante datecreate"
          format={dateFormat}
          value={filters.createDate}
          onChange={(e) => updateFilter("createDate", e)}
        />
        <LabelDateRangePicker
          label="Deadline"
          className="filter-item date-rante deadline"
          format={dateFormat}
          value={filters.deadline}
          onChange={(e) => updateFilter("deadline", e)}
        />
        <LabelSelect
          className="filter-item"
          label="Discipline"
          placeholder="Select Discipline"
          value={filters.discipline}
          mode="multiple"
          onChange={(e) => updateFilter("discipline", e)}
          options={disciplineOpts}
        />
        <LabelSelect
          className="filter-item"
          label="Status"
          placeholder="Select Status"
          value={filters.status}
          onChange={(e) => updateFilter("status", e)}
          options={statusOpts}
        />
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={exportCsv}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        title={() => (
          <div className="flex">
            <span>Grants</span>
            <Input.Search
              onSearch={(e) => updateFilter("keyword", e)}
              placeholder="Search"
            />
          </div>
        )}
        className="table-container list"
        dataSource={list}
        columns={columns}
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: curPage,
          pageSize,
          onChange: (page, pageSize) => {
            setCurPage(page);
          },
        }}
        sticky
        showHeader={!!list?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {/* </div> */}
      {deleteConfirm}
    </div>
  );
};
