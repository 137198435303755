//created by Peggy on 2021/5/5
import React, { useEffect, useState } from 'react'
import DatePicker from '@/components/DatePicker'
import dayjs from 'dayjs'
import { Divider, Dropdown, Form, Input, Menu, message, Modal, Select, Table, Tag, Tooltip } from 'antd'
import { ReactComponent as FilterMore } from '@/images/filter-more.svg'
import { useHistory } from 'react-router-dom'
import { sponsorshipList } from '@/adminApp/apis/application-api'
import { membershipStatus } from '@/types/enum'

import './list.less'
import queryString from 'query-string'
import { ReactComponent as Filter } from '@/images/filter.svg'
import {
  admin_router_application_membership_review,
  admin_router_message_to
} from '@/adminApp/routers'
import { disciplineOptions as options } from '@/types/enum'
import { shareLink } from '@/api/document-api'
import { getEnv } from '@/libs/cfg'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useWindowSize } from '@/adminApp/models/model_admin_size'

const membershipStatusOpts = [
  {
    label: 'Pending',
    value: 'in_review'
  },
  {
    label: 'Approved',
    value: 'approved'
  },
  {
    label: 'Fee Paid',
    value: 'paid'
  },
  {
    label: 'Waiting for Signature',
    value: 'wait_for_signature'
  },
  {
    label: 'Active',
    value: 'completed'
  },
  {
    label: 'Expired',
    value: 'expired'
  },
  {
    label: 'Declined',
    value: 'rejected'
  }
]

export const subsidyStatus: any = {
  pending: { text: '-', color: 'orange' },
  approved: { text: 'Yes', color: 'green' },
  rejected: { text: 'No', color: 'red' }
}

const List = () => {
  const history = useHistory()

  // const { setShow: deleteShow, component: deleteComponent } = useConfirm(
  //   <div className="flex column start align-start">
  //     <p>
  //       You are about to permantly delete this account. <br />
  //       By clicking ‘Delete’, you will:
  //     </p>
  //     <ul>
  //       <li>Cancel all subscriptions </li>
  //       <li>Delete this account </li>
  //       <li>Delete all content related to this account</li>
  //       <li>Disable this user from loging in </li>
  //     </ul>
  //   </div>,
  //   {
  //     title: "Are you sure you want to permanently delete this account ?  ",
  //     className: "suspend-confirm-modal",
  //     okText: "Delete",
  //     onOk: (id?: any) => {
  //       // userDelete({
  //       //   id,
  //       // }).then(() => {
  //       //   getList();
  //       //   message.success("successfully");
  //       // });
  //     },
  //   }
  // );

  //handler
  const handleExport = () => {
    const { page, limit, date, ...others } = params
    const [start, end] = date || ['', '']

    window.open(
      `${process.env.REACT_APP_API_HOST}/sponsorship/export-csv?${queryString.stringify({
        ...others,
        startDate: (start && dayjs(start?.toString())?.format('YYYY-MM-DD')) || '',
        endDate: (end && dayjs(end?.toString())?.format('YYYY-MM-DD')) || '',
        exportId: selectedRows.map((item: any) => item.id).join(','),
        tz: dayjs.tz.guess()
      })}`,
      '_blank'
    )
  }
  const handleExportBoard = () => {
    const { page, limit, date, ...others } = params
    const [start, end] = date || ['', '']

    window.open(
      `${process.env.REACT_APP_API_HOST}/sponsorship/export-board-csv?${queryString.stringify({
        ...others,
        startDate: (start && dayjs(start?.toString())?.format('YYYY-MM-DD')) || '',
        endDate: (end && dayjs(end?.toString())?.format('YYYY-MM-DD')) || '',
        exportId: selectedRows.map((item: any) => item.id).join(','),
        tz: dayjs.tz.guess()
      })}`,
      '_blank'
    )
  }

  const handleLink = (id: number) => {
    window.open(admin_router_application_membership_review.replace(/:id/g, id.toString()), "_blank");
  }
  const [loading, setLoading] = useState<boolean>(false)
  const [shareForm] = Form.useForm()
  const [showShare, setShowShare] = useState<any>(undefined)
  const handleSubmitShare = () => {
    setLoading(true)
    shareForm
      .validateFields()
      .then((values) => {
        shareLink({
          ...values,
          url:
            `${getEnv('ADMIN_HOST')}/sign-in?to=` +
            admin_router_application_membership_review.replace(/:id/g, showShare.id.toString()),
          title: 'Application'
        })
          .then((_) => {
            _?.success && message.success('succeed.')
            onCancelShare()
          })
          .catch(() => {
            setLoading(false)
          })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onCancelShare = () => {
    shareForm.resetFields()
    setLoading(false)
    setShowShare(undefined)
  }
  //handler end

  const renderApplicationStatus = (text: string, _: any) => {
    return <Tag color={membershipStatus[text] ? membershipStatus[text]?.color : 'gray'}>
      {_.subsidyStatus === '-' ? 'Social Justice Pending' : membershipStatus[text] ? membershipStatus[text]?.text : text}
    </Tag>
  }

  const renderSJSA = (text: string, _: any) => {
    if(text === 'Yes'){
      return <Tag color='green'>{text}</Tag>
    }else if(text === 'No'){
      return <Tag color='red'>{text}</Tag>
    }
    return text || 'N/A'
  }

  const column: any[] = [
    {
      title: 'Date submitted',
      dataIndex: 'submitDate',
      fixed: 'left',
      key: 'createDate',
      width: 150,
      render: (text: string, row: any) => (text ? dayjs(text).format('MM/DD/YYYY') : 'N/A')
    },
    {
      title: 'Application status',
      dataIndex: 'status',
      fixed: 'left',
      width: 180,
      className: 'artist-cell-roles',
      render: renderApplicationStatus
    },
    {
      title: <>SJSA APPROVED <Tooltip
      overlay={
        <div style={{padding:10}}>
          <div><b>Yes</b> (if SJSA approved)</div>
          <div><b>No</b> (if SJSA declined)</div>
          <div><b>N/A</b> (did not apply for SJSA)</div>
          <div><b> - </b> (application has not yet been reviewed) </div>
        </div>
      }
    >
      <QuestionCircleOutlined
        size={12}
        style={{ padding: 6, minWidth: 20 }}
      />
    </Tooltip></>,
      dataIndex: 'subsidyStatus',
      width: 170,
      className: 'artist-cell-roles',
      render: renderSJSA
    },
    {
      title: 'first name',
      width: 120,
      dataIndex: 'firstName'
    },
    {
      title: 'Last name',
      width: 120,
      dataIndex: 'lastName'
    },
    {
      title: 'Company',
      width: 100,
      dataIndex: 'companyName'
    },
    {
      title: 'date approved',
      dataIndex: 'approvedDate',
      width: 150,
      key: 'approvedDate',
      render: (text: string, row: any) => text ? dayjs(text).format('MM/DD/YYYY') : '-'
    },
    {
      title: 'SA Legal Name',
      width: 150,
      dataIndex: 'legalName'
    },
    {
      title: 'email address',
      dataIndex: 'email'
    },
    // {
    //   title: "contract",
    //   dataIndex: "contact",
    //   key: "contract",
    //   render: (text: string) =>
    //     (text && (
    //       <a
    //         href={`${process.env.REACT_APP_API_HOST}/sponsorship/hellosign-download?id=${text}`}
    //         target="_blank"
    //         rel="noreferrer"
    //       >
    //         contract.pdf
    //       </a>
    //     )) ||
    //     "N/A",
    // },
    {
      title: 'Membership type',
      width: 160,
      dataIndex: 'type'
    },
    {
      title: 'Membership expiry date',
      dataIndex: 'expireDate',
      width: 210,
      key: 'expireDate',
      render: (text: string, row: any) => (text && dayjs(text).format('MM/DD/YYYY')) || 'N/A'
    },
    {
      title: 'Primary Discipline',
      dataIndex: 'primaryTag',
      width: 180,
      ellipsis: {
        showTitle: true
      },
      className: 'ellipsis-custom-cell',
      render: (text: string) => text || 'N/A'
    },
    {
      title: 'Other Disciplines',
      dataIndex: 'tags',
      maxWidth: 200,
      ellipsis: {
        showTitle: true
      },
      className: 'ellipsis-custom-cell',
      render: (text: string[]) => text?.join(',') || 'N/A'
    },
    {
      title: 'Address 1',
      dataIndex: 'address1',
      width: 120,
      maxWidth: 200,
      ellipsis: {
        showTitle: true
      },
      className: 'ellipsis-custom-cell',
      render: (text: string[]) => text || 'N/A'
    },
    {
      title: 'Address 2',
      dataIndex: 'address2',
      width: 120,
      maxWidth: 200,
      ellipsis: {
        showTitle: true
      },
      className: 'ellipsis-custom-cell',
      render: (text: string[]) => text || 'N/A'
    },
    {
      title: 'City',
      width: 100,
      dataIndex: 'city'
    },
    {
      title: 'State',
      width: 100,
      dataIndex: 'state'
    },
    {
      title: 'zip code',
      width: 120,
      dataIndex: 'zipCode'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      render: (text: string, row: any) => text || 'United States'
    },
    {
      title: 'Phone number',
      width: 150,
      dataIndex: 'phone'
    },
    {
      title: 'contract signed',
      dataIndex: 'signDate',
      width: 170,
      key: 'signDate',
      render: (text: string, row: any) => (text ? dayjs(text).format('MM/DD/YYYY') : 'N/A')
    },
    {
      title: '',
      width: 80,
      fixed: 'right',
      align: 'center',
      key: 'action',
      render: (_: string, row: any) => (
        <Dropdown
          trigger={['click']}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleLink(row.id)}>View application</Menu.Item>
                <Menu.Item
                  onClick={() => history.push(admin_router_message_to.replace(':to', row.userId))}
                >
                  Message
                </Menu.Item>
                <Menu.Item onClick={() => setShowShare(row)}>Share link</Menu.Item>
                {/*<Menu.Item onClick={() => handleDelete(row)}>*/}
                {/*  Delete account*/}
                {/*</Menu.Item>*/}
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      )
    }
  ]
  const [text, setText] = useState('')
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: ''
  })
  const [total, setTotal] = useState(0)
  const [tableData, setTableData] = useState<any[]>()
  const [selectedRows, setSelectedRows] = useState<any>([])

  const [windowHeight] = useWindowSize();

  const [filterForm] = Form.useForm()
  const [filterModal, setFilterModal] = useState<any>(false)
  const handleViewFilter = () => {
    setFilterModal(true)
  }
  const handleFilterFinish = (values: any) => {
    const { status, ...others } = values
    setSelectedRows([])
    setTableData([])
    setParams({
      ...params,
      ...others,
      status: status?.reduce((arr: string[], s: string) => {
        const a = s.split(',')
        return arr.concat(a)
      }, []),
      page: 1,
      limit: 10
    })
    setFilterModal(false)
  }

  const handleReset = () => {
    filterForm.resetFields()
    setText('')
    setParams({ page: 1, limit: 10, keyword: '' })
  }

  const getList = () => {
    const { date, ...others } = params,
      [start, end] = date || ['', '']
    let _data = {
      startDate: (start && dayjs(start?.toString())?.format('YYYY-MM-DD')) || '',
      endDate: (end && dayjs(end?.toString())?.format('YYYY-MM-DD')) || '',
      ...others
    }
    sponsorshipList(_data)
      .then((data) => {
        setTableData(data.items)
        setTotal(data.meta.totalItems)
      })
      .catch(() => {
        setTableData([])
        setTotal(0)
      })
  }
  useEffect(() => {
    getList()
    // eslint-disable-next-line
  }, [params])
  return (
    <div className="admin-membership-container admin-common-container">
      <h1>Fiscal Sponsorship</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && current > dayjs().endOf('day')
              }}
              value={params?.date}
              onChange={(e) => {
                setTableData([])
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e
                })
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Search admin note</span>
            <Input.Search
              allowClear
              placeholder="Search..."
              value={params?.note}
              onChange={(e) => {
                setTableData([])
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  note: e.target.value
                })
              }}
            />
          </div>
          <div className="filter-item" onClick={handleViewFilter}>
            <Filter />
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Dropdown
          className="filter-item"
          trigger={['click']}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
              <Menu.Item onClick={handleExportBoard}>Export Board CSV</Menu.Item>
              {/*<Menu.Item onClick={handleShowUserModal}>Add new user</Menu.Item>*/}
              {/*<Menu.Item onClick={() => handleShowTagModal(true)}>*/}
              {/*  Add new user tag*/}
              {/*</Menu.Item>*/}
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container scroll-table"
        title={() => (
          <div className="flex">
            <span>Application</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) => {
                setTableData([])
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e
                })
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: 'checkbox',
          onChange: (keys, rows) => {
            setSelectedRows(rows)
          }
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize
            })
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {/* </div> */}
      <Modal
        width={670}
        title="Advanced filters"
        className="common-modal admin-artist-modal admin-sponsorship-modal"
        visible={filterModal}
        onOk={() => filterForm.submit()}
        okText="Apply"
        cancelText="cancel"
        onCancel={() => setFilterModal(false)}
        forceRender
        centered
        maskClosable={false}
      >
        <Form form={filterForm} onFinish={handleFilterFinish} layout="vertical" name="form_filter">
          <h4>Discipline</h4>
          <div className="flex">
            <Form.Item label="Condition" name="tagCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="all_of">Is all of </Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Discipline" name="tag">
              <Select allowClear mode="multiple" placeholder="select..." bordered={false}>
                {options.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <h4>Status</h4>
          <div className="flex">
            <Form.Item label="Condition" name="statusCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Select allowClear mode="multiple" placeholder="select..." bordered={false}>
                {membershipStatusOpts.map((item) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <h4>Social Justice Status</h4>
          <div className="flex">
            <Form.Item label="Condition" name="subsidyStatusCondition">
              <Select allowClear placeholder="select..." bordered={false}>
                <Select.Option value="one_of">Is one of</Select.Option>
                <Select.Option value="none_of">Is none of</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Status" name="subsidyStatus">
              <Select allowClear mode="multiple" placeholder="select..." bordered={false}>

                  <Select.Option key="approved" value="approved">
                    approved
                  </Select.Option>
                  <Select.Option key="pending" value="pending">
                    pending
                  </Select.Option>
                  <Select.Option key="rejected" value="rejected">
                    rejected
                  </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      {/*{deleteComponent}*/}
      <Modal
        className="common-modal document-modal"
        title="Share Application Link"
        visible={!!showShare}
        onOk={handleSubmitShare}
        okButtonProps={{
          loading: loading
        }}
        okText="send"
        cancelText="cancel"
        onCancel={onCancelShare}
        forceRender
        centered
      >
        <p className="des">
          You are shareing:
          <span style={{ fontWeight: 'bold', paddingLeft: 6 }}>{showShare?.name}</span>
        </p>
        <Form form={shareForm} layout="vertical" name="form_inking">
          <Form.Item
            label="Reciever email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Input email'
              }
            ]}
          >
            <Input placeholder="Input email to share this link" />
          </Form.Item>
          <Form.Item
            label="Reciever name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Input reciever name'
              }
            ]}
          >
            <Input placeholder="Input reciever name" />
          </Form.Item>
          <Form.Item
            label="Message"
            name="msg"
            rules={[
              {
                whitespace: true,
                message: 'Input message'
              }
            ]}
          >
            <Input.TextArea
              minLength={5}
              style={{ height: 100 }}
              placeholder="Input message to send out this link"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default List
