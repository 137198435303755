import {
  deleteGrantApply,
  searchGrantApplications,
} from "@/adminApp/apis/grant-api";
import {
  admin_router_application_review,
  admin_router_message_to,
} from "@/adminApp/routers";
import { LabelSelect } from "@/components/LabelInput/LabelInput";
import { allOpt } from "@/types/enum";
import { Button, Input, Menu, message, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { KV } from "use-reaction";
import "./grant_applications.less";
import Back from "@/components/Back";
import queryString from "query-string";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";

const pageSize = 12;
const initFilters: KV = {
  grant_id: undefined,
  keyword: undefined,
  deadline: [undefined, undefined],
  createDate: [undefined, undefined],
  status: allOpt,
  page: 1,
  limit: pageSize,
};
const dateFormat = "MM/DD/YYYY";
const statusOpts = [
  {
    label: allOpt,
    value: allOpt,
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Approved",
    value: "approved",
  },
];
const statusDisplay = {
  applied: {
    lbl: "Pending",
    color: "orange",
  },
  rush_pending: {
    lbl: "Pending",
    color: "orange",
  },
  approved: {
    lbl: "Approved",
    color: "green",
  },
  rejected: {
    lbl: "pending",
    color: "red",
  },
  failed: {
    lbl: "Failed",
    color: "red",
  },
};
export const GrantApplications = () => {
  const history = useHistory();
  const { id } = useParams() as any;
  initFilters.grant_id = parseInt(id);
  const [filters, setFilters] = useState<KV>(initFilters);
  const [list, setList] = useState<any>([]);
  const [grantName, setGrantName] = useState("");
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [curPage, setCurPage] = useState(1);

  const columns = [
    {
      title: "SA name",
      width: 130,
      dataIndex: "user_legal_name",
      render: (name: number) => <div className="legal-name">{name}</div>,
    },
    {
      title: "Contact Email",
      dataIndex: "email",
      render: (email: string) => <div className="email">{email}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: keyof typeof statusDisplay) => (
        <Tag color={statusDisplay[status].color} className="status">
          {statusDisplay[status].lbl}
        </Tag>
      ),
    },
    {
      title: "Due Date",
      width: 120,
      dataIndex: "grant_deadline",
      render: (deadline: string, item: any) => (
        <div className="deadline">
          {item.deadlineType === "hard" && deadline
            ? dayjs(deadline).format(dateFormat)
            : "N/A"}
        </div>
      ),
    },
    {
      title: "Date Submitted",
      width: 150,
      dataIndex: "createDate",
      render: (date: string) => (
        <div className="date-created">{dayjs(date).format(dateFormat)}</div>
      ),
    },
    {
      title: "Date Approved",
      width: 150,
      dataIndex: "approveDate",
      render: (date: string) => (
        <div className="date-created">{dayjs(date).format(dateFormat)}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      width: 90,
      render: (id: number, item: any) => (
        <Tooltip
          className="action-tool"
          placement="bottomLeft"
          overlay={
            <Menu onClick={({ key }) => menuAction[key](id, item)}>
              <Menu.Item key="view_detail">View details</Menu.Item>
              <Menu.Item key="message">Message Artist</Menu.Item>
              <Menu.Item key="delete">Delete Application</Menu.Item>
            </Menu>
          }
        >
          <div className="action">
            <FilterMore />
          </div>
        </Tooltip>
      ),
    },
  ];

  const updateFilter = (key: string, val: any) => {
    setFilters({ ...filters, [key]: val });
  };
  const searchList = async () => {
    if (loading || !filters.grant_id) {
      return;
    }
    setLoading(true);

    const res = await searchGrantApplications(filters);
    setLoading(false);
    setList((res.items || []).map((g: any) => ({ ...g, key: g.id })));
    setTotal(res.total || 0);
    if (res.items.length) {
      setGrantName(res.items[0].grant_name);
    }
  };

  const exportCsv = () => {
    const { page, limit, ...others } = filters;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/grant/applications/export-csv?${queryString.stringify({
        ...others,
        ids: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  const menuAction = useMemo(() => {
    return {
      view_detail: (id: number) => {
        history.push(admin_router_application_review.replace(":id", id + ""));
      },
      message: async (id: number, item: any) => {
        history.push(admin_router_message_to.replace(":to", item.user_id));
      },
      delete: async (id: number) => {
        setLoading(true);
        const res = await deleteGrantApply(id);
        if (res?.success) {
          message.success("delete success.");
          searchList();
        }
        setLoading(false);
      },
    };
    // eslint-disable-next-line
  }, []) as any;

  useEffect(() => {
    searchList();
    // eslint-disable-next-line
  }, [filters]);
  return (
    <div className="admin-common-container grant-application-list">
      <Back />
      <div className="topbar flex start">
        <h2 className="title">{grantName}</h2>
      </div>
      <div className="flex wrap between filters">
        <LabelSelect
          className="filter-item"
          label="Status"
          placeholder="Select Status"
          value={filters.status}
          onChange={(e) => updateFilter("status", e)}
          options={statusOpts}
        />
        <Button className="filter-item" onClick={exportCsv}>
          Export to csv
        </Button>
      </div>
      <div className="flex between statistics">
        <h3>Application list</h3>
        <div className="num">{total} applications</div>
      </div>

      {/* <div className="table-container"> */}
      <Table
        title={() => (
          <div className="flex">
            <span>Applications</span>
            <Input.Search
              onSearch={(e) => updateFilter("keyword", e)}
              placeholder="Search"
            />
          </div>
        )}
        className="table-container list"
        dataSource={list}
        columns={columns}
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: curPage,
          pageSize,
          onChange: (page, pageSize) => {
            setCurPage(page);
            searchList();
          },
        }}
        sticky
        showHeader={!!list?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
      />
      {/* </div> */}
    </div>
  );
};
