//
import request from "@/libs/fetch";

export const saveDonation = async (data: any) =>
  await request("/donation", {
    data,
  });

export const getArtistList = async (params: any) =>
  await request("/user/sponsored/list", {
    type: "get",
    params,
  });

export const getArtist = async (params: any) =>
  await request("/artist", {
    type: "get",
    params,
  });

export const getArtistById = async (params: any, preview?: boolean, noLoading?: boolean) =>
  await request(`/artist/${params.id}`, {
    type: "get",
    params: preview ? { preview } : undefined,
    ...(noLoading? {headers: {noLoading: true}} : {})
  });

export const putArtist = async (data: any) =>
  await request(`/artist`, {
    type: "put",
    data,
  });

export const putArtistSection = async (data: any) =>
  await request(`/artist/section-settings`, {
    type: "post",
    data,
  });

export const artistChart = async (params: any) =>
  await request(`/artist/view-history`, {
    type: "get",
    params,
  });

export const artistOverView = async (params: any) =>
  await request(`/artist/overview`, {
    type: "get",
    params,
  });

export const deleteProject = async (id: number) =>
  await request(`/project/${id}`, {
    type: "delete",
  });

export const putProject = async (id: number, data: any) =>
  await request(`/project/${id}`, {
    type: "put",
    data,
  });

export const saveProject = async (data: any) =>
  await request(`/project`, {
    type: "post",
    data,
  });

export const getProject = async (id: string) =>
  await request(`/project/${id}`, {
    type: "get",
  });

export const savePayable = async (data: any) =>
  await request(`/artist/payable-settings`, {
    type: "post",
    data,
  });

export const getPayable = async () =>
  await request(`/artist/payable-settings`, {
    type: "get",
  });

export const reportArtist = async (id: number, reason: string) => 
  await request('/artist/report', { data: { id, reason }, type: 'post' })