//created by Pegg on 2021/1/26
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Progress } from "antd";
import styled from "styled-components";
import { timezoneHText } from "@/types/enum";

const dayjs = require("dayjs");

const relativeTime = require("dayjs/plugin/relativeTime");
const duration = require("dayjs/plugin/duration");
const utc = require("dayjs/plugin/utc")
const tz = require("dayjs/plugin/timezone")
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc)
dayjs.extend(tz)

const CrowdItemContainer = styled.div`
  p {
    margin-bottom: 0;
  }
`;

interface Props {
  time?: { start: string, end: string };
  timezone?: string;
  status?: boolean;
  donors?: number;
  goal?: number;
  raised?: number;
  to?: string;
  viewTo?: string;
}

export const formatDuration = (time: any, timezone: string, hideButton?: boolean) => {
  const { start, end } = time
  if (!(start && end)) {
    return { valid: false, date: '' }
  }
  const startDate = dayjs(start.replace(/(\.\d+)/, '')).format("MMMM DD ,  YYYY | hh:mm A ") + timezone;
  const endDate = dayjs(end.replace(/(\.\d+)/, '')).format("MMMM DD ,  YYYY | hh:mm A ") + timezone;

  const now = dayjs()
  const started = dayjs(start).isBefore(now)
  const notEnded = dayjs(end).isAfter(now)
  const valid = !hideButton && started && notEnded
  const date = started ? <><i>Campaign {notEnded ? 'ends at' : 'ended'} </i>{endDate}</> : <><i>Campaign opens </i>{startDate}</>
  return { valid, date }
};

const CrowdItem = ({
  donors = 0,
  raised = 0,
  goal = 0,
  time,
  timezone = 'EST',
  to = "",
  viewTo = "",
  status = true,
}: Props) => {
  console.log();
  const history = useHistory();
  const { edit } = useParams() as any;
  const { valid, date } = formatDuration(time, timezone, status)
  useEffect(() => { }, []);
  return (
    <CrowdItemContainer className="crowd-item-container">
      <p className="flex">
        <span>Raised</span>
        <span>Goal</span>
      </p>
      <p className="flex raised">
        <span>
          {Number(raised).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
          <i className="dm">By {donors} donors</i>
          <i className="mb">
            raised of{" "}
            {Number(goal).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}{" "}
            goal
          </i>
        </span>
        <span>
          {Number(goal).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })}
        </span>
      </p>
      <Progress
        strokeColor="#00FF6D"
        strokeLinecap="square"
        percent={goal ? Number((raised / goal) * 100) : 0}
        showInfo={false}
      />
      <p className="flex donate-item-wrap">
        <span className="flex">
          {valid && <Button
            type="primary"
            disabled={edit || !valid}
            onClick={() => history.push(to)}
          >
            Donate Now
          </Button>}
          <span className="donate-time" style={valid ? {} : { marginLeft: 0 }}>
            {date}
          </span>
        </span>
        {viewTo && (
          <span className="link" onClick={() => history.push(viewTo)}>
            {"view detail >"}
          </span>
        )}
      </p>
    </CrowdItemContainer>
  );
};

export default CrowdItem;
