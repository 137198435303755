//crated by pegg at 2020/10/08
// import fetch from '@/utils/fetch'
import request from "@/libs/fetch";

export const login = async (data: { email: string; password: string }) =>
  await request("/user/basic/sign-in", {
    data,
  });

export const loginFacebook = async (data: { userId: string; type: string }) =>
  await request("/user/facebook/sign-in", {
    data,
  });
export const loginGoogle = async (data: {
  email?: string;
  userId?: string;
  idToken: string;
}) =>
  await request("/user/google/sign-in", {
    data,
  });

export const signUp = async (data: {
  email: string;
  password: string;
  type?: string;
}) =>
  await request("/user/basic/sign-up", {
    data,
  });

export const signFacebook = async (data: { userId: string; type: string }) =>
  await request("/user/facebook/sign-up", {
    data,
  });
export const signGoogle = async (data: {
  email?: string;
  userId?: string;
  idToken: string;
  type: string;
}) =>
  await request("/user/google/sign-up", {
    data,
  });

export const getUserInfo = async () =>
  await request("/user/info", {
    type: "get",
  });

export const saveUserInfo = async (data: {
  primaryTag: string;
  tags: string[];
  progress: number;
  type: string;
}) =>
  await request("/user/info", {
    data,
    type: "post",
  });

export const changePassword = async (data: any) =>
  await request("/user/change-password", {
    data,
  });

export const resetPassword = async (data: any) =>
  await request("/user/reset-password", {
    data,
  });

export const deactiveAccount = async () =>
  await request("/user/deactive-account", { type: 'put' });

export const reactiveAccount = async () =>
  await request("/user/reactive-account", { type: 'put' });

export const forgetPassword = async (data: { email: string, admin?:boolean }) =>
  await request("/user/forget-password", {
    data,
  });

export const backToAdmin = async () => await request("/user/back-to-admin", {});

export const getDonor = async (id: string) =>
  await request(`donation/donor-info/${id}`, { type: "get" });
