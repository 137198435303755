import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Dropdown, Input, Menu, Select, Table, Tag } from "antd";
import dayjs from "dayjs";
import DatePicker from "@/components/DatePicker";
import { eventCrowdList } from "./../../../apis/event-api";
import { getEnv } from "@/libs/cfg";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import "./crowd.less";

import queryString from "query-string";
import { admin_router_message_to } from "@/adminApp/routers";
import { eventStatus } from "@/adminApp/pages/admin_activity/field_event/list";
import { capitalize } from "lodash";
import { useWindowSize } from "@/adminApp/models/model_admin_size";

const statusOption = ["live", "draft", "closed"].map((_) => ({
  label: capitalize(_),
  value: _,
}));

export const CrowdList = () => {
  const history = useHistory();

  //handler
  const handleExport = () => {
    const { page, limit, date = ["", ""], created, ...others } = params,
      [startTime, endTime] = date;
    const [createeStart, createdEnd] = created || ["", ""];
    window.open(
      `${process.env.REACT_APP_API_HOST
      }/crowdfunding/export-csv?${queryString.stringify({
        startTime,
        endTime,
        createdFromDate: (createeStart && dayjs(createeStart?.toString())?.format("YYYY-MM-DD")) || "",
        createdToDate: (createdEnd && dayjs(createdEnd?.toString())?.format("YYYY-MM-DD")) || "",
        ...others,
        exportId: selectedRows.map((item: any) => item.id).join(","),
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  // -view note

  const handleViewCampaign = (row: any) => {
    window.open(
      `${getEnv("WEB_HOST")}/home/donation/crowd/view/${row.id
      }/${row.name.replace(/[^\w]/g, "-")}`,
      "_blank"
    );
  };

  //handler end

  // -search
  const column: any[] = [
    {
      title: "Date created",
      fixed: "left",
      width: 130,
      dataIndex: "dateCreated",
      render: (text: string, _: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Campaign name",
      dataIndex: "name",
      width: 150,
      fixed: "left",
    },

    {
      title: "created by",
      width: 130,
      dataIndex: "createdBy",
    },
    {
      title: "first name",
      width: 120,
      dataIndex: "firstName",
    },
    {
      title: "Last name",
      width: 120,
      dataIndex: "lastName",
    },

    {
      title: "status",
      dataIndex: "status",
      className: "artist-cell-roles",
      render: (text: string, _: any) => (
        <Tag color={eventStatus[text] ? eventStatus[text]?.color : "gray"}>
          {eventStatus[text] ? eventStatus[text]?.text : text}
        </Tag>
      ),
    },
    {
      title: "Goals",
      dataIndex: "goals",
      render: (text: any, row: any) =>
        text
          ? Number(text).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })
          : "N/A",
    },
    {
      title: "Revenue",
      width: 100,
      dataIndex: "revenue",
      render: (text: any, row: any) =>
        text
          ? Number(text).toLocaleString("en", {
            style: "currency",
            currency: "USD",
          })
          : "$0.00",
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      render: (text: string, _: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },

    {
      title: "",
      width: 80,
      fixed: "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handleViewCampaign(row)}>
                  View Campaign
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    history.push(
                      admin_router_message_to.replace(":to", row.userId)
                    )
                  }
                >
                  Message user
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
  });

  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [windowHeight] = useWindowSize()

  const getList = () => {
    let { date, created, ...others } = params,
      [startTime, endTime] = date || ["", ""];
    const [createeStart, createdEnd] = created || ["", ""];
    eventCrowdList({
      startTime,
      endTime,
      createdFromDate: (createeStart && dayjs(createeStart?.toString())?.format("YYYY-MM-DD")) || "",
      createdToDate: (createdEnd && dayjs(createdEnd?.toString())?.format("YYYY-MM-DD")) || "",
      ...others,
    })
      .then(({ items, meta, ...others }: any) => {
        setTableData(items);
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="admin-artists-container admin-common-container">
      <h1>Artist Crowdfunding Campaigns</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start ">
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e,
                });
              }}
            />
          </div>
          <div className="range-picker-wrap field-form-items">
            <span>created</span>
            <DatePicker.RangePicker
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  created: e,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Campaign status</span>
            <Select
              allowClear
              options={statusOption}
              value={params?.userTag}
              placeholder="select..."
              bordered={false}
              onChange={(e) => {
                setSelectedRows([]);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  status: e,
                });
              }}
            />
          </div>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Campaigns</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) =>
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                })
              }
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        dataSource={tableData}
        columns={column}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content",y: windowHeight - 420 }}
      />
      {/* </div> */}
    </div>
  );
};
