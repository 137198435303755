import request from "@/libs/fetch";

export const getDocument = async (params: any) =>
  await request("/document", {
    type: "get",
    params,
  });

export const getUserContracts = async (id: number) =>
  await request("/document/contracts", {
    type: "get",
    params: { id },
  });

export const saveDocument = async (data: any) =>
  await request("/document", {
    type: "post",
    data,
  });

export const saveLetter = async (data: any) =>
  await request("/document/affiliation-letter", {
    type: "post",
    data,
  });

export const shareDoc = async (data: any) =>
  await request("/document/share", {
    type: "post",
    data,
  });
export const shareLink = async (data: any) =>
  await request("/document/share-link", {
    type: "post",
    data,
  });
export const deleteDocument = async (data: any) =>
  await request("/document/delete", {
    type: "post",
    data,
  });

export const downloadDocument = async (data: any) =>
  await request("/document/generate-temp-url", {
    type: "post",
    data,
  });
