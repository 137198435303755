//created by Peggy on 2021/1/28
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, message } from "antd";

import Back from "@/components/Back";
import Info from "@/components/Modal/Info";

import { forgetPassword } from "@/api/sign-api";

import { ReactComponent as LogoWhite } from "@/images/logo-white.svg";

import styled from "styled-components";
const RecoverContainer = styled.div``;

const Recover = ({admin = false}) => {
  const [show, setShow] = useState(false);
  const [input, setInput] = useState("");
  const handleSent = () => {
    const reg = /^[a-zA-Z0-9_\\-\\+\\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (!reg.test(input)) {
      message.error("invalid email");
      return false;
    }
    forgetPassword({
      email: input,
      admin,
    }).then(() => setShow(true));
  };
  return (
    <div className="sign-container sign-up-container password-container sign-in-container flex start align-start">
      <div className="left-wrap">
        <span>
          <LogoWhite />
        </span>
        <i className="copy-right">Ayiti Brass photo by Neha Guatam</i>
      </div>
      <RecoverContainer className="recover-container sign-up-wrap  flex column start">
        <div className="other-sign">
          <Back />
        </div>
        <h1>Recover Password </h1>
        <div className="sign-form">
          <h4>
            We will send you an email with instructions on how to reset your
            password.{" "}
          </h4>
          <div className="field-form-items">
            <span>Email</span>
            <Input
              value={input}
              onChange={(e) => {
                setInput(e.target.value.replace(/\s/g, ""));
              }}
              placeholder="Email"
              maxLength={50}
            />
          </div>
          <Button
            disabled={!input}
            onClick={handleSent}
            block
            type="primary"
            size="large"
          >
            Send recovery link
          </Button>
        </div>
      </RecoverContainer>
      <Info
        className="recover-info-modal"
        visible={show}
        footer={[
          <Link to="/sign/in">
            <Button type="primary">Return to sign in</Button>
          </Link>,
        ]}
      >
        <h1>Recover password</h1>
        <p>
          An email has been sent to your email address (<b>{input}</b>). Follow
          the directions in the email to reset your password.
        </p>
      </Info>
    </div>
  );
};

export default Recover;
