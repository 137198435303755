//created by Peggy on 2021/8/23
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

const CaptionContainer = styled.div`
  .text-caption {
    margin-top: 8px;
    font-size: 12px;
    
  }
  img {
    margin-bottom: 0 !important;
  }
`;

interface Props {
  src: string;
  className?: string;
  hideImage?: boolean;
}

const Caption = ({ src, className = "", hideImage = false }: Props) => {
  const [text, setText] = useState("");
  const [retry, setRetry] = useState(6)
  const getCaption = () => {
    if (src) {
      new ((window as any)?.AWS).S3()?.getObject(
        {
          Bucket: process.env.REACT_APP_AWS_BUCKET,
          Key: src?.split(`${process.env.REACT_APP_AWS_BUCKET}/`)[1],
        },
        (err: any, data: any) => {
          if (err) {
            if(err.retryable && retry >0){
              setRetry(retry - 1)
              getCaption()
            }
          } else {
            setText(data?.Metadata?.caption);
          }
        }
      );
    }
  };
  useEffect(() => {
    getCaption();
  }, [src]);
  return (
    <CaptionContainer
      className={`caption-container flex column start align-start ${className}`}
    >
      {!hideImage && <img src={src} />}
      {text && (
        <p className="text-caption">
          <>{text}</>
        </p>
      )}
    </CaptionContainer>
  );
};

export default Caption;
