import Request from "@/libs/fetch";

export const couponList = async (data?: any) =>
  await Request("/coupon/search", { data });

export const couponSave = async (data: any) =>
  await Request(`/coupon`, { data });

export const couponUpdate = async (id: string, data: any) =>
  await Request(`/coupon/${id}`, { type: "put", data });

export const couponDelete = async (id: string) =>
  await Request(`/coupon/${id}`, { type: "delete" });

export const couponGet = async (name: string) =>
  await Request(`/coupon/${name}`, { type: "get" });

export const couponTypeList = async () =>
  await Request(`/event/admin/thefield-shortlist`, { type: "get" });
