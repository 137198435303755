import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import useConfirm from "@/components/Modal/Confirm";
import {
  userList,
  userSuspend,
  userDelete,
  sendAdminInvitation,
  userUnsuspend,
} from "../../apis/user-api";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useWindowSize } from "@/adminApp/models/model_admin_size";
import { UserRole, UserStatus } from "@/types/enum";
import "./admin_admins.less";

export const roles = [
  {
    label: "Artist",
    value: "artist",
  },
  {
    label: "Donor",
    value: "donor",
  },
  {
    label: "Admin",
    value: "administrator",
  },
];

const statusMap: Record<UserStatus, { color: string; text: string }> = {
  [UserStatus.ACTIVE]: {
    color: "success",
    text: "Active",
  },
  [UserStatus.SUSPENDED]: {
    color: "error",
    text: "Suspended",
  },
  [UserStatus.INVITED]: {
    color: "processing",
    text: "Invitation sent",
  },
  [UserStatus.DEACTIVED]: {
    color: "error",
    text: "Deactived",
  },
  [UserStatus.DELETED]: {
    color: "error",
    text: "Deleted",
  },
};

export const AdminAdmins = () => {
  const history = useHistory();
  const [windowHeight] = useWindowSize();

  const { setShow: setSuspendShow, component: suspendModal } = useConfirm(
    (record: any) => (
      <div className="flex column start align-start">
        <p>{`You are suspending ${record.name ?? ""} <${
          record.email
        }> account. By clicking ‘Suspend’, you will:`}</p>
        <ul>
          <li>Suspend this account, and </li>
          <li>Disable this user from loging in </li>
          <li>Back up all content in the database.</li>
        </ul>
      </div>
    ),
    {
      title: "Are you sure you want to suspend this account ? ",
      className: "suspend-confirm-modal",
      okText: "suspend",
      onOk: (record?: any) => {
        userSuspend({
          id: record.id,
        }).then(() => {
          getList();
          message.success("Suspend successfully");
        });
      },
    }
  );

  const { setShow: setUnsuspendShow, component: unsuspendModal } = useConfirm(
    (record: any) => (
      <div className="flex column start align-start">
        <p>{`You are unsuspending ${record.name ?? ""} <${
          record.email
        }> account. By clicking ‘Unsuspend’, you will:`}</p>
        <ul>
          <li>Unsuspend this account, and</li>
          <li>Allow this user to log in</li>
          <li>Recover all content in the database as drafts.</li>
        </ul>
      </div>
    ),
    {
      title: "Are you sure you want to unsuspend this account ? ",
      className: "suspend-confirm-modal",
      okText: "unsuspend",
      onOk: (record?: any) => {
        userUnsuspend({
          id: record.id,
        }).then(() => {
          getList();
          message.success("Unsuspend successfully");
        });
      },
      width: 600,
    }
  );

  const { setShow: resendInvitationShow, component: resendInvitationModal } =
    useConfirm(
      (record: any) => (
        <div className="flex column start align-start">
          <p>
            You are sending the invitation to {record.email ?? ""} for the role
            of admin.
          </p>
        </div>
      ),
      {
        title: "Resend invitation",
        className: "suspend-confirm-modal",
        okText: "Confirm",
        onOk: (record?: any) => {
          sendAdminInvitation({
            id: record.id,
          }).then(() => {
            getList();
            message.success("Invitation sent successfully");
          });
        },
      }
    );

  const { setShow: deleteShow, component: deleteModal } = useConfirm(
    (record: any) => (
      <div className="flex column start align-start">
        <p>{`You are about to permantly delete ${record.name ?? ""} <${
          record.email
        }> account. By clicking ‘Delete’, you will:`}</p>
        <ul>
          <li>Delete this account</li>
          <li>Delete ALL content related to this account</li>
          <li>Disable this user from loging in </li>
        </ul>
      </div>
    ),
    {
      title: "Are you sure you want to permanently delete this account? ",
      className: "suspend-confirm-modal",
      okText: "Delete",
      onOk: (record?: any) => {
        userDelete({
          id: record.id,
        }).then(() => {
          getList();
          message.success("Delete successfully");
        });
      },
    }
  );

  const handleReset = () => {
    setParams({ page: 1, limit: 10, keyword: "" });
  };
  const handleResendInvitation = (row: any) => {
    resendInvitationShow(row);
  };
  const handleSuspend = (row: any) => {
    setSuspendShow(row);
  };
  const handleUnsuspend = (row: any) => {
    setUnsuspendShow(row);
  };
  const handleDelete = (row: any) => {
    deleteShow(row);
  };

  const column: any[] = [
    {
      title: "User name",
      width: 130,
      dataIndex: "name",
    },
    {
      title: "Email address",
      dataIndex: "email",
      width: 180,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: "Phone number",
      width: 150,
      dataIndex: "phone",
    },
    {
      title: "date created",
      dataIndex: "createDate",
      width: 130,
      key: "createDate",
      render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    },
    {
      title: (
        <>
          STATUS
          <Tooltip
            overlay={
              <div style={{ padding: 8 }}>
                Note: Expired accounts and crowdfunding campaigns may remain
                Active for 1-2 days after a failed payment attempt.
              </div>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      width: 110,
      key: "status",
      render: (text: UserStatus, row: any) => {
        const config = statusMap[text];
        return <Tag color={config.color}>{config.text}</Tag>;
      },
    },
    {
      title: "User role",
      width: 130,
      dataIndex: "roles",
      className: "artist-cell-roles",
      render: (text: any[], _: any) => {
        return text.includes(UserRole.SUPER_ADMIN) ? "Super Admin" : "Admin";
      },
    },
    {
      title: "",
      width: 80,
      align: "center",
      key: "action",
      fixed: "right",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlayStyle={{ zIndex: 5 }}
          overlay={() => (
            <>
              <Menu>
                {[UserStatus.INVITED].includes(row.status) && (
                  <Menu.Item
                    key="resend"
                    onClick={() => handleResendInvitation(row)}
                  >
                    Resend invitation
                  </Menu.Item>
                )}
                {UserStatus.INVITED !== row.status && (
                  <Menu.Item
                    key="edit"
                    onClick={() => history.push(`/admins/${row.id}`)}
                  >
                    Edit Admin
                  </Menu.Item>
                )}
                {UserStatus.SUSPENDED !== row.status && (
                  <Menu.Item key="suspend" onClick={() => handleSuspend(row)}>
                    Suspend account
                  </Menu.Item>
                )}
                {UserStatus.SUSPENDED === row.status && (
                  <Menu.Item
                    key="unsuspend"
                    onClick={() => handleUnsuspend(row)}
                  >
                    Unsuspend account
                  </Menu.Item>
                )}
                <Menu.Item key="delete" onClick={() => handleDelete(row)}>
                  Delete account
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
    note: "",
  });
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState<any[]>();
  const [, setSelectedRows] = useState<any>([]);
  const getList = () => {
    const { date, ...others } = params,
      [startDate, endDate] = date || [null, null];
    let _data = {
      startDate: startDate?.toDate(),
      endDate: endDate?.toDate(),
      role: ["administrator"],
      ...others,
    };
    userList(_data)
      .then((data) => {
        setTableData(data.items);
        setTotal(data.meta.totalItems);
      })
      .catch(() => {
        setTableData([]);
        setTotal(0);
      });
  };

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  return (
    <div className="admin-admin-container admin-common-container">
      <h1>Admins</h1>
      <div className="search-container flex">
        <div className="left-wrap flex start !flex-auto">
          {/* <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              value={params?.date}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  date: e,
                });
              }}
            />
          </div> */}
          <div className="field-form-items">
            <span>Status</span>
            <Select
              allowClear
              bordered={false}
              placeholder="All Status"
              mode="multiple"
              value={params?.status}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  status: e,
                });
              }}
            >
              <Select.Option key="active" value={UserStatus.ACTIVE}>
                Active
              </Select.Option>
              <Select.Option key="invited" value={UserStatus.INVITED}>
                Invitation sent
              </Select.Option>
              <Select.Option key="suspended" value={UserStatus.SUSPENDED}>
                Suspended
              </Select.Option>
            </Select>
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Button className="ml-auto" onClick={() => history.push("/admins/add")}>
          Add Admin
        </Button>
      </div>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Admins</span>
            <Input.Search
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (keys, rows) => {
            setSelectedRows(rows);
          },
        }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: "max-content",
          y: windowHeight - 420,
        }}
      />
      {suspendModal}
      {unsuspendModal}
      {deleteModal}
      {resendInvitationModal}
    </div>
  );
};
