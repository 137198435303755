//created by Peggy on 2021/2/16
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  Breadcrumb,
  Button,
  Divider,
  Dropdown,
  Input,
  Menu,
  Progress,
  Tag,
  Tooltip,
} from "antd";

import styled from "styled-components";
import dayjs from "dayjs";
import Search from "./Search";
import VirtualTable from "@/components/VirtualTable";
import GeneralFundModal from "@/pages/dashboard/donation/GeneralFundModal";

import { crowGet, crowedFundIncome } from "@/api/donation-api";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { formatDuration } from "@/pages/dashboard/income/CampaignCard";
import { paymentTypes, statusTypes, UserRole } from "@/types/enum";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import InKindTable from "@/pages/dashboard/donation/InkindTable";
import queryString from "query-string";
import { localStorageGet } from "@/utils/storage";
import { admin_router_crowd_field, admin_router_finance_contribution_order, admin_router_finance_contribution_order_note } from "@/adminApp/routers";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { NoTranslate } from "@/components/GoogleTranslate";

const OrderDetailContainer = styled.div``;

const CampaignDetail = () => {
  const history = useHistory();
  const { id } = useParams() as any;
  const [info, setInfo] = useState<any>("");
  const {
    store: { isMobile },
  } = useModel(view);
  const roles = localStorageGet("roles");
  const isAdmin = roles?.includes(UserRole.ADMIN);

  //campaign detail
  useEffect(() => {
    let _data = {
      id,
    };
    crowGet(_data).then((data) => {
      setInfo(data);
    });
  }, [id]);

  //table
  const column: any[] = [
    {
      title: "Donor",
      dataIndex: "donor",
      key: "name",
      fixed: "left",
      render: (text: string) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Donor email",
      dataIndex: "donorEmail",
      ellipsis: true,
      key: "email",
      render: (text: string) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: "Donation Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text: string, row: any) => {
        if(row.paymentMethod === 'check'){
          if(row.status === 'check_pending'){
            return 'N/A';
          }
          return dayjs(row.billDate || row.createDate).format("MM/DD/YYYY")
        }else{
          return dayjs(row.createDate).format("MM/DD/YYYY")
        }
      },
    },
    // {
    //   title: "received date",
    //   dataIndex: "createDate",
    //   key: "receivedDate",
    //   render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    // },
    {
      title: (
        <>
          STATUS
          <Tooltip
            overlay={
              <ul>
                <li>
                  Paid - Donation successful; payment to artist scheduled or
                  complete.
                </li>
                <li>
                  Pending - Donation initiated; verifying payment information.
                </li>
                <li>
                  Check Pending - Check entered online; will process upon
                  receipt.
                </li>
                <li>
                  Processing - Donation successful; payment to artist scheduled
                  for 10 days later.
                </li>
                <li>
                  Failed - Donor’s payment information incomplete or declined.
                </li>
              </ul>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      key: "status",
      render: (text: string, _: any) => (
        <Tag color={statusTypes[text ?? "processing"].color}>
          {statusTypes[text ?? "processing"].label}
        </Tag>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      width: 120,
      align: "right",
      key: "amount",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Fee",
      dataIndex: "fees",
      width: 80,
      align: "right",
      key: "fees",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Received Amount",
      dataIndex: "received",
      width: 120,
      align: "right",
      key: "received",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "",
      width: 80,
      align: "right",
      key: "action",
      render: (_: any, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() =>
                    history.push(isAdmin ? admin_router_finance_contribution_order.split(':')[0] + row.id + '?crowd=' + id : `/dashboard/income/detail/${row.id}`)
                  }
                >
                  View Order Detail
                </Menu.Item>
                <Menu.Item
                  hidden={row.status === 'failed'}
                  onClick={() =>
                    history.push(isAdmin ? admin_router_finance_contribution_order_note.replace(':id', row.id) + '?crowd=' + id : `/dashboard/income/detail/${row.id}/note`)
                  }
                >
                  View thank you note
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 25,
    keyword: "",
    status: Object.keys(statusTypes).filter(_ => _ !== 'failed'),
  });
  const [tableCount, setTableCount] = useState({
    total: 0,
    pageIndex: 0,
    pageTotal: 0,
    fees: 0,
    amount: 0,
    received: 0,
  });
  const [tableData, setTableData] = useState<any[]>([]);

  const onReset = () => {
    setTableData([]);
    setTableCount({
      total: 0,
      pageIndex: 0,
      pageTotal: 0,
      fees: 0,
      amount: 0,
      received: 0,
    });
  };
  const getList = (refresh?: boolean) => {
    const { range, startTime, endTime, ...others } = params,
      [startAmount, endAmount] = range || ["", ""],
      _data = {
        // type: "donation_to_artist,crowdfunding",
        ...others,
        startAmount,
        endAmount,
        startTime: startTime ? startTime.format('YYYY-MM-DD') : undefined,
        endTime: endTime ? endTime.format('YYYY-MM-DD') : undefined,
        id,
      };
    crowedFundIncome(_data).then((data: any) => {
      setTableData([...(refresh ? [] : tableData), ...(data?.items || [])]);
      setTableCount({
        ...tableCount,
        ...data?.summary,
        total: data?.meta?.totalItems || 0,
        pageIndex: data?.meta?.currentPage || 0,
        pageTotal: data?.meta?.totalPages || 0,
      });
    });
  };
  useEffect(() => {
    if (params) {
      getList();
    }
    // eslint-disable-next-line
  }, [params]);

  //Modal
  const [show, setShow] = useState(false);
  const handleExport = () => {
    const { range, startTime, endTime, ...others } = params,
      [startAmount, endAmount] = range || ["", ""],
      _data = {
        ...others,
        startAmount,
        endAmount,
        startTime: startTime ? startTime.format('YYYY-MM-DD') : undefined,
        endTime: endTime ? endTime.format('YYYY-MM-DD') : undefined,
      };
    window.open(
      `${process.env.REACT_APP_API_HOST
      }/crowdfunding/${id}/income-csv?${queryString.stringify({
        ..._data,
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  return (
    <OrderDetailContainer className="campaign-detail-container admin-common-container flex column start align-start">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href={isAdmin ? admin_router_crowd_field : "/dashboard/donation/list"}>
          Crowdfunding campaigns
        </Breadcrumb.Item>
        <Breadcrumb.Item>{info.name}</Breadcrumb.Item>
      </Breadcrumb>
      <h1 translate="no">{info.name}</h1>
      <div className="crow-wrap">
        <h4>Donation overview</h4>
        <div className="crow-info">
          {isMobile ? (
            <div className="flex start" translate="no">
              <h4>
                {Number(info?.currentAmount || 0).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })}
              </h4>{" "}
              Raised of{" "}
              {Number(info?.currentAmount || 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}{" "}
              goals by {info?.donateNumber} donor
            </div>
          ) : (
            <>
              <p className="flex">
                <span>raised</span>
                <span>goal</span>
              </p>
              <div className="flex" translate="no">
                <h2>
                  {Number(info?.currentAmount || 0).toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}{" "}
                  <i>By {info?.donateNumber} donor</i>
                </h2>
                <h2>
                  {" "}
                  {Number(info?.goalAmount || 0).toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                  })}
                </h2>
              </div>
            </>
          )}

          <Progress
            showInfo={false}
            strokeLinecap="square"
            percent={
              info?.goalAmount
                ? Number(
                  (Number(info?.currentAmount) / Number(info?.goalAmount)) *
                  100
                )
                : 0
            }
          />
          {dayjs() <= dayjs(info?.endDate) && (
            <p >
              Donation ends in <b translate="no"> {formatDuration(info?.endDate)}</b>
            </p>
          )}
        </div>
      </div>
      <Divider />
      <div className="table-wrap">
        <div className="flex title">
          <Search
            value={params}
            onChange={(e) => {
              // console.log(e)
              onReset();
              setParams({
                ...params,
                ...e,
              });
            }}
            hiddenDropdown
            custom={!isMobile &&
              <div style={{ marginLeft: 'auto' }}>
                <Button onClick={handleExport}>Export To CSV</Button>
                <Button style={{ marginLeft: '16px' }} onClick={() => setShow(true)}>Add Check Donation</Button>
              </div>
            }
          />
        </div>
        {
          isMobile && <div className="flex" style={{ marginBottom: '6px' }}>
            <Button onClick={handleExport} size='small'>Export CSV</Button>
            <Button style={{ marginLeft: '6px' }} size='small' onClick={() => setShow(true)}>Add Check</Button>
          </div>
        }
        {isMobile ? (
          <div className="table-item kind-table-item mb-24">
            <InKindTable
              title="Donation details"
              columns={column}
              tableData={tableData}
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  keyword: e,
                });
              }}
              pagination={{
                total: tableCount,
                current: params.page,
                pageSize: params.limit,
                onChange: (page: number, pageSize: number) => {
                  setParams({
                    ...params,
                    ...{
                      page,
                      limit: pageSize,
                    },
                  });
                },
              }}
              scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
            />
          </div>
        ) : (
          <div className="table-item">
            <div className="flex table-search-wrap">
              <span>Donation details</span>
              <Input.Search
                onSearch={(e) => {
                  setParams({
                    ...params,
                    keyword: e,
                  });
                }}
                placeholder="Search"
              />
            </div>
            <VirtualTable
              height={240}
              column={column}
              dataSource={tableData}
              tableCount={tableCount}
              pageChange={() => {
                setParams({
                  ...params,
                  page: params.page + 1,
                });
              }}
            />
          </div>
        )}
      </div>
      <GeneralFundModal
        crowId={+id}
        artistName={info?.artist?.displayName}
        crowName={info.name}
        show={show}
        onClose={(flag) => {
          setShow(false);
          if (flag) {
            getList(true)
          }
        }}
      />
    </OrderDetailContainer>
  );
};

export default CampaignDetail;
