//
import request from "@/libs/fetch";

export const eventListSearch = async (params: any) =>
  await request("/event/search", {
    type: "get",
    params,
  });

export const eventListLive = async () =>
  await request("/event/live", {
    type: "get",
  });

export const eventListPast = async (params: any) =>
  await request("/event/past", {
    params,
    type: "get",
  });

export const eventPublish = async (data: any) =>
  await request("/event/publish", {
    data,
  });

export const eventSave = async (data: any) =>
  await request("/event", {
    data,
  });

export const eventEditSave = async (data: any) =>
  await request("/event", {
    data,
    type: "put",
  });

export const eventDelete = async (data: any) =>
  await request(`/event/${data.id}`, {
    data,
    type: "delete",
  });

export const eventClose = async (data: any) =>
  await request(`/ event/close`, {
    data,
  });

export const eventPut = async (id: string | number, data: any) =>
  await request(`/event/${id}`, {
    data,
    type: "put",
  });
export const eventGet = async (id: string) =>
  await request(`/event/${id}`, {
    type: "get",
  });

export const crowedFundIncome = async ({ id }: any) =>
  await request(`/event/${id}/income`, {
    type: "get",
  });

export const incomeList = async (params: any) =>
  await request(`/income`, {
    params,
    type: "get",
  });

export const incomeDetail = async (params: any) =>
  await request(`/income/detail`, {
    params,
    type: "get",
  });

export const donationOverview = async () =>
  await request(`/income/overview`, {
    type: "get",
  });

export const InKindList = async (params: any) =>
  await request(`/income`, {
    params,
    type: "get",
  });

export const InKindAdd = async (data: any) =>
  await request(`/donation/in-kind`, {
    data,
  });

export const checkAdd = async (data: any) =>
  await request(`/donation/check`, {
    data,
  });

export const getBillInfo = async (params: any) =>
  await request("/donation/stripe-card-information", {
    params,
    type: "get",
  });

export const getStripeSecret = async (data: any) =>
  await request("/donation/by-stripe", {
    data,
  });

export const createPayPal = async (data: any) =>
  await request("/donation/create-paypal-transaction", {
    data,
  });

export const capturePayPal = async (data: any) =>
  await request("/donation/capture-paypal-transaction", {
    data,
  });
export const reportEvent = async (id: number, reason: string) => await request('/event/report', { data: { id, reason }, type: 'post' })
