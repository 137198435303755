import Request from "@/libs/fetch";

export const eventList = async (params?: any) =>
  await Request("/event/admin/search", { params, type: "get" });
export const deleteEvent = async (id: number) =>
  await Request("/event/" + id, { type: "delete" });
export const eventEditRegNum = async (data: { id: number, num: number }) =>
  await Request("/event/admin/regedit", { type: "post", data });

export const eventRegistration = async (params: any) =>
  await Request("/event/admin/registration", { type: "get", params });

export const eventUploadRegistration = async ({ id, file }: any) =>
  await Request("/event/admin/upload-registration", { type: "form", data: { id, file } });

export const eventOrderDuplicate = async (data: any) =>
  await Request("/event/admin/duplicate", { type: "post", data });

export const eventOrderDelete = async (data?: any) =>
  await Request("/event/admin/delete-order", { type: "post", data });

export const eventCrowdList = async (params?: any, noLoading?: boolean) =>
  await Request("/crowdfunding/search", { type: "get", params, ...(noLoading ? { headers: { noLoading: true } } : {}) });

  export const getCrowdList = async (params?: {user_id:number}, noLoading?: boolean) =>
  await Request("/crowdfunding/list", { type: "get", params, ...(noLoading ? { headers: { noLoading: true } } : {}) });
