import Request from "@/libs/fetch";

export const grantList = async (data: any) => {
  return await Request("/grant/search", {
    data,
    type: "post",
  });
};

export const grantListInArtist = async (data: any) => {
  return await Request("/grant/applications/my", {
    data,
    type: "post",
  });
};

export const grantApply = async (data: any) => {
  return await Request("/grant/apply", {
    data,
    type: "post",
  });
};

export const grantSolicitation = async (data: any) => {
  return await Request("/grant/solicitation", {
    data,
    type: "post",
  });
};

export const grantDetail = async (id: string) => {
  return await Request(`/grant/${id}`, {
    type: "get",
  });
};

export const grantApplicationDetail = async (id: string) => {
  return await Request(`/grant/application/${id}`, {
    type: "get",
  });
};

export const grantDelete = async (id: string) => {
  return await Request(`/grant/application/${id}`, {
    type: "delete",
  });
};

export const grantByStripe = async (data: any) => {
  return await Request("/grant/pay-by-stripe", {
    data,
    type: "post",
  });
};
export const getGrantNoteUnreadCount = async () => await Request('/grant/note/unread-count', { type: 'GET', headers: { noLoading: true } })

