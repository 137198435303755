import Password from "@/components/Password";
import { Input, Form, Button, Divider } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import { KV, useLoading, useModel } from "use-reaction";
import {
  model_admin_user,
  AdminLoginAction,
  getUserInfoAction,
} from "../models/model_admin_user";
import { admin_router_artists, admin_router_home, admin_router_sign_forget_pwd, admin_router_sign_in_2fa } from "../routers";
import qs from "query-string";
import "./admin_signin.less";
import { getToken } from "@/utils/storage";

export const AdminSignIn = (props: KV) => {
  const loading = useLoading();
  const history = useHistory();
  const { to } = qs.parse(history.location.search);
  const { doAction } = useModel(model_admin_user);
  const token = getToken()
  const onFinish = async (data: any) => {
    const res = await doAction(AdminLoginAction, data, "global");
    if (res && res.verify_sent) {
      history.push(admin_router_sign_in_2fa + (to ? `?to=${to}` : ''))
    } else if (res?.token) {
      history.push((to as string) || admin_router_artists);
      doAction(getUserInfoAction);
    }
  };
  return token? <Redirect to={admin_router_home}  /> : (
    <div className="admin-sign-container">
      <div className="admin-sign-in">
        <h1>Admin Sign In</h1>
        <div className="sign-form">
          <Form layout="vertical" size="large" name="sign" onFinish={onFinish}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  type: "email",
                  message: "Enter email address",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <div className="password-item">
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: "Enter password" }]}
              >
                <Password visibilityToggle={false} />
              </Form.Item>
            </div>

            <Form.Item className="no-style">
              <Button block type="primary" htmlType="submit" loading={loading}>
                Sign In
              </Button>
              <Link to={admin_router_sign_forget_pwd}>Forgot password?</Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
