//created by Peggy on 2021/3/5
import React from "react";
import {
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Tooltip,
} from "antd";
import DatePicker from "@/components/DatePicker";

import { InfoCircleFilled } from "@ant-design/icons";
import { checkAdd } from "@/api/donation-api";
import { stateList } from "@/types/enum";
import AmountInput from "@/components/Input/AmountInput";
import { useModel } from "use-reaction";
import { user } from "@/model/user";

interface Props {
  crowId?: number;
  crowName?: string;
  artistName?: string;
  show?: boolean;
  onClose?: (flag?: boolean) => void;
}

const GeneralFundModal = ({
  crowId,
  crowName = '',
  artistName = '',
  show = false,
  onClose = () => { },
}: Props) => {
  //Modal
  const { store: { info } } = useModel(user)
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { amount, checkNumber, date, ...others } = values;
      checkAdd({
        amount: amount?.replace(",", ""),
        checkNumber,
        date,
        donor: others,
        crowdfundingId: crowId,
      }).then((data) => {
        message.success("Check donation added successfully.");
        onCancel(true);
      });
    });
  };

  const onCancel = (flag?: boolean) => {
    form.resetFields();
    onClose(flag);
  };
  return (
    <Modal
      className="common-modal"
      title={
        <div className="title-wrap">
          Add check donation
          <Tooltip
            title={`All the check donation will be
            added to ${crowId ? 'Crowdfunding' : 'General'} donations.`}
          >
            <InfoCircleFilled />
          </Tooltip>
        </div>
      }
      visible={show}
      onOk={handleSubmit}
      okText="save"
      centered
      cancelText="cancel"
      onCancel={() => onCancel()}
      forceRender
    >
      <Form form={form} layout="vertical" name="form_fund_modal">
        <h2>Recipient information</h2>
        <Form.Item label="Artist">
          <Input value={artistName || info?.signInfo?.legalName || info?.displayName || `${info?.firstName} ${info?.lastName}`} disabled />
        </Form.Item>
        {
          crowId && <>
            <Checkbox checked style={{ marginBottom: '12px' }}>This check is for a crowdfunding campaign </Checkbox>
            <Form.Item label="crowdfunding campaign ">
              <Input value={crowName} disabled />
            </Form.Item>
          </>
        }
        <h2>Check information</h2>
        <Form.Item
          label="Check number"
          name="checkNumber"
          normalize={(e: string) =>
            e
              .replace(/[^\d]*/g, "")
              .replace(/^(\d{11})/, (_) => _.substr(0, 10) + '-' + _[10])
              .replace(/-(\d{8})/, (_) => _.substr(0, 8) + '-' + _[8])
          }
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Check number",
            },
          ]}
        >
          <Input maxLength={24} placeholder="Check number" />
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            {
              required: false,
              whitespace: false,
              message: "Amount",
            },
          ]}
        >
          <AmountInput placeholder="Enter amount" />
        </Form.Item>
        <Form.Item
          label="Donation date"
          name="date"
          rules={[
            {
              required: true,
              message: "Donation date",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Divider />
        <h2>Donor information</h2>
        <Form.Item
          label="DONOR FULL NAME"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "DONOR FULL NAME",
            },
          ]}
        >
          <Input maxLength={50} placeholder="First and last name" />
        </Form.Item>
        <Form.Item
          label="Donor public name"
          name="publicName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Donor public name",
            },
          ]}
        >
          <Input maxLength={50} placeholder="Donor public name" />
        </Form.Item>
        <Form.Item
          label="email address"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              whitespace: true,
              message: "email address",
            },
          ]}
        >
          <Input maxLength={50} placeholder="Email address" />
        </Form.Item>
        <Form.Item
          label="address"
          name="address"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Address",
            },
          ]}
        >
          <Input maxLength={200} placeholder="Address" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "City",
            },
          ]}
        >
          <Input placeholder="City" maxLength={30} />
        </Form.Item>
        <div className="flex">
          <Form.Item
            label="State"
            name="state"
            className="select-container"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "State",
              },
            ]}
          >
            <Select placeholder="State">
              {Object.values(stateList)?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Zip Code"
            name="zipCode"
            rules={[
              {
                required: true,
                whitespace: true,
                message: `Please enter the donor's 5-digit zip code`,
                validator: (_, value) => {
                  if(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)){
                    return Promise.resolve()
                  }else{
                    return Promise.reject()
                  }
                }
              }
            ]}
          >
            <Input placeholder="Zip Code" maxLength={5} />
          </Form.Item>
        </div>
        <p className="text-12">
          IMPORTANT: Please make sure to physically mail your check to us at:
          <div translate="no">The Field</div>
          <div translate="no">228 Park Ave S</div>
          <div translate="no">Suite 97217</div>
          <div translate="no">New York, NY 10003-1502.</div>
        </p>
      </Form>
    </Modal>
  );
};

export default GeneralFundModal;
