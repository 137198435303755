//created by Peggy on 2021/3/24
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  Select,
  Radio,
  message,
  Checkbox,
  Switch,
  Tooltip,
} from "antd";
import DatePicker from "@/components/DatePicker";
import { TimePicker } from "@/components/DatePicker";

import {
  QuestionCircleFilled,
  MinusCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";

import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import { useHistory, useParams } from "react-router-dom";
import { eventGet, eventPut, eventSave } from "@/api/event-api";

import { timezoneH } from "@/types/enum";
import { stateList } from "@/types/enum";
import { TextEditor } from "@/components/TextEditor/TextEditor";

import "./create.less";
import AmountInput from "@/components/Input/AmountInput";
import dayjs from "dayjs";
import { getQuery } from "@/utils/util";
// const utc = require("dayjs/plugin/utc");
// const timezones = require("dayjs/plugin/timezone");
// const dayjs = require("dayjs");
// dayjs.extend(utc);
// dayjs.extend(timezones);


const disabledDate = (current: any) => {
  return current && current < dayjs().endOf("day");
};

const CreateContainer = styled.div`
  .title{
    margin-top : 15px;
  }
  .ant-form-item-required {
    .ant-form-item-label label {
      &:after {
        display: inline-block;
        width: 12px;
        height: 12px;
        line-height: 12px;
        content: "*";
        color: #ff4d4f;
      }
    }
  }
  .ant-form-item.ant-form-image{
    border: none;
  }
  .ant-form-item.ant-form-item-has-error{
    border:1px solid #ff4d4f;
  }
  h4.ant-form-item-required {
    &:after {
      display: inline-block;
      width: 12px;
      height: 12px;
      line-height: 12px;
      margin-left: 4px;
      content: "*";
      color: #ff4d4f;
    }
  }
`;

const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'
const Create = () => {
  const history = useHistory();
  const { id } = useParams() as any;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(false);
  const [draft, setDraft] = useState(-1);
  const [status, setStatus] = useState("draft");
  const [isRequired, setRequired] = useState(false);
  const [info] = useState<any>({
    frequency: "one_time",
    locationType: "offline",
    customMsgType: "default"
  });
  const [purchase, setPurchase] = useState(0);
  const [isPrivate, setIsPrivate] = useState(false);

  const onFinish = (values: any) => {
    let {
      startDate,
      endDate,
      startTime,
      endTime,
      banner,
      thumbnail = [],
      frequency,
      timezone,
      repeatEvery,
      week,
      repeatOn,
      endAfter,
      re_startDate,
      re_endDate,
      re_startTime,
      re_endTime,
      re_timezone,
      price_model,
      customTierPricing,
      roleTierPricing,
      price,
      minimum,
      maximum,
      inventSwitch,
      inventory,
      inventType,
      displayPrice = "",
      locationType,
      locationName,
      address,
      city,
      state,
      zipCode,
      eventUrl,
      url,
      label,
      companyName,
      email,
      note,
      phone,
      documents,
      ...others
    } = values,
      _time =
        frequency === "one_time"
          ? {}
          : {
            repeatEvery: repeatEvery + " " + week,
            repeatOn,
            endAfter: endAfter + "",
          },
      _location =
        locationType === "online"
          ? {
            url:
              eventUrl.indexOf("http") === -1 && eventUrl.indexOf("www.") > -1
                ? "https://" + eventUrl
                : eventUrl,
          }
          : {
            name: locationName,
            address,
            city,
            state,
            zipCode,
          },
      pricing = {
        model: price_model,
        inventory: inventSwitch || !inventory ? -1 : inventory - 0,
        showTicketInventory: inventType?.includes("showTicketInventory"),
        showDisplayPrice: inventType?.includes("showDisplayPrice"),
        displayPrice,
        asYouWishPricing:
          price_model === "pay_as_you_wish" ? { price, minimum, maximum } : "",
        standardPricing: price_model === "standard" ? { price } : "",
        customTierPricing: customTierPricing?.map((item: any) => {
          item.inventory = item.inventory - 0;
          return item;
        }),
        roleTierPricing,
      };

    if (re_startDate && frequency === 'one_time' && dayjs(re_endDate || re_startDate).isAfter(dayjs(endDate || startDate))) {
      message.error('regist open date can not be after event end date')
      return
    }
    setLoading(true);
    let _data = {
      category: "the_field",
      ...others,
      thumbnail: thumbnail?.map((item: any) => item.url)?.join(","),
      banner: banner && banner?.map((item: any) => item.url)?.join(","),
      documents: documents && documents?.map((item: any) => item.url),
      time: {
        frequency,
        startDate: startDate ? startDate.format(dateFormat) : undefined,
        endDate: endDate?.format(dateFormat),
        timezone,
        startTime: startDate ? `${dayjs(startDate).format(dateFormat)}T` + (timeFormat ? startTime.format(timeFormat) : '') : undefined,
        endTime: endDate ? `${dayjs(endDate || startDate).format(dateFormat)} ` + (endTime ? endTime?.format(timeFormat) : '') : undefined,
        ..._time,
      },
      pricing,
      openDate: {
        startDate: re_startDate ? re_startDate.format(dateFormat) : undefined,
        endDate: re_endDate?.format(dateFormat),
        startTime: re_startTime ? `${dayjs(re_startDate).format(dateFormat)}T` + (timeFormat ? re_startTime.format(timeFormat) : '') : undefined,
        endTime: re_endDate ? `${dayjs(re_endDate || re_startDate).format(dateFormat)} ` + (re_endTime ? re_endTime?.format(timeFormat) : '') : undefined,
        timezone: re_timezone,
      },
      location: {
        type: locationType,
        ..._location,
      },
      button: {
        label,
        url: url?.indexOf("http") > -1 || !url ? url : "https://" + url,
      },
      contactInformation: {
        name: companyName,
        email,
        phone,
      },
      admin: {
        email,
        note,
      },
      private: isPrivate,
      status: "draft",
    };

    if (id > 0) {
      eventPut(id, _data)
        .then((data) => {
          if (draft === 1) {
            history.push(`/event/${id}/preview`);
          } else {
            history.push(`/program/field`);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      eventSave(_data)
        .then((data) => {
          if (draft === 1) {
            history.push(`/event/${data.id}/preview`);
          } else {
            history.push(`/program/field`);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  const handleSubmit = async (val: number) => {
    await setRequired(false);
    await setDraft(val);
    await form.submit();
  };

  const checkRequiredField = ()=>{
    setTimeout(() => {
      const checkField = getQuery('checkField')
      if(checkField === 'true'){
        setRequired(true)
        form.validateFields().catch(e => {
          const parent:any = document.querySelector(`.main-content`)
          const child:any = document.querySelector(`.main-content .ant-form-item-has-error`)
          if(child?.offsetTop){
            parent.scrollTo({
              top: child.offsetTop ,
              behavior: "smooth"
            })
          }
        })
      }  
    }, 500);
  }

  const onValuesChange = (e: any, all: any) => {
    if (
      ["eligibleUserRole", "price_model"].includes(Object.keys(e)[0]) &&
      all?.eligibleUserRole?.length &&
      all?.price_model
    ) {
      let _roleTierPricing = all?.eligibleUserRole?.map((item: any) => ({
        role: item,
        price: "",
      }));
      form.setFieldsValue({
        roleTierPricing: _roleTierPricing,
      });
    }
    setStatus("draft");
  };

  useEffect(() => {
    if (id > 0) {
      eventGet(id).then((data) => {
        const {
          eligibleUserRole = "",
          time: {
            endDate,
            endTime,
            startDate,
            startTime,
            repeatEvery = "1 week",
            ...otherTime
          },
          pricing: {
            model: price_model,
            asYouWishPricing,
            customTierPricing,
            roleTierPricing,
            standardPricing,
            inventory,
            showTicketInventory,
            showDisplayPrice,
            displayPrice,
            purchased,
          },
          openDate: {
            endDate: re_endDate,
            endTime: re_endTime,
            startDate: re_startDate,
            startTime: re_startTime,
            timezone: re_timezone,
          },
          location: { url: eventUrl, type, name, ...otherLocation },
          button,
          admin,
          banner,
          customMsg,
          thumbnail,
          documents,
          ...others
        } = data;
        const { name: companyName = "", ...otherInfo } =
          others.contactInformation || {},
          [repeatE = "1", week = "week"] = repeatEvery?.split(" ");
        // form.resetFields();

        const {
          minimum,
          maximum,
        } = asYouWishPricing || {}
        setIsPrivate(data.private)
        form.setFieldsValue({
          ...others,
          ...otherTime,
          ...button,
          ...(otherLocation || {}),
          ...admin,
          ...otherInfo,
          companyName,
          locationName: name,
          eventUrl,
          eligibleUserRole: !!eligibleUserRole ? eligibleUserRole : [],
          startTime: startTime ? dayjs(startTime) : "",
          endTime: endTime ? dayjs(endTime) : "",
          startDate: startDate ? dayjs(startDate) : "",
          endDate: endDate ? dayjs(endDate) : "",
          repeatEvery: repeatE,
          week,
          showEndItem: !!(endTime || endDate),
          showReEndItem: !!(re_endTime || re_endDate),
          // price_model,
          // ...asYouWishPricing,
          // ...standardPricing,
          asYouWishPricing,
          minimum,
          maximum,
          standardPricing,
          ...(price_model === 'standard' ? standardPricing : {}),
          customTierPricing: customTierPricing?.map((item: any) => {
            item.inventory = item?.inventory?.toString();
            return item;
          }),
          roleTierPricing,
          inventory: inventory === -1 ? "" : inventory?.toString(),
          inventType: [
            showTicketInventory ? "showTicketInventory" : "",
            showDisplayPrice ? "showDisplayPrice" : "",
          ].filter((item) => item),
          displayPrice,
          inventSwitch: inventory === -1,
          re_endDate: re_endDate ? dayjs(re_endDate) : "",
          re_endTime: re_endTime ? dayjs(re_endTime) : "",
          re_startDate: re_startDate ? dayjs(re_startDate) : "",
          re_startTime: re_startTime ? dayjs(re_startTime) : "",
          re_timezone,
          locationType: type,
          documents: (documents || []).map((item: any, index: number) => ({
            uid: index,
            name: item.split('/').pop(),
            status: "done",
            url: item,
          })),
          thumbnail: [thumbnail].map((item: any, index: number) => ({
            uid: index,
            name: item.replace(/[^\d]+/g, ""),
            status: "done",
            url: item,
          })),
          banner:
            (banner &&
              banner?.split(",")?.map((item: any, index: number) => ({
                uid: index,
                name: item.replace(/[^\d]+/g, ""),
                status: "done",
                url: item,
              }))) ||
            "",
          customMsgType: customMsg ? 'custom' : 'default',
          customMsg
        });
        setStatus(data.status);
        setPurchase(
          purchased ||
          customTierPricing?.reduce(
            (sum: number, item: any) => sum + item?.purchased,
            0
          )
        );
        setTimeout(() => {
          form.setFieldsValue({
            price_model,
          });
        }, 60);
        checkRequiredField();
      });
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <CreateContainer className="admin-event-create-container admin-common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/program/field">Events</Breadcrumb.Item>
        <Breadcrumb.Item>{id > 0 ? "Edit" : "Create"} an event</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex title">
        <h1>{id > 0 ? "Edit" : "Create"} an event</h1>
        <Radio.Group options={[ 
          { value: false, label: 'Public' }, 
          { value: true, label: 'Private' } 
        ]} value={isPrivate}
        onChange={(e)=>{
          setIsPrivate(e.target.value)
          setStatus("draft")
        }} buttonStyle="solid" size="large" optionType="button"/>
      </div>
      
      <p>
        To publish a grant opportunity, complete the form below. You will have
        the opportunity to review and edit this content before publishing.
      </p>
      <Form
        layout="vertical"
        name="campaign"
        form={form}
        initialValues={info}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <div className="event-form">
          <h2>Event information</h2>
          <Form.Item
            label="event name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter Event Name",
              },
            ]}
          >
            <Input maxLength={100} placeholder="Enter event name" />
          </Form.Item>
          <Form.Item
            className="textarea-wrap ant-form-item-required"
            label="event description"
            name="description"
            rules={[
              {
                required: isRequired,
                whitespace: false,
                message: "Enter event description",
              },
            ]}
          >
            <TextEditor placeholder="Enter event description" />
          </Form.Item>
          <Form.Item
            label="event type"
            className="ant-form-item-required"
            name="type"
            rules={[
              {
                required: isRequired,
                whitespace: false,
                message: "Select an event type",
              },
            ]}
          >
            <Select
              bordered={false}
              placeholder="Select an event type"
              getPopupContainer={() =>
                document.getElementsByClassName(
                  "admin-common-container"
                )[0] as any
              }
            >
              <Select.Option value="Development Events">
                Development Events
              </Select.Option>
              <Select.Option value="Professional Development">
                Professional Development
              </Select.Option>
              <Select.Option value="Life Preparedness">
                Life Preparedness
              </Select.Option>
              <Select.Option value="Thought Leadership">
                Thought Leadership
              </Select.Option>
              <Select.Option value="Third Party Event">
                Third Party Event
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="event presenter"
            className="ant-form-item-required"
            name="presenter"
            rules={[
              {
                required: isRequired,
                whitespace: true,
                message: "Enter event presenter",
              },
            ]}
          >
            <Input placeholder="Name(s), Organization 1; Name(s), Organization 2" />
          </Form.Item>
          <Form.Item
            label="Accessibility notice"
            name="accessibilityNotice"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                whitespace: true,
                message: "Enter accessibility notice",
              },
            ]}
          >
            <Input placeholder="Enter accessibility notice" />
          </Form.Item>
          <Form.Item noStyle dependencies={["type"]}>
            {({ getFieldValue }) =>
              getFieldValue("type") !== "Third Party Event" && (
                <>
                  <Form.Item
                    label="Eligible User Role"
                    name="eligibleUserRole"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: false,
                        type: "array",
                        message:
                          "Select applicable user roles (multiple choice)",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      mode="multiple"
                      placeholder="Select applicable user roles (multiple choice)"
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "admin-common-container"
                        )[0] as any
                      }
                    >
                      <Select.Option value="sponsored_artist">
                        Sponsored artist
                      </Select.Option>
                      <Select.Option value="free_artist">
                        Free artist
                      </Select.Option>
                      <Select.Option value="donor">Donor</Select.Option>
                      <Select.Option value="guest">Guest</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="ticket model"
                    name="ticketModel"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: false,
                        message: "Select a model",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      placeholder="Select a model"
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "admin-common-container"
                        )[0] as any
                      }
                    >
                      <Select.Option value="single">Single</Select.Option>
                      <Select.Option value="multiple">Multiple</Select.Option>
                    </Select>
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Divider />
          <h2>Time</h2>
          <Form.Item className="radio-item" name="frequency" label="Frequency">
            <Radio.Group>
              <Radio value="one_time">One-time event</Radio>
              <Radio value="recurring">Recurring event</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.frequency !== curValues.frequency
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("frequency") !== "one_time" && (
                <>
                  <div className="flex start time-option-wrap ">
                    <span>Repeat every</span>
                    <Form.Item noStyle name="repeatEvery">
                      <Select
                        placeholder="Select"
                        size="large"
                        style={{ width: 120 }}
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "admin-common-container"
                          )[0] as any
                        }
                      >
                        {new Array(365).fill(1).map((item, index) => (
                          <Select.Option key={index} value={index - 0 + 1}>
                            {index + 1}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="week">
                      <Select
                        placeholder="Select"
                        size="large"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "admin-common-container"
                          )[0] as any
                        }
                      >
                        <Select.Option value="week">Week</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="flex start time-option-wrap repeat-wrap">
                    <span>Repeat on</span>
                    <Form.Item noStyle name="repeatOn" label="">
                      <Radio.Group defaultValue="a">
                        <Radio.Button value="Monday">Monday</Radio.Button>
                        <Radio.Button value="Tuesday">Tuesday</Radio.Button>
                        <Radio.Button value="Wednesday">Wednesday</Radio.Button>
                        <Radio.Button value="Thursday">Thursday</Radio.Button>
                        <Radio.Button value="Friday">Friday</Radio.Button>
                        <Radio.Button value="Saturday">Saturday</Radio.Button>
                        <Radio.Button value="Sunday">Sunday</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <div className="flex start time-option-wrap">
                    <span>End after</span>
                    <Form.Item
                      noStyle
                      className="time-option-wrap"
                      name="endAfter"
                      label=""
                    >
                      <Select
                        size="large"
                        placeholder="Select"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "admin-common-container"
                          )[0] as any
                        }
                      >
                        {new Array(100).fill(1).map((item, index) => (
                          <Select.Option key={index} value={index - 0 + 1}>
                            {index + 1} occurrences
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </>
              )
            }
          </Form.Item>
          <div className="flex date-item">
            <Form.Item
              label="Start date"
              name="startDate"
              className="ant-form-item-required"
              rules={[
                {
                  required: isRequired,
                  message: "Enter start date",
                },
              ]}
            >
              <DatePicker
                placeholder="Enter start date"
                dropdownClassName="date-picker-wrap"
                disabledDate={disabledDate}
                format={dateFormat}
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "admin-common-container"
                  )[0] as any
                }
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.showEndItem !== curValues.showEndItem ||
                prevValues.frequency !== curValues.frequency
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("showEndItem") && (
                  <Form.Item
                    label="end date"
                    name="endDate"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: false,
                        message: "Select end date",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || !getFieldValue("startDate")) {
                            return Promise.resolve();
                          } else if (
                            dayjs(value) < dayjs(getFieldValue("startDate"))
                          ) {
                            return Promise.reject(
                              new Error(
                                "The start date should be less than the end date !"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select end date"
                      dropdownClassName="date-picker-wrap"
                      disabledDate={disabledDate}
                      format={dateFormat}
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "admin-common-container"
                        )[0] as any
                      }
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </div>
          <Form.Item
            name="timezone"
            label="Select timezone"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                message: "Select timezone",
              },
            ]}
          >
            <Select
              bordered={false}
              showSearch
              placeholder="Select timezone"
              getPopupContainer={() =>
                document.getElementsByClassName(
                  "admin-common-container"
                )[0] as any
              }
            >
              {timezoneH.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex date-item">
            <Form.Item
              label="start time"
              name="startTime"
              className="ant-form-item-required"
              rules={[
                {
                  required: isRequired,
                  message: "Select start name",
                },
              ]}
            >
              <TimePicker
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "admin-common-container"
                  )[0] as any
                }
                use12Hours
                format="h:mm A"
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.showEndItem !== curValues.showEndItem ||
                prevValues.frequency !== curValues.frequency
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("showEndItem") && (
                  <Form.Item
                    label="end time"
                    name="endTime"
                    className="ant-form-item-required"
                    dependencies={["startDate", "endDate", "startTime"]}
                    rules={[
                      {
                        required: isRequired,
                        message: "Select end Time",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || !getFieldValue("startTime") || !getFieldValue("endTime")) {
                            return Promise.resolve();
                          } else if (
                            getFieldValue("startDate") &&
                            value &&
                            getFieldValue("startTime") &&
                            dayjs(
                              (
                                getFieldValue("endDate") ||
                                getFieldValue("startDate")
                              )?.format(dateFormat) +
                              " " +
                              dayjs(value).format(timeFormat)
                            ) <
                            dayjs(
                              getFieldValue("startDate")?.format(
                                dateFormat
                              ) +
                              " " +
                              getFieldValue("startTime")?.format(timeFormat)
                            )
                          ) {
                            return Promise.reject(
                              new Error(
                                "The start time should be less than the end time !"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TimePicker
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "admin-common-container"
                        )[0] as any
                      }
                      use12Hours
                      format="h:mm A"
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </div>

          <Form.Item name="showEndItem" valuePropName="checked" noStyle>
            <Checkbox>Show end date & end time</Checkbox>
          </Form.Item>
          <Divider />
          <h2>Registration</h2>
          <Form.Item noStyle dependencies={["type"]}>
            {({ getFieldValue }) =>
              getFieldValue("type") !== "Third Party Event" && (
                <>
                  <h3>Open date</h3>
                  <div className="flex">
                    <Form.Item
                      label="start date"
                      name="re_startDate"
                      className="ant-form-item-required"
                      rules={[
                        {
                          required: isRequired,
                          message: "Select start date",
                        },
                      ]}
                    >
                      <DatePicker
                        dropdownClassName="date-picker-wrap"
                        format={dateFormat}
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "admin-common-container"
                          )[0] as any
                        }
                      />
                    </Form.Item>
                    <Form.Item noStyle dependencies={["showReEndItem"]}>
                      {({ getFieldValue }) =>
                        getFieldValue("showReEndItem") && (
                          <Form.Item
                            label="end date"
                            className="ant-form-item-required"
                            name="re_endDate"
                            dependencies={["re_startDate"]}
                            rules={[
                              {
                                required: false,
                                message: "Select end date",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    !getFieldValue("re_startDate")
                                  ) {
                                    return Promise.resolve();
                                  } else if (
                                    dayjs(value) <
                                    dayjs(getFieldValue("re_startDate"))
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "The start date should be less than the end date !"
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <DatePicker
                              placeholder="Select end date"
                              dropdownClassName="date-picker-wrap"
                              format={dateFormat}
                              getPopupContainer={() =>
                                document.getElementsByClassName(
                                  "admin-common-container"
                                )[0] as any
                              }
                            />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="re_timezone"
                    label="Select timezone"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        message: "Select timezone",
                      },
                    ]}
                  >
                    <Select
                      bordered={false}
                      showSearch
                      placeholder="Select timezone"
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "admin-common-container"
                        )[0] as any
                      }
                    >
                      {timezoneH.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="flex">
                    <Form.Item
                      label="start time"
                      name="re_startTime"
                      className="ant-form-item-required"
                      rules={[
                        {
                          required: isRequired,
                          message: "Enter start name",
                        },
                      ]}
                    >
                      <TimePicker
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "admin-common-container"
                          )[0] as any
                        }
                        use12Hours
                        format="h:mm A"
                      />
                    </Form.Item>
                    <Form.Item noStyle dependencies={["showReEndItem"]}>
                      {({ getFieldValue }) =>
                        getFieldValue("showReEndItem") && (
                          <Form.Item
                            label="end time"
                            name="re_endTime"
                            className="ant-form-item-required"
                            dependencies={[
                              "re_startDate",
                              "re_endDate",
                              "re_startTime",
                            ]}
                            rules={[
                              {
                                required: isRequired,
                                message: "Select end Time",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    !getFieldValue("re_startTime")
                                  ) {
                                    return Promise.resolve();
                                  } else if (
                                    getFieldValue("re_startDate") &&
                                    value &&
                                    getFieldValue("re_startTime") &&
                                    dayjs(
                                      (
                                        getFieldValue("re_endDate") ||
                                        getFieldValue("re_startDate")
                                      )?.format(dateFormat) +
                                      " " +
                                      dayjs(value).format(timeFormat)
                                    ) <
                                    dayjs(
                                      getFieldValue("re_startDate")?.format(
                                        dateFormat
                                      ) +
                                      " " +
                                      getFieldValue("re_startTime")?.format(
                                        timeFormat
                                      )
                                    )
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "The start time should be less than the end time !"
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <TimePicker
                              getPopupContainer={() =>
                                document.getElementsByClassName(
                                  "admin-common-container"
                                )[0] as any
                              }
                              use12Hours
                              format="h:mm A"
                            />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </div>
                  <Form.Item
                    name="showReEndItem"
                    valuePropName="checked"
                    noStyle
                  >
                    <Checkbox style={{ marginBottom: 32 }}>
                      Show end date & end time
                    </Checkbox>
                  </Form.Item>
                </>
              )
            }
          </Form.Item>

          <h3>Call to action button</h3>
          <Form.Item noStyle dependencies={["type"]}>
            {({ getFieldValue }) =>
              getFieldValue("type") === "Third Party Event" && (
                <Form.Item
                  label="button url"
                  name="url"
                  className="ant-form-item-required"
                  rules={[
                    {
                      required: isRequired,
                      pattern: /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                      message: "button url",
                    },
                  ]}
                >
                  <Input maxLength={100} placeholder="Insert the url where we can learn more about your event or buy tickets" />
                </Form.Item>
              )
            }
          </Form.Item>
          <Form.Item
            label="button label"
            name="label"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                whitespace: true,
                message: "button label",
              },
            ]}
          >
            <Input maxLength={30} placeholder={`What is your Call-to-Action? For example "Learn More", "Buy Tickets", etc`} />
          </Form.Item>
          <Divider />
          <Form.Item noStyle dependencies={["type"]}>
            {({ getFieldValue }) =>
              getFieldValue("type") === "Third Party Event" && (
                <>
                  <h2>Contact information</h2>
                  <Form.Item
                    label="contact name"
                    name="companyName"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: true,
                        message: "contact name",
                      },
                    ]}
                  >
                    <Input maxLength={30} placeholder="contact name" />
                  </Form.Item>
                  <Form.Item
                    label="Email address"
                    name="email"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        type: "email",
                        whitespace: true,
                        message: "email invalid",
                      },
                    ]}
                  >
                    <Input placeholder="Email address" />
                  </Form.Item>
                  <Form.Item
                    label="Phone number"
                    name="phone"
                    className="ant-form-item-required"
                    normalize={(e) => {
                      return e
                        .replace(/[^\d]*/g, "")
                        .replace(/(\d{3})(?=\d{2})/g, "$1-");
                    }}
                    rules={[
                      {
                        required: isRequired,
                        message: "Enter phone number",
                      },
                    ]}
                  >
                    <Input maxLength={12} placeholder="Enter phone number" />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>

          <h2>Pricing</h2>
          <Form.Item noStyle dependencies={["type"]}>
            {({ getFieldValue }) =>
              getFieldValue("type") !== "Third Party Event" && (
                <>
                  <h3>Detail</h3>
                  <Form.Item
                    label="price model"
                    name="price_model"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: false,
                        message: "Select a model",
                      },
                    ]}
                  >
                    <Select
                      disabled={id && purchase > 0}
                      bordered={false}
                      placeholder="Select a model"
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "admin-common-container"
                        )[0] as any
                      }
                    >
                      <Select.Option value="pay_as_you_wish">
                        Pay as you wish
                      </Select.Option>
                      <Select.Option value="standard">
                        Standard pricing
                      </Select.Option>
                      <Select.Option value="role_tier">
                        User role tier pricing
                      </Select.Option>
                      <Select.Option value="custom_tier">
                        Custom tier pricing
                      </Select.Option>
                      <Select.Option value="free">Free</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      if (getFieldValue("price_model") === "pay_as_you_wish") {
                        return (
                          <>
                            <div className="flex">
                              <Form.Item
                                label="Minimum Price"
                                name="minimum"
                                rules={[
                                  {
                                    required: true,
                                    whitespace: false,
                                    message: "price",
                                  },
                                ]}
                              >
                                <AmountInput placeholder="0.00" />
                              </Form.Item>
                              {/*<Form.Item*/}
                              {/*  label="Maximum Price"*/}
                              {/*  name="maximum"*/}
                              {/*  rules={[*/}
                              {/*    {*/}
                              {/*      required: true,*/}
                              {/*      whitespace: false,*/}
                              {/*      message: "price",*/}
                              {/*    },*/}
                              {/*    {*/}
                              {/*      validator: (_, value) => {*/}
                              {/*        if (!value || !getFieldValue("minimum")) {*/}
                              {/*          return Promise.resolve();*/}
                              {/*        } else if (*/}
                              {/*          Number(value) < Number(getFieldValue("minimum"))*/}
                              {/*        ) {*/}
                              {/*          return Promise.reject(*/}
                              {/*            new Error(*/}
                              {/*              "The minimum Price should be less than the maximum price !"*/}
                              {/*            )*/}
                              {/*          );*/}
                              {/*        }*/}
                              {/*        return Promise.resolve();*/}
                              {/*      },*/}
                              {/*    },*/}
                              {/*  ]}*/}
                              {/*>*/}
                              {/*  <AmountInput placeholder="0.00" />*/}
                              {/*</Form.Item>*/}
                            </div>
                          </>
                        );
                      } else if (getFieldValue("price_model") === "standard") {
                        return (
                          <Form.Item
                            label="Price per uniT"
                            name="price"
                            rules={[
                              {
                                required: true,
                                whitespace: false,
                                message: "price",
                              },
                            ]}
                          >
                            <AmountInput placeholder="0.00" />
                          </Form.Item>
                        );
                      } else if (getFieldValue("price_model") === "role_tier") {
                        return (
                          <Form.List name="roleTierPricing">
                            {(fields, { add, remove }) => (
                              <div>
                                {fields.map((field) => {
                                  if (getFieldValue("eligibleUserRole")) {
                                    return (
                                      <div key={field.key}>
                                        <h2 className="flex">
                                          User Role {field.name + 1}
                                          {/*<MinusCircleOutlined*/}
                                          {/*  onClick={() => remove(field.name)}*/}
                                          {/*/>*/}
                                        </h2>
                                        <div className="flex">
                                          <Form.Item
                                            label="role"
                                            name={[field.name, "role"]}
                                            fieldKey={[field.fieldKey, "role"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing title",
                                              },
                                              {
                                                validator: (_, value) => {
                                                  if (
                                                    !getFieldValue(
                                                      "eligibleUserRole"
                                                    ).includes(value)
                                                  ) {
                                                    return Promise.reject(
                                                      new Error(
                                                        "please re-change this role"
                                                      )
                                                    );
                                                  }
                                                  return Promise.resolve();
                                                },
                                              },
                                            ]}
                                          >
                                            <Select
                                              bordered={false}
                                              disabled
                                              placeholder="Select an event type"
                                              getPopupContainer={() =>
                                                document.getElementsByClassName(
                                                  "admin-common-container"
                                                )[0] as any
                                              }
                                            >
                                              <Select.Option value="sponsored_artist">
                                                Sponsored artist
                                              </Select.Option>
                                              <Select.Option value="free_artist">
                                                Free artist
                                              </Select.Option>
                                              <Select.Option value="donor">
                                                Donor
                                              </Select.Option>
                                              <Select.Option value="guest">
                                                Guest
                                              </Select.Option>
                                            </Select>
                                          </Form.Item>
                                          <Form.Item
                                            label="price per unit"
                                            name={[field.name, "price"]}
                                            fieldKey={[field.fieldKey, "price"]}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Missing price",
                                              },
                                            ]}
                                          >
                                            <AmountInput placeholder="0.00" />
                                          </Form.Item>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                                {fields?.length < 4 &&
                                  getFieldValue("eligibleUserRole") &&
                                  fields?.length <
                                  getFieldValue("eligibleUserRole")
                                    ?.length && (
                                    <Form.Item noStyle>
                                      {/*<Button*/}
                                      {/*  style={{ marginBottom: 32 }}*/}
                                      {/*  onClick={() => add()}*/}
                                      {/*>*/}
                                      {/*  Add new Tier*/}
                                      {/*</Button>*/}
                                    </Form.Item>
                                  )}
                              </div>
                            )}
                          </Form.List>
                        );
                      } else if (
                        getFieldValue("price_model") === "custom_tier"
                      ) {
                        return (
                          <Form.List name="customTierPricing">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map((field, index) => (
                                  <div key={field.key}>
                                    <h2 className="flex">
                                      Tier {index + 1}
                                      {!(
                                        id &&
                                        getFieldValue("customTierPricing")[
                                          index
                                        ]?.purchased > 0
                                      ) && (
                                          <MinusCircleOutlined
                                            onClick={() => remove(field.name)}
                                          />
                                        )}
                                    </h2>

                                    <Form.Item
                                      label="Tier modal"
                                      name={[field.name, "name"]}
                                      fieldKey={[field.fieldKey, "name"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing name",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter tier modal" />
                                    </Form.Item>
                                    <div className="flex">
                                      <Form.Item
                                        label="price per unit"
                                        name={[field.name, "price"]}
                                        fieldKey={[field.fieldKey, "price"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing price",
                                          },
                                        ]}
                                      >
                                        <AmountInput placeholder="0.00" />
                                      </Form.Item>
                                      <Form.Item
                                        label="Inventory"
                                        className="ant-form-item-required"
                                        name={[field.name, "inventory"]}
                                        fieldKey={[field.fieldKey, "inventory"]}
                                        normalize={(e) =>
                                          e.replace(/[^\d]+/, "")
                                        }
                                        rules={[
                                          {
                                            required: isRequired,
                                            whitespace: true,
                                            message: "Enter inventory",
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (
                                                value <
                                                getFieldValue(
                                                  "customTierPricing"
                                                )[index]?.purchased &&
                                                !getFieldValue("inventSwitch")
                                              ) {
                                                return Promise.reject(
                                                  `The inventory needs to be greater than the number of tickets sold, please try again.`
                                                );
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                      >
                                        <Input placeholder="Enter inventory" />
                                      </Form.Item>
                                    </div>
                                  </div>
                                ))}

                                <Form.Item noStyle>
                                  <Button
                                    style={{ marginBottom: 32 }}
                                    onClick={() => add()}
                                  >
                                    Add new Tier
                                  </Button>
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        );
                      }
                    }}
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                      getFieldValue("price_model") !== "custom_tier" && (
                        <div className="flex">
                          <h3>Inventory</h3>
                          <div className="switch-item flex">
                            <span>Unlimited</span>
                            <Form.Item
                              noStyle
                              name="inventSwitch"
                              valuePropName="checked"
                              initialValue={false}
                            >
                              <Switch />
                            </Form.Item>
                          </div>
                        </div>
                      )
                    }
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.inventSwitch !== curValues.inventSwitch ||
                      prevValues.price_model !== curValues.price_model
                    }
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("price_model") !== "custom_tier" && (
                        <Form.Item
                          label="Inventory"
                          name="inventory"
                          className={
                            getFieldValue("inventSwitch")
                              ? "form-item-disabled"
                              : "ant-form-item-required"
                          }
                          normalize={(e) => e.replace(/[^\d]+/, "")}
                          rules={[
                            {
                              required:
                                isRequired && !getFieldValue("inventSwitch"),
                              whitespace: true,
                              message: "Enter inventory",
                            },
                            {
                              validator: (_, value) => {
                                if (
                                  value < purchase &&
                                  !getFieldValue("inventSwitch")
                                ) {
                                  return Promise.reject(
                                    `The inventory needs to be greater than the number of tickets sold, please try again.`
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter inventory"
                            disabled={getFieldValue("inventSwitch")}
                          />
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <Form.Item noStyle dependencies={["type"]}>
            {({ getFieldValue }) => (
              <Form.Item
                className="radio-item"
                name="inventType"
                label="Display option"
              >
                <Checkbox.Group
                  options={
                    getFieldValue("type") !== "Third Party Event"
                      ? [
                        {
                          label: "Show ticket inventory",
                          value: "showTicketInventory",
                        },
                        {
                          label: "Show Display Price",
                          value: "showDisplayPrice",
                        },
                      ]
                      : [
                        {
                          label: "Show Display Price",
                          value: "showDisplayPrice",
                        },
                      ]
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            className="price-item"
            label="Display Price"
            name="displayPrice"
            dependencies={["inventType"]}
            rules={[
              ({ getFieldValue }) => ({
                required: getFieldValue("inventType")?.includes(
                  "showDisplayPrice"
                ),
                whitespace: true,
                message: "price",
              }),
            ]}
          >
            <Input placeholder="Input as you wish" />
          </Form.Item>
          <p>This price will be display on the banner of event</p>
          <Divider />
          <h2>Location</h2>
          <Form.Item
            className="radio-item"
            name="locationType"
            label="Location type"
          >
            <Radio.Group>
              <Radio value="offline">in person event</Radio>
              <Radio value="online">Online event</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.locationType !== curValues.locationType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("locationType") === "online" ? (
                <Form.Item
                  label="Event url"
                  name="eventUrl"
                  className="ant-form-item-required"
                  rules={[
                    {
                      required: isRequired,
                      // pattern: /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                      message: "event url",
                    },
                  ]}
                >
                  <Input placeholder="Enter event url" />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    label="location name"
                    name="locationName"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: true,
                        message: "location name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter location name" />
                  </Form.Item>
                  <Form.Item
                    label="address"
                    name="address"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: true,
                        message: "Address",
                      },
                    ]}
                  >
                    <Input placeholder="Enter address" />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="city"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: true,
                        message: "City",
                      },
                    ]}
                  >
                    <Input placeholder="Enter City" maxLength={30} />
                  </Form.Item>
                  <div className="flex">
                    <Form.Item
                      label="State"
                      name="state"
                      className="select-container ant-form-item-required"
                      id="select-container"
                      rules={[
                        {
                          required: isRequired,
                          whitespace: true,
                          message: "State",
                        },
                      ]}
                    >
                      <Select
                        bordered={false}
                        placeholder="Select a state"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "admin-common-container"
                          )[0] as any
                        }
                      >
                        {Object.values(stateList)?.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Zip Code"
                      name="zipCode"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                          message: "Zip Code",
                        },
                      ]}
                    >
                      <Input placeholder="Enter Zip Code" maxLength={5} />
                    </Form.Item>
                  </div>
                </>
              )
            }
          </Form.Item>
          <Divider />
          <h2>Registration Message</h2>
          <Form.Item
            className="radio-item"
            name="customMsgType"
            label="Registration Message type"
          >
            <Radio.Group>
              <Radio value="default">Default</Radio>
              <Radio value="custom">Custom message</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.customMsgType !== curValues.customMsgType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("customMsgType") === "custom" ? (
                <Form.Item
                  label="Custom message"
                  name="customMsg"
                  className="ant-form-item-required textarea-wrap"
                  rules={[
                    {
                      required: true,
                      message: "custom message",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter custom message" />
                </Form.Item>
              ) : null}
              </Form.Item>
          <Divider />
          <Form.Item noStyle dependencies={["type"]}>
            {({ getFieldValue }) =>
              getFieldValue("type") !== "Third Party Event" && (
                <>
                  <h2>Email notification</h2>
                  <Form.Item
                    label="contact name"
                    name="companyName"
                  >
                    <Input maxLength={30} placeholder="contact name" />
                  </Form.Item>
                  <Form.Item
                    label="Add Emails "
                    name="email"
                    rules={[
                      {
                        required: false,
                        whitespace: true,
                        message: "email invalid",
                      },
                    ]}
                  >
                    <Input placeholder="Enter email addresses, separate by commas. These emails will receive updates.  " />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
          <h2>Admin Note</h2>
          <Form.Item
            className="textarea-wrap"
            label="Admin Note"
            name="note"
            rules={[
              {
                required: false,
                whitespace: false,
                message: "Enter event description",
              },
            ]}
          >
            <Input.TextArea placeholder="Admin notes are not visible to the public, but should be searchable in admin reports." />
          </Form.Item>
          <h2>Event media</h2>
          <h4 className="ant-form-item-required">
            Cover / Thumbnail photo{" "}
            <Tooltip
              className="tooltip-field-item"
              title="The thumbnail photo will appear in  the  Artist Events/Community Calendar page."
            >
              <QuestionCircleFilled />
            </Tooltip>
          </h4>
          <Form.Item
            label=""
            name="thumbnail"
            className="ant-form-image"
            rules={[
              {
                required: isRequired,
                type: "array",
                message: "Missing Cover / Thumbnail photo",
              },
            ]}
          >
            <UploadCrop
              folder="event"
              listType="picture-card"
              className="upload-item-wrap cover-image"
              showUploadList
              maxCount={1}
            />
          </Form.Item>
          <h4>
            Banner image{" "}
            <Tooltip
              className="tooltip-field-item"
              title="For best results, banner images should be free of text. If no
              banner image is uploaded, the thumbnail will be used"
            >
              <QuestionCircleFilled />
            </Tooltip>
          </h4>
          <Form.Item label="" name="banner" className="ant-form-image">
            <UploadCrop
              folder="event"
              scale={144 / 58}
              listType="picture-card"
              className="upload-item-wrap cover-image"
              showUploadList
              maxCount={1}
            />
          </Form.Item>
          <h4>Document</h4>
          <Form.Item
            label=""
            name="documents"
            noStyle
            rules={[
              {
                required: false,
                type: "array",
                message: "Missing a document",
              },
            ]}
          >
            <UploadCrop
              crop={false}
              accept=".pdf,.xls,.xlsx,.doc,.docx,.png,.jpeg,.ics"
              listType="picture"
              className="upload-item-wrap"
              showUploadList
              maxCount={15}
            >
              <div className="children-upload">
                <p className="ant-upload-drag-icon">
                  <CloudUploadOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Upload 1 file at a time. We support .pdf,.xls, .xlsx, .doc,
                  .docx, .png, .jpeg, .ics. File size should be under 100MB
                </p>
              </div>
            </UploadCrop>
          </Form.Item>
        </div>
        <p className="flex end btn">
          <Button loading={loading} onClick={() => handleSubmit(0)}>
            Save as draft
          </Button>
          <Button
            loading={loading}
            type="primary"
            disabled={status === "live"}
            onClick={() => handleSubmit(1)}
          >
            Preview
          </Button>
        </p>
      </Form>
    </CreateContainer>
  );
};

export default Create;
